import React, { Component, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Row, Col, Card, Accordion } from "react-bootstrap";
import DatePicker from "react-datepicker";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Select from "react-select";
import { editBackTestAction } from "../../../src/store/actions/StrategyTestAction";

const stockCryptoOptions = [
  { value: "BTC-USD", label: "BTC-USD" },
  { value: "ETH-USD", label: "ETH-USD" },
  { value: "AAPL", label: "AAPL" },
  { value: "TESLA", label: "TESLA" },
];
const initialValidationPeriodOptions = [
  { value: 1, label: "Day" },
  { value: 7, label: "Week" },
  { value: 14, label: "2 weeks" },
  { value: 30, label: "Month" },
];
const allValidationPeriodOptions = [
  { value: 1, label: "Day" },
  { value: 7, label: "Week" },
  { value: 14, label: "2 weeks" },
  { value: 30, label: "Month" },
  { value: 90, label: "3 months" },
  { value: 180, label: "6 months" },
];
const timeFrameOptions = [
  { value: "1D", label: "Day" },
  // { value: "2D", label: "2Day" },
];

function EditStrategyBox({ editTestId, showModal }) {
  const dispatch = useDispatch();
  const [defaultActiveKey, setDefaultActiveKey] = useState();

  const [editItem, setEditItem] = useState(null);
  const [stockCrypto, setStockCrypto] = useState(null);
  const [atr, setAtr] = useState(6);
  const [multiplier, setMultiplier] = useState(10);
  const [optimizeParameter, setOptimizeParameter] = useState("option1");
  const [backTestPeriod, setBackTestPeriod] = useState("2023/09/01-2024/03/31");
  // const [validationPeriod, setValidationPeriod] = useState(null);
  const [validationPeriodOption, setValidationPeriodOption] = useState(
    initialValidationPeriodOptions[3]
  );
  const [limitedOptions, setLimitedOptions] = useState(
    initialValidationPeriodOptions
  );
  const [useDefaultParameters, setUseDefaultParameters] = useState(false);
  const [timeFrame, setTimeFrame] = useState(timeFrameOptions[0]);
  const [initialInvestment, setInitialInvestment] = useState(10000);
  const [lotSize, setLotSize] = useState(2000);
  const [stopLoss, setStopLoss] = useState(0);
  const [targetProfit, setTargetProfit] = useState(0);
  const [commission, setCommission] = useState(5);

  const strategyTestData = useSelector((state) => state.strategyTestData);
  // function editStrategyTest(test_id) {
  //   dispatch(editBackTestAction(test_id));
  //   showModal(false);
  // }
  function editStrategyTest(e) {
    e.preventDefault();
    let validationPeriod = validationPeriodOption.value;
    // Create the strategy test using the retrieved values
    const strategyTest = {
      editTestId,
      stockCrypto,
      backTestPeriod,
      validationPeriod,
      atr,
      multiplier,
      useDefaultParameters,
      timeFrame,
      initialInvestment,
      lotSize,
      stopLoss,
      targetProfit,
      commission,
    };

    console.log(strategyTest);
    dispatch(editBackTestAction(strategyTest));
    showModal(false);

    // setReload(true);
  }
  const changeValidationRangeOption = (dateRange) => {
    console.log(dateRange);

    const [startDateString, endDateString] = dateRange.split(" - ");
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);

    // Calculate the difference in days
    const days_between = Math.abs(endDate - startDate) / (1000 * 60 * 60 * 24);

    let test_range;

    if (days_between > 356) {
      test_range = 90;
    } else if (days_between > 90) {
      test_range = 30;
    } else if (days_between > 30) {
      test_range = 7;
    } else if (days_between > 7) {
      test_range = 3;
    } else {
      test_range = 1;
    }

    console.log(test_range);
    const newLimitedOptions = allValidationPeriodOptions.filter(
      (option) => option.value <= test_range
    );
    let last_item = newLimitedOptions.length - 1;
    setValidationPeriodOption(newLimitedOptions[last_item]); // Set the first available option as the selected value
    setLimitedOptions(newLimitedOptions);
  };
  const handleDefaultChange = (e) => {
    const checked = e.target.checked;

    if (checked) {
      setAtr(6);
      setMultiplier(10);
      setUseDefaultParameters(true);
    } else {
      // Set the default values to their initial state or any other desired value
      setAtr(editItem.bt_atr_period);
      setMultiplier(editItem.bt_multiplier);
      setUseDefaultParameters(false);
    }
  };

  useEffect(() => {
    setDefaultActiveKey("0");
    console.log(strategyTestData);
    const editItem = strategyTestData.data.find(
      (item) => item.test_id === editTestId
    );
    setEditItem(editItem);
    console.log(editItem);
    const selectedStockCrypto = editItem.bt_symbol;
    const selectedStockCryptoOption = stockCryptoOptions.find(
      (item) => item.value === selectedStockCrypto
    );

    const bt_2nd_start_date = editItem.bt_2nd_start_date;
    const bt_2nd_end_date = editItem.bt_2nd_end_date;

    const startDate = new Date(bt_2nd_start_date);
    const endDate = new Date(bt_2nd_end_date);

    const timeDiff = endDate.getTime() - startDate.getTime();

    // Calculate the time difference in days
    const selectedValidationPeriod = timeDiff / (1000 * 60 * 60 * 24);
    console.log(selectedValidationPeriod);

    // const selectedValidationPeriod = editItem.validationPeriod;
    const selectedValidationPeriodOption = allValidationPeriodOptions.find(
      (item) => item.value === selectedValidationPeriod
    );
    console.log(selectedValidationPeriodOption);

    const selectedTimeFrame = editItem.bt_time_frame_backward;
    const selectedTimeFrameOption = timeFrameOptions.find(
      (item) => item.value === selectedTimeFrame
    );

    setValidationPeriodOption(selectedValidationPeriodOption);
    console.log(selectedStockCryptoOption);
    setStockCrypto(selectedStockCryptoOption);
    console.log(selectedTimeFrameOption);
    setTimeFrame(selectedTimeFrameOption);
    setAtr(editItem.bt_atr_period);
    setMultiplier(editItem.bt_multiplier);
    setInitialInvestment(editItem.bt_initial_investment);
    setLotSize(editItem.bt_lot_size);
    setStopLoss(editItem.bt_sl_size);
    setTargetProfit(editItem.bt_tp_size);
    setCommission(editItem.bt_commission);
  }, []);
  const settings = {
    // className: "center",
    centerMode: true,
    infinite: true,
    // slidesToShow: 3,
    // slidesToScroll: 1,
    speed: 500,
    responsive: [
      {
        breakpoint: 1601,
        settings: {
          centerPadding: "100px",
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          centerPadding: "80px",
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          centerPadding: "60px",
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          centerPadding: "0px",
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const defaultAccordion = [
    {
      title: "SuperTrend",
      text: "SuperTrend is a popular technical analysis indicator used in financial markets, primarily in trading and investing. It helps identify the direction of a trend and provides potential entry and exit signals for traders.",
      bg: "primary",
    },
    {
      title: "EMA",
      text: "EMA stands for Exponential Moving Average. It is a commonly used technical indicator in financial markets, particularly in trading and investing. The Exponential Moving Average is used to smooth out price data and identify trends over a specified period.",

      bg: "info",
    },
    {
      title: "Moving Average Crossover",
      text: "Moving Average Crossover is a popular trend-following strategy used in technical analysis. It involves using two or more moving averages of different lengths to generate buy and sell signals based on their crossover points.",

      bg: "success",
    },
  ];
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      paddingLeft: "20px",
      backgroundColor: state.isSelected ? "#eb8153" : "white",
      color: state.isSelected ? "white" : "#6e6e6e",
      ":hover": {
        backgroundColor: "lightgray",
        color: "white",
      },
    }),
    control: (provided, state) => ({
      ...provided,
      // border: state.isFocused ? "1px solid #eb8153" : "none",
      border: "1px solid #EEEEEE",
      // boxShadow: state.isFocused ? "0 0 1px #eb8153" : "none",
      boxShadow: "none",
      borderRadius: "0.75rem",
      minHeight: "2.6rem",
      ":hover": {
        border: "none",
        boxShadow: "none",
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#6e6e6e",
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "150px",
      // paddingLeft: "20px", // change the left padding of the words
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: "20px", // change the left padding of the caret
    }),
    group: (provided) => ({
      ...provided,
      paddingLeft: "40px", // change the left padding of the caret
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "white",
    }),
  };
  return (
    <>
      <div className="slick-container">
        {/* <Slider {...settings}> */}
        {/* <Row>
        <Col xl="12"> */}
        {/* <div className="slick"> */}
        {/* <div className="card"> */}
        {/* <!-- Column starts --> */}
        <Card className="mb-0">
          <Card.Header className="d-block mx-2 my-2">
            <Card.Title>SuperTrend</Card.Title>
            <Card.Text className="m-0 subtitle">
              SuperTrend is a popular technical analysis indicator used in
              financial markets, primarily in trading and investing. It helps
              identify the direction of a trend and provides potential entry and
              exit signals for traders.
            </Card.Text>
          </Card.Header>
          <Card.Body className="pt-0 pb-1">
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={editStrategyTest}>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">
                      Stock/ Crypto
                    </label>
                    <div className="col-sm-9">
                      <Select
                        // defaultValue={stockCryptoOptions[0]}
                        value={stockCrypto}
                        onChange={setStockCrypto}
                        options={stockCryptoOptions}
                        showContent="true"
                        open="true"
                        className="my-react-select-container"
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <div className="col-sm-3 col-form-label">
                      <div>Back Test Period</div>
                    </div>
                    <div className="col-sm-9">
                      <DateRangePicker
                        onChange={(e) => setBackTestPeriod(e.target.value)}
                        initialSettings={{
                          startDate: "2023/09/01",
                          endDate: "2024/03/01",
                          locale: {
                            format: "YYYY/MM/DD",
                          },
                        }}
                        onApply={(e) => {
                          setBackTestPeriod(e.target.value);
                          changeValidationRangeOption(e.target.value);
                        }}
                      >
                        <input
                          type="text"
                          className="form-control input-daterange-timepicker"
                          value={backTestPeriod}
                          onChange={(e) => setBackTestPeriod(e.target.value)}
                        />
                      </DateRangePicker>
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">
                      Add Validation <br /> (select which period at the end of
                      back test for validation)
                    </label>
                    <div className="col-sm-9">
                      <Select
                        // defaultValue={validationPeriodOption}
                        value={validationPeriodOption}
                        onChange={setValidationPeriodOption}
                        options={limitedOptions}
                        showContent="true"
                        open="true"
                        className="my-react-select-container"
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  <div className="mb-4 row">
                    <label className="col-sm-3 col-form-label">
                      Time Frame
                    </label>
                    <div className="col-sm-9">
                      <Select
                        defaultValue={timeFrameOptions[0]}
                        // value={timeFrame}
                        onChange={setTimeFrame}
                        options={timeFrameOptions}
                        showContent="true"
                        open="true"
                        className="my-react-select-container"
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  <div class="line-edit-strategy"></div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">ATR</label>
                    <div className="col-sm-9">
                      <input
                        type="atr"
                        className="form-control"
                        placeholder="ATR"
                        value={atr}
                        onChange={(e) => {
                          setAtr(e.target.value);
                          if (e.target.value !== 6) {
                            setUseDefaultParameters(false);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">
                      Multiplier
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="multiplier"
                        className="form-control"
                        placeholder="Multiplier"
                        value={multiplier}
                        onChange={(e) => {
                          setMultiplier(e.target.value);
                          if (e.target.value !== 10) {
                            setUseDefaultParameters(false);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <fieldset className="form-group">
                    <div className="row mb-0">
                      <label className="col-form-label col-sm-3 pt-0">
                        Parameters Setting
                      </label>
                      <div className="col-sm-9">
                        <div className="form-check ms-2">
                          <input
                            className="form-check-input"
                            // type="radio"
                            type="checkbox"
                            // name="gridRadios"
                            value={useDefaultParameters}
                            // defaultChecked
                            onChange={handleDefaultChange}
                          />
                          <label className="form-check-label mx-3">
                            Use Default
                          </label>
                        </div>
                        {/* <div className="form-check ms-2">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="gridRadios"
                            value="option2"
                          />
                          <label className="form-check-label">
                            Use Optimized
                          </label>
                        </div> */}
                        {/* <div className="form-check disabled">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="gridRadios"
                            value="option3"
                            disabled
                          />
                          <label className="form-check-label">
                            Third disabled radio
                          </label>
                        </div> */}
                      </div>
                    </div>
                  </fieldset>
                  <div class="line-edit-strategy"></div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">
                      Initial Investment
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="initial_investment"
                        className="form-control"
                        placeholder="Initial Investment"
                        value={initialInvestment}
                        onChange={(e) => setInitialInvestment(e.target.value)}
                      />
                    </div>
                  </div>
                  {/* <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Lot Size</label>
                    <div className="col-sm-9">
                      <input
                        type="lot_size"
                        className="form-control"
                        placeholder="Lot Size"
                        value={lotSize}
                        onChange={(e) => setLotSize(e.target.value)}
                      />
                    </div>
                  </div> */}
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">Stop Loss</label>
                    <div className="col-sm-9">
                      <input
                        type="stop_loss"
                        className="form-control"
                        placeholder="Stop Loss"
                        value={stopLoss}
                        onChange={(e) => setStopLoss(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">
                      Target Profit
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="target_profit"
                        className="form-control"
                        placeholder="Target Profit"
                        value={targetProfit}
                        onChange={(e) => setTargetProfit(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mb-3 row">
                    <label className="col-sm-3 col-form-label">
                      Commission
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="commission"
                        className="form-control"
                        placeholder="Commission"
                        value={commission}
                        onChange={(e) => setCommission(e.target.value)}
                      />
                    </div>
                  </div>
                  {/* <div className="mb-3 row">
                    <div className="col-sm-3">1st Back Test</div>
                    <div className="col-sm-9">
                      <DateRangePicker
                        initialSettings={{
                          startDate: "10/5/2023",
                          endDate: "3/6/2023",
                        }}
                      >
                        <input
                          type="text"
                          className="form-control input-daterange-timepicker"
                        />
                      </DateRangePicker>
                    </div>
                  </div> */}
                  {/* <div className="mb-3 row">
                    <div className="col-sm-3">2nd Back Test</div>
                    <div className="col-sm-9">
                      <DateRangePicker
                        initialSettings={{
                          startDate: "10/5/2023",
                          endDate: "3/6/2023",
                        }}
                      >
                        <input
                          type="text"
                          className="form-control input-daterange-timepicker"
                        />
                      </DateRangePicker>
                    </div>
                  </div> */}
                  {/* <div className="mb-3 row">
                    <div className="col-sm-4">Start Forward Test</div>
                    <div className="col-sm-8">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" />
                        <label className="form-check-label">Yes</label>
                      </div>
                    </div>
                  </div> */}
                  <br />
                  <div className="mb-3 row">
                    <div className="col-sm-10">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        // onClick={() => editStrategyTest(editTestId)}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Card.Body>
        </Card>
        {/* </div> */}
        {/* </Col>
        </Row> */}
        {/* </Slider> */}
      </div>
    </>
  );
}

export default EditStrategyBox;
