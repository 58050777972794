import React from "react";

export const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <div className="display-flex justify-content-between align-items-center">
      <div>Search:</div>
      <input
        className="ms-2 input-search form-control"
        value={filter || ""}
        onChange={(e) => setFilter(e.target.value)}
        style={{ width: "90%" }}
      />
    </div>
  );
};
