import {
  HIGHEST_RETURN_SUCCESS_ACTION,
  HIGHEST_RETURN_FAILED_ACTION,
  MOST_RECENT_CHANGE_SUCCESS_ACTION,
  MOST_RECENT_CHANGE_FAILED_ACTION,
} from "../actions/MarketScanAction";

const initialState = {
  highestReturnData: [],
  mostRecentChangeData: [],
  errorMessage: "",
  successMessage: "",
  showLoading: false,
};

export function MarketScanReducer(state = initialState, action) {
  if (action.type === HIGHEST_RETURN_SUCCESS_ACTION) {
    return {
      ...state,
      highestReturnData: action.payload,
      errorMessage: "",
      successMessage: "highest return api call successful",
      showLoading: false,
    };
  }
  if (action.type === HIGHEST_RETURN_FAILED_ACTION) {
    return {
      ...state,
      // highestReturnData: action.payload,
      errorMessage: "highest return api call failed",
      successMessage: "",
      showLoading: false,
    };
  }
  if (action.type === MOST_RECENT_CHANGE_SUCCESS_ACTION) {
    return {
      ...state,
      mostRecentChangeData: action.payload,
      errorMessage: "",
      successMessage: "most recent change api call successful",
      showLoading: false,
    };
  }
  if (action.type === MOST_RECENT_CHANGE_FAILED_ACTION) {
    return {
      ...state,
      // mostRecentChangeData: action.payload,
      errorMessage: "most recent change api call failed",
      successMessage: "",
      showLoading: false,
    };
  }

  return state;
}

export default MarketScanReducer;
