import React, { useMemo } from "react";
import PageTitle from "../../../layouts/PageTitle";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import MOCK_DATA from "./MOCK_DATA_FirstBT.json";
import { COLUMNS } from "./Columns";
import { COLUMNSLOWER } from "./ColumnsLower";
import { GlobalFilter } from "./GlobalFilter";
import "./filtering.css";
// import TransactionTab from "../../../pages/TransactionTab";
import { Nav, Tab } from "react-bootstrap";

export const FirstBackTestTransactionTable = ({
  handleTransactionChange,
  backTestData,
  // selectedTestId,
}) => {
  // console.log(backTestData);
  // let columns = useMemo(() => COLUMNS, []);
  // const data = useMemo(() => MOCK_DATA, []);
  const columns = useMemo(() => {
    if (
      backTestData &&
      backTestData.data &&
      backTestData.data.transactions &&
      // backTestData.data.transactions.first_back_test &&
      backTestData.data.transactions.overall_back_test &&
      // backTestData.data.transactions.first_back_test.transactions
      backTestData.data.transactions.overall_back_test.transactions
    ) {
      return COLUMNSLOWER;
    }
    return COLUMNS;
  }, [backTestData]);

  const transactions = useMemo(() => {
    let transactions = [];

    if (backTestData && backTestData.length > 0) {
      const entries = backTestData[0].bt_1st_entries;
      const exits = backTestData[0].bt_1st_exits;

      transactions = [...entries, ...exits];

      transactions.sort((a, b) => new Date(a.Date) - new Date(b.Date));
    } else if (
      backTestData &&
      backTestData.data &&
      backTestData.data.transactions &&
      backTestData.data.transactions.overall_back_test &&
      backTestData.data.transactions.overall_back_test.transactions &&
      backTestData.data.transactions.overall_back_test.transactions.length >
        0 &&
      backTestData.data.transactions.first_back_test &&
      backTestData.data.transactions.first_back_test.start_date &&
      backTestData.data.transactions.first_back_test.end_date
    ) {
      const overall_transactions =
        backTestData.data.transactions.overall_back_test.transactions;
      const start_date =
        backTestData.data.transactions.first_back_test.start_date;
      const end_date = backTestData.data.transactions.first_back_test.end_date;
      transactions = overall_transactions.filter((item) => {
        const transactionDate = new Date(item.date_time);
        const startDate = new Date(start_date);
        const endDate = new Date(end_date);
        return transactionDate >= startDate && transactionDate <= endDate;
      });
    }

    return transactions;
  }, [backTestData]);
  // console.log(selectedTestId);
  // const data = useMemo(() => transactions, [transactions, selectedTestId]);
  const data = transactions;
  // const data = transactions;
  const tabData = [
    {
      name: "1st Back Test",
      icon: "chevron-circle-left",
      transactionType: "FirstBackTest",
    },
    {
      name: "2nd Back Test",
      icon: "chevron-circle-left",
      transactionType: "SecondBackTest",
    },
    {
      name: "Forward Test",
      icon: "chevron-circle-right",
      transactionType: "ForwardTest",
    },
  ];
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 6 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;

  return (
    <>
      {/* <PageTitle activeMenu="Filtering" motherMenu="Table" /> */}
      {/* <div className="card">
        <div className="card-header">
          <h4 className="card-title">1st Back Test Transactions</h4>
        </div> */}
      <div className="card-body pt-0">
        <div className="table-responsive">
          <div className="table-container">
            <div className="display-flex justify-content-between">
              {/* <TransactionTab
                handleTransactionTypeClick={handleTransactionChange}
              /> */}
              <div className="card-action coin-tabs my-3 mx-3">
                <div className="custom-tab-1">
                  <Tab.Container
                    defaultActiveKey={tabData[0].name.toLowerCase()}
                  >
                    <Nav as="ul" className="nav-tabs">
                      {tabData.map((data, i) => (
                        <Nav.Item as="li" key={i}>
                          <Nav.Link
                            eventKey={data.name.toLowerCase()}
                            onClick={() =>
                              handleTransactionChange(data.transactionType)
                            }
                            active={"1st Back Test" === data.name}
                          >
                            <i className={`la la-${data.icon} me-2`} />
                            {data.name}
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                  </Tab.Container>
                </div>
              </div>
              <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            </div>
            <div className="table-mobile">
              <table
                {...getTableProps()}
                className="table filtering-table table-responsive-lg"
                // className="table"
              >
                <colgroup>
                  <col className="col-2" />
                  <col className="col-2" />
                  <col className="col-2" />
                  <col className="col-2" />
                  <col className="col-2" />
                  <col className="col-2" />
                  <col className="col-4" />
                </colgroup>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>
                          {column.render("Header")}
                          {/* {column.canFilter ? column.render("Filter") : null} */}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {" "}
                              {cell.render("Cell")}{" "}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-between">
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
                {""}
              </span>
              <span className="table-index">
                Go to page :{" "}
                <input
                  type="number"
                  className="ms-2"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const pageNumber = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(pageNumber);
                  }}
                />
              </span>
            </div>
            <div className="text-center">
              <div className="filter-pagination  mt-3">
                <button
                  className=" previous-button"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  {"<<"}
                </button>

                <button
                  className="previous-button"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  Previous
                </button>
                <button
                  className="next-button"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  Next
                </button>
                <button
                  className=" next-button"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {">>"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};
export default FirstBackTestTransactionTable;
