import React, { Component, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Row, Col, Card, Accordion } from "react-bootstrap";
import DatePicker from "react-datepicker";
import DateRangePicker from "react-bootstrap-daterangepicker";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import Select from "react-select";
import { createStrategyTestAction } from "../../../src/store/actions/StrategyTestAction";

const stockCryptoOptions = [
  { value: "BTC-USD", label: "BTC-USD" },
  { value: "ETH-USD", label: "ETH-USD" },
  { value: "AAPL", label: "AAPL" },
  { value: "TESLA", label: "TESLA" },
];
const initialValidationPeriodOptions = [
  { value: 1, label: "Day" },
  { value: 7, label: "Week" },
  { value: 14, label: "2 weeks" },
  { value: 30, label: "Month" },
];
const allValidationPeriodOptions = [
  { value: 1, label: "Day" },
  { value: 7, label: "Week" },
  { value: 14, label: "2 weeks" },
  { value: 30, label: "Month" },
  { value: 90, label: "3 months" },
  { value: 180, label: "6 months" },
];
const timeFrameOptions = [{ value: "1D", label: "Day" }];

function AddStrategySlickSlides({ user, showModal, setLoadTop, setLoading }) {
  const dispatch = useDispatch();
  const [defaultActiveKey, setDefaultActiveKey] = useState();
  const [stockCrypto, setStockCrypto] = useState(null);
  const [atr, setAtr] = useState(6);
  const [multiplier, setMultiplier] = useState(10);
  const [optimizeParameter, setOptimizeParameter] = useState("option1");
  const [backTestPeriod, setBackTestPeriod] = useState("2023/09/01-2024/03/31");
  // const [validationPeriod, setValidationPeriod] = useState(30);
  const [validationPeriodOption, setValidationPeriodOption] = useState(
    initialValidationPeriodOptions[3]
  );
  const [limitedOptions, setLimitedOptions] = useState(
    initialValidationPeriodOptions
  );
  const [useDefaultParameters, setUseDefaultParameters] = useState(false);
  const [timeFrame, setTimeFrame] = useState(null);
  const [initialInvestment, setInitialInvestment] = useState(100000);
  const [lotSize, setLotSize] = useState(2000);
  const [stopLoss, setStopLoss] = useState(10000);
  const [targetProfit, setTargetProfit] = useState(10000);
  const [commission, setCommission] = useState(5);
  const [enableTelegramNotification, setEnableTelegramNotification] =
    useState(true);
  const [telegramName, setTelegramName] = useState("");

  function createStrategyTest(e) {
    e.preventDefault();
    setLoading(true);
    let validationPeriod = validationPeriodOption.value;
    // Create the strategy test using the retrieved values
    const strategyTest = {
      user,
      stockCrypto,
      backTestPeriod,
      validationPeriod,
      atr,
      multiplier,
      useDefaultParameters,
      timeFrame,
      initialInvestment,
      lotSize,
      stopLoss,
      targetProfit,
      commission,
      enableTelegramNotification,
      telegramName,
    };

    console.log(strategyTest);
    dispatch(createStrategyTestAction(strategyTest, setLoading));
    showModal(false);
    setLoadTop(true);
  }

  const handleDefaultChange = (e) => {
    const checked = e.target.checked;

    if (checked) {
      setAtr(6);
      setMultiplier(10);
      setUseDefaultParameters(true);
    } else {
      // Set the default values to their initial state or any other desired value
      setAtr(""); // Initial ATR value
      setMultiplier(""); // Initial Multiplier value
      setUseDefaultParameters(false);
    }
  };

  const changeValidationRangeOption = (dateRange) => {
    console.log(dateRange);

    const [startDateString, endDateString] = dateRange.split(" - ");
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);

    // Calculate the difference in days
    const days_between = Math.abs(endDate - startDate) / (1000 * 60 * 60 * 24);

    let test_range;

    if (days_between > 356) {
      test_range = 90;
    } else if (days_between > 90) {
      test_range = 30;
    } else if (days_between > 30) {
      test_range = 7;
    } else if (days_between > 7) {
      test_range = 3;
    } else {
      test_range = 1;
    }

    console.log(test_range);
    const newLimitedOptions = allValidationPeriodOptions.filter(
      (option) => option.value <= test_range
    );
    let last_item = newLimitedOptions.length - 1;
    setValidationPeriodOption(newLimitedOptions[last_item]); // Set the first available option as the selected value
    setLimitedOptions(newLimitedOptions);
  };

  useEffect(() => {
    setDefaultActiveKey("0");
  }, []);
  const settings = {
    // className: "center",
    centerMode: true,
    infinite: true,
    // slidesToShow: 3,
    // slidesToScroll: 1,
    speed: 500,
    responsive: [
      {
        breakpoint: 1601,
        settings: {
          centerPadding: "100px",
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          centerPadding: "80px",
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          centerPadding: "60px",
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          centerPadding: "0px",
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const defaultAccordion = [
    {
      title: "SuperTrend",
      text: "SuperTrend is a popular technical analysis indicator used in financial markets, primarily in trading and investing. It helps identify the direction of a trend and provides potential entry and exit signals for traders.",
      bg: "primary",
    },
    {
      title: "EMA",
      text: "EMA stands for Exponential Moving Average. It is a commonly used technical indicator in financial markets, particularly in trading and investing. The Exponential Moving Average is used to smooth out price data and identify trends over a specified period.",

      bg: "info",
    },
    {
      title: "Moving Average Crossover",
      text: "Moving Average Crossover is a popular trend-following strategy used in technical analysis. It involves using two or more moving averages of different lengths to generate buy and sell signals based on their crossover points.",

      bg: "success",
    },
  ];
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      paddingLeft: "20px",
      backgroundColor: state.isSelected ? "#eb8153" : "white",
      color: state.isSelected ? "white" : "black",
      ":hover": {
        backgroundColor: "lightgray",
        color: "white",
      },
    }),
    control: (provided, state) => ({
      ...provided,
      // border: state.isFocused ? "1px solid #eb8153" : "none",
      border: "none",
      // boxShadow: state.isFocused ? "0 0 1px #eb8153" : "none",
      boxShadow: "none",
      borderRadius: "0.75rem",
      minHeight: "2.6rem",
      ":hover": {
        border: "none",
        boxShadow: "none",
      },
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "150px",
      // paddingLeft: "20px", // change the left padding of the words
    }),
    valueContainer: (provided) => ({
      ...provided,
      paddingLeft: "20px", // change the left padding of the caret
    }),
    group: (provided) => ({
      ...provided,
      paddingLeft: "40px", // change the left padding of the caret
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "white",
    }),
  };
  return (
    <div className="slick-container">
      <Slider {...settings}>
        {/* <Row>
          <Col xl="12"> */}
        <div className="slick">
          {/* <div className="card"> */}
          {/* <!-- Column starts --> */}
          <Card>
            <Card.Header className="d-block">
              <Card.Title>Trend Following</Card.Title>
              <Card.Text className="m-0 subtitle">
                {/* Add <code>accordion-gradient</code> class with{" "}
                <code>accordion</code> */}
                Strategies for trend following
              </Card.Text>
            </Card.Header>
            <Card.Body>
              <Accordion
                className="accordion accordion-rounded-stylish accordion-gradient"
                defaultActiveKey={defaultActiveKey}
              >
                {defaultAccordion.map((d, i) => (
                  <Accordion.Item key={i} eventKey={`${i}`}>
                    <Accordion.Header className="accordion-header accordion-header--primary">
                      <span className="accordion-header-icon"></span>
                      <span className="accordion-header-text">{d.title}</span>
                      <span className="accordion-header-indicator"></span>
                    </Accordion.Header>
                    <Accordion.Collapse
                      eventKey={`${i}`}
                      className="accordion__body"
                    >
                      <div className="accordion-body">
                        {d.text}
                        <div className="card-body">
                          <div className="basic-form">
                            {/* <form onSubmit={(e) => e.preventDefault()}> */}
                            <form onSubmit={createStrategyTest}>
                              {/* <div className="mb-4">
                                <label className="col-sm-3 col-form-label">
                                  Stock/ Crypto
                                </label>
                                <div className="col-sm-12">
                                  <select
                                    defaultValue={"option"}
                                    className="form-control"
                                    id="sel1"
                                  >
                                    <option>BTC-USD</option>
                                    <option>ETH-USD</option>
                                    <option>AAPL</option>
                                    <option>TESLA</option>
                                  </select>
                                </div>
                              </div> */}
                              <div className="mb-4 mx-0">
                                <label className="col-form-label">
                                  Stock/ Crypto
                                </label>
                                {/* <p>
                                  Select2 can take a regular select box like
                                  this...
                                </p> */}
                                <Select
                                  defaultValue={stockCryptoOptions[0]}
                                  onChange={setStockCrypto}
                                  options={stockCryptoOptions}
                                  showContent="true"
                                  open="true"
                                  className="my-react-select-container"
                                  styles={customStyles}
                                />
                              </div>
                              <div className="mb-3 row">
                                {/* <div className="example rangeDatePicker"> */}
                                <div className="col-sm-4">Back Test Period</div>
                                {/* <DatePicker
                                    startText="Start"
                                    endText="End"
                                    startPlaceholder="Start Date"
                                    endPlaceholder="End Date"
                                  /> */}
                                <div className="col-sm-8">
                                  <DateRangePicker
                                    initialSettings={{
                                      startDate: "2023/09/01",
                                      endDate: "2024/03/01",
                                      locale: {
                                        format: "YYYY/MM/DD",
                                      },
                                    }}
                                    onApply={(e) => {
                                      setBackTestPeriod(e.target.value);
                                      changeValidationRangeOption(
                                        e.target.value
                                      );
                                    }}
                                  >
                                    <input
                                      type="text"
                                      className="form-control input-daterange-timepicker"
                                      value={backTestPeriod}
                                      onChange={(e) =>
                                        setBackTestPeriod(e.target.value)
                                      }
                                    />
                                  </DateRangePicker>
                                </div>
                                {/* </div> */}
                              </div>
                              {/* <div className="mb-3 row">
                                <div className="col-sm-3">2nd Back Test</div>
                                <div className="col-sm-9">
                                  <DateRangePicker
                                    initialSettings={{
                                      startDate: "10/5/2023",
                                      endDate: "3/6/2023",
                                      locale: {
                                        format: "DD/MM/YYYY",
                                      },
                                    }}
                                    onApply={(e) =>
                                      setSecondBackTest(e.target.value)
                                    }
                                  >
                                    <input
                                      type="text"
                                      className="form-control input-daterange-timepicker"
                                      value={secondBackTest}
                                    />
                                  </DateRangePicker>
                                </div>
                              </div> */}
                              <div className="mb-4 mx-0">
                                <label className="col-form-label">
                                  Add Validation <br /> (select which period at
                                  the end of back test for validation)
                                </label>
                                <Select
                                  // defaultValue={validationPeriodOption}
                                  value={validationPeriodOption}
                                  onChange={setValidationPeriodOption}
                                  options={limitedOptions}
                                  showContent="true"
                                  open="true"
                                  className="my-react-select-container"
                                  styles={customStyles}
                                />
                              </div>
                              <div className="mb-0 row">
                                <label className="col-sm-4 col-form-label">
                                  Time Frame
                                </label>
                                <div className="col-sm-8">
                                  <Select
                                    defaultValue={timeFrameOptions[0]}
                                    onChange={setTimeFrame}
                                    options={timeFrameOptions}
                                    showContent="true"
                                    open="true"
                                    className="my-react-select-container"
                                    styles={customStyles}
                                  />
                                </div>
                              </div>
                              <div class="line-add-strategy"></div>
                              <div className="mb-3 row">
                                <label className="col-sm-4 col-form-label">
                                  ATR
                                </label>
                                <div className="col-sm-8">
                                  <input
                                    type="atr"
                                    className="form-control"
                                    placeholder="ATR"
                                    value={atr}
                                    onChange={(e) => {
                                      setAtr(e.target.value);
                                      if (e.target.value !== 6) {
                                        setUseDefaultParameters(false);
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="mb-3 row">
                                <label className="col-sm-4 col-form-label">
                                  Multiplier
                                </label>
                                <div className="col-sm-8">
                                  <input
                                    type="multiplier"
                                    className="form-control"
                                    placeholder="Multiplier"
                                    value={multiplier}
                                    onChange={(e) => {
                                      setMultiplier(e.target.value);
                                      if (e.target.value !== 10) {
                                        setUseDefaultParameters(false);
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="mb-0 row">
                                <div className="col-sm-4">
                                  Use Default Parameters
                                </div>
                                <div className="col-sm-8">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={useDefaultParameters}
                                      // onChange={(e) =>
                                      //   setDefaultParameters(e.target.checked)
                                      // }
                                      onChange={handleDefaultChange}
                                    />
                                    <label className="form-check-label">
                                      Yes
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div class="line-add-strategy"></div>
                              <div className="mb-3 row">
                                <label className="col-sm-4 col-form-label">
                                  Initial Investment
                                </label>
                                <div className="col-sm-8">
                                  <input
                                    type="initial_investment"
                                    className="form-control"
                                    placeholder="Initial Investment"
                                    value={initialInvestment}
                                    onChange={(e) =>
                                      setInitialInvestment(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="mb-3 row">
                                <label className="col-sm-4 col-form-label">
                                  Lot Size
                                </label>
                                <div className="col-sm-8">
                                  <input
                                    type="lot_size"
                                    className="form-control"
                                    placeholder="Lot Size"
                                    value={lotSize}
                                    onChange={(e) => setLotSize(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="mb-3 row">
                                <label className="col-sm-4 col-form-label">
                                  Stop Loss
                                </label>
                                <div className="col-sm-8">
                                  <input
                                    type="stop_loss"
                                    className="form-control"
                                    placeholder="Stop Loss"
                                    value={stopLoss}
                                    onChange={(e) =>
                                      setStopLoss(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="mb-3 row">
                                <label className="col-sm-4 col-form-label">
                                  Target Profit
                                </label>
                                <div className="col-sm-8">
                                  <input
                                    type="target_profit"
                                    className="form-control"
                                    placeholder="Target Profit"
                                    value={targetProfit}
                                    onChange={(e) =>
                                      setTargetProfit(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="mb-3 row">
                                <label className="col-sm-4 col-form-label">
                                  Commission
                                </label>
                                <div className="col-sm-8">
                                  <input
                                    type="commission"
                                    className="form-control"
                                    placeholder="Commission"
                                    value={commission}
                                    onChange={(e) =>
                                      setCommission(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="mb-0 row">
                                <div className="col-sm-4">
                                  Enable Telegram Notification
                                </div>
                                <div className="col-sm-8">
                                  <div className="form-check mb-2">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={enableTelegramNotification}
                                      onChange={(e) => {
                                        setEnableTelegramNotification(
                                          e.target.checked
                                        );
                                      }}
                                      disabled
                                    />
                                    <label className="form-check-label">
                                      Yes
                                    </label>
                                  </div>
                                  {/* {enableTelegramNotification && (
                                    <input
                                      type="text"
                                      className="form-control mb-2"
                                      placeholder="Your Telegram Username"
                                      value={telegramName}
                                      onChange={(e) =>
                                        setTelegramName(e.target.value)
                                      }
                                    />
                                  )} */}
                                </div>
                              </div>
                              {/* <fieldset className="form-group">
                                <div className="row mb-3">
                                  <label className="col-form-label col-sm-4 pt-0">
                                    Optimize Parameters
                                  </label>
                                  <div className="col-sm-8">
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="gridRadios"
                                        value="option1"
                                        // defaultChecked
                                        checked={
                                          optimizeParameter === "option1"
                                        }
                                        onChange={(e) => {
                                          setOptimizeParameter(e.target.value);
                                        }}
                                      />
                                      <label className="form-check-label">
                                        Manual Input
                                      </label>
                                    </div>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="gridRadios"
                                        value="option2"
                                        checked={
                                          optimizeParameter === "option2"
                                        }
                                        onChange={(e) => {
                                          setOptimizeParameter(e.target.value);
                                        }}
                                      />
                                      <label className="form-check-label">
                                        Auto Optimize
                                      </label>
                                    </div>
                                    <div className="form-check disabled">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="gridRadios"
                                        value="option3"
                                        disabled
                                      />
                                      <label className="form-check-label">
                                        Third disabled radio
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </fieldset> */}
                              <br />
                              <div className="mb-0 row justify-content-end">
                                <div className="col-sm-10 display-flex justify-content-end">
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                    // onClick={() => createStrategyTest(user)}
                                  >
                                    Add Strategy Test
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </Accordion.Collapse>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Card.Body>
          </Card>
          {/* <h3>1</h3> */}
          {/* </div> */}
        </div>
        <div className="slick">
          <div className="card">
            <h3>2</h3>
          </div>
        </div>
        <div className="slick">
          <div className="card">
            <h3>3</h3>
          </div>
        </div>
        <div className="slick">
          <div className="card">
            <h3>4</h3>
          </div>
        </div>
        <div className="slick">
          <div className="card">
            <h3>5</h3>
          </div>
        </div>
        <div className="slick">
          <div className="card">
            <h3>6</h3>
          </div>
        </div>
        {/* </Col>
        </Row> */}
      </Slider>
    </div>
  );
}

export default AddStrategySlickSlides;
