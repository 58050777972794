import PerfectScrollbar from "react-perfect-scrollbar";
import { useRef, useEffect } from "react";
import { Link } from "react-router-dom";

const NewsTimeline = ({
  newsAnalysisData,
  testId,
  newsIndex,
  newsHighlighted,
}) => {
  const perfectScroll = useRef();

  function scrollBottom() {
    // const curr = perfectScroll.current;
    if (perfectScroll.current) {
      perfectScroll.current.scrollTop = 370;
    }
  }
  // const scrollToPosition = () => {
  //   if (scrollbarRef.current) {
  //     // Scroll to a specific position (e.g., 300 pixels from the top)
  //     scrollbarRef.current.scrollTop = 300;
  //     const scrollPosition = scrollbarRef.current.scrollTop;
  //     console.log("Scroll Position:", scrollPosition);
  //   }
  // };
  // const getScrollPosition = () => {
  //   if (scrollbarRef.current) {
  //     // Get the current scroll position
  //     console.log(scrollbarRef.current);
  //     console.log(scrollbarRef.current.scrollTop);
  //     const scrollPosition = scrollbarRef.current.scrollTop;
  //     console.log("Scroll Position:", scrollPosition);
  //   }
  // };

  const newsDataTemp = newsAnalysisData.data;
  const newsData = [...newsDataTemp, {}];
  // console.log(testId);
  // console.log(newsData);
  const data = [
    {
      date_time: "10 minutes ago",
      headline: "Youtube, a video-sharing website, goes live $500.",
      body_impact_overall: 85,
      impactColor: "text-light-green",
      className: "primary",
    },
    {
      date_time: "20 minutes ago",
      headline: "New order placed #XF-2356.",
      description: "Quisque a consequat ante Sit amet magna at volutapt...",
      body_impact_overall: -45,
      impactColor: "text-light-red",
      className: "info",
    },
    {
      date_time: "30 minutes ago",
      headline: "john just buy your product Sell $250",
      body_impact_overall: 35,
      impactColor: "text-light-green",
      className: "danger",
    },
    {
      date_time: "15 minutes ago",
      headline: "StumbleUpon is acquired by eBay.",
      body_impact_overall: 75,
      impactColor: "text-light-green",
      className: "success",
    },
    {
      date_time: "20 minutes ago",
      headline: "Mashable, a news website and blog, goes live.",
      body_impact_overall: 55,
      impactColor: "text-light-green",
      className: "warning",
    },
    {
      date_time: "20 minutes ago",
      headline: "Mashable, a news website and blog, goes live.",
      body_impact_overall: -25,
      impactColor: "text-light-red",
      className: "dark",
    },
    {},
  ];

  useEffect(() => {
    if (perfectScroll.current && newsHighlighted) {
      // Scroll to the specified data item
      const itemElement = perfectScroll.current.querySelector(
        `[data-index="${newsIndex}"]`
      );
      if (itemElement) {
        // console.log("itemElement.offsetTop: " + itemElement.offsetTop);
        // console.log(
        //   "perfectScroll.current.offsetTop: " + perfectScroll.current.offsetTop
        // );
        const scrollToOffset = itemElement.offsetTop - 10;
        // itemElement.offsetTop - perfectScroll.current.offsetTop;
        perfectScroll.current.scrollTop = scrollToOffset;
      }

      // const newsBadge = document.querySelector(`[badge-index="${newsIndex}"]`);
      // console.log(newsBadge);
      // if (newsBadge) {
      //   console.log("change badges");
      //   newsBadge.setAttribute(
      //     "class",
      //     `timeline-badge-highlighted ${
      //       newsData[newsIndex].body_impact_overall > 0 ? "success" : "primary"
      //     }`
      //   );
      // }
    }
  }, [newsIndex, newsHighlighted]);

  return (
    // <div className="col-xl-4 col-xxl-6 col-lg-6">
    <div className="col-12">
      {/* <div className="card"> */}
      {/* <div className="card-header border-0 pb-0"> */}
      {/* <h1 className="card-title">News Timeline</h1> */}
      <h6 className="mx-2 mt-0 mb-0">News AI Analysis </h6>
      {/* </div> */}
      <div className="card-body">
        {/* <button onClick={scrollBottom}>Scroll bottom</button> */}
        {/* <button onClick={getScrollPosition}>Scroll Position</button> */}
        <PerfectScrollbar
          containerRef={(element) => (perfectScroll.current = element)}
          style={{ height: "200px" }}
          id="DZ_W_TimeLine1"
          className="widget-timeline dz-scroll style-1 height370 ps ps--active-y"
        >
          <ul className="timeline">
            {newsData.map((item, index) => {
              // console.log(item);
              if (!item.headline) {
                return <li className="my-4"></li>; // Skip rendering the row if the item data is empty
              }
              return (
                <li
                  key={index}
                  data-index={index}
                  className={
                    index === newsIndex && newsHighlighted
                      ? "card newsHighlighted"
                      : ""
                  }
                >
                  <div
                    // badge-index={index}
                    className={`timeline-badge ${
                      item.body_impact_overall > 0 ? "success" : "primary"
                    } ${
                      index === newsIndex && newsHighlighted
                        ? "shifted-badge"
                        : ""
                    }`}
                    // style={{
                    //   left:
                    //     index === newsIndex &&
                    //     newsHighlighted &&
                    //     item.body_impact_overall > 0
                    //       ? "10px"
                    //       : "0",
                    // }}
                  ></div>
                  <a
                    className="timeline-panel text-muted"
                    href={item.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="display-flex justify-content-between">
                      <div>
                        <span>{item.date_time}</span>
                        <h6 className="mb-0">{item.headline}</h6>
                        {item.description && (
                          <p className="mb-0">{item.description}</p>
                        )}
                      </div>
                      <h6
                        className={`place-end align-items-center mx-5 ${
                          item.body_impact_overall > 0
                            ? "text-light-green"
                            : item.body_impact_overall == 0
                            ? ""
                            : "text-light-red"
                        }`}
                      >
                        Impact: {item.body_impact_overall}
                      </h6>
                    </div>
                  </a>
                </li>
              );
            })}
            {/* <li className={highlightClassName}>
              <div className="timeline-badge primary"></div>
              <Link className="timeline-panel text-muted" to="/widget-basic">
                <div className="display-flex justify-content-between">
                  <div>
                    <span>10 minutes ago</span>
                    <h6 className="mb-0">
                      Youtube, a video-sharing website, goes live{" "}
                      <strong className="text-primary">$500</strong>.
                    </h6>
                  </div>
                  <h6 className="place-end align-items-center mx-5 text-light-green">
                    Impact: 85
                  </h6>
                </div>
              </Link>
            </li>
            <li>
              <div className="timeline-badge info"></div>
              <Link className="timeline-panel text-muted" to="/widget-basic">
                <div className="display-flex justify-content-between">
                  <div>
                    <span>20 minutes ago</span>
                    <h6 className="mb-0">
                      New order placed{" "}
                      <strong className="text-info">#XF-2356.</strong>
                    </h6>
                    <p className="mb-0">
                      Quisque a consequat ante Sit amet magna at volutapt...
                    </p>
                  </div>
                  <h6 className="place-end align-items-center mx-5 text-light-red">
                    Impact: -45
                  </h6>
                </div>
              </Link>
            </li>
            <li>
              <div className="timeline-badge danger"></div>
              <Link className="timeline-panel text-muted" to="/widget-basic">
                <div className="display-flex justify-content-between">
                  <div>
                    <span>30 minutes ago</span>
                    <h6 className="mb-0">
                      john just buy your product{" "}
                      <strong className="text-warning">Sell $250</strong>
                    </h6>
                  </div>
                  <h6 className="place-end align-items-center mx-5 text-light-green">
                    Impact: 35
                  </h6>
                </div>
              </Link>
            </li>
            <li>
              <div className="timeline-badge success"></div>
              <Link className="timeline-panel text-muted" to="/widget-basic">
                <div className="display-flex justify-content-between">
                  <div>
                    <span>15 minutes ago</span>
                    <h6 className="mb-0">StumbleUpon is acquired by eBay. </h6>
                  </div>
                  <h6 className="place-end align-items-center mx-5 text-light-green">
                    Impact: 75
                  </h6>
                </div>
              </Link>
            </li>
            <li>
              <div className="timeline-badge warning"></div>
              <Link className="timeline-panel text-muted" to="/widget-basic">
                <div className="display-flex justify-content-between">
                  <div>
                    <span>20 minutes ago</span>
                    <h6 className="mb-0">
                      Mashable, a news website and blog, goes live.
                    </h6>
                  </div>
                  <h6 className="place-end align-items-center mx-5 text-light-green">
                    Impact: 55
                  </h6>
                </div>
              </Link>
            </li>
            <li>
              <div className="timeline-badge dark"></div>
              <Link className="timeline-panel text-muted" to="/widget-basic">
                <div className="display-flex justify-content-between">
                  <div>
                    <span>20 minutes ago</span>
                    <h6 className="mb-0">
                      Mashable, a news website and blog, goes live.
                    </h6>
                  </div>
                  <h6 className="place-end align-items-center mx-5 text-light-red">
                    Impact: -25
                  </h6>
                </div>
              </Link>
            </li> */}
          </ul>
        </PerfectScrollbar>
      </div>
      {/* </div> */}
    </div>
  );
};

export default NewsTimeline;
