import React, { Component, useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
// import { useDispatch } from "react-redux";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import { Row, Col, Card, Accordion } from "react-bootstrap";
// import DatePicker from "react-datepicker";
// import DateRangePicker from "react-bootstrap-daterangepicker";
// import { editBackTestAction } from "../../store/actions/StrategyTestAction";

function InfoBox({ showModal }) {
  // const dispatch = useDispatch();
  // const [defaultActiveKey, setDefaultActiveKey] = useState();

  // function editStrategyTest(test_id) {
  //   dispatch(editBackTestAction(test_id));
  //   showModal(false);
  // }

  // useEffect(() => {
  //   setDefaultActiveKey("0");
  // }, []);
  // const settings = {
  //   // className: "center",
  //   centerMode: true,
  //   infinite: true,
  //   // slidesToShow: 3,
  //   // slidesToScroll: 1,
  //   speed: 500,
  //   responsive: [
  //     {
  //       breakpoint: 1601,
  //       settings: {
  //         centerPadding: "100px",
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 1200,
  //       settings: {
  //         centerPadding: "80px",
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         centerPadding: "60px",
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 450,
  //       settings: {
  //         centerPadding: "0px",
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };
  // const defaultAccordion = [
  //   {
  //     title: "SuperTrend",
  //     text: "SuperTrend is a popular technical analysis indicator used in financial markets, primarily in trading and investing. It helps identify the direction of a trend and provides potential entry and exit signals for traders.",
  //     bg: "primary",
  //   },
  //   {
  //     title: "EMA",
  //     text: "EMA stands for Exponential Moving Average. It is a commonly used technical indicator in financial markets, particularly in trading and investing. The Exponential Moving Average is used to smooth out price data and identify trends over a specified period.",

  //     bg: "info",
  //   },
  //   {
  //     title: "Moving Average Crossover",
  //     text: "Moving Average Crossover is a popular trend-following strategy used in technical analysis. It involves using two or more moving averages of different lengths to generate buy and sell signals based on their crossover points.",

  //     bg: "success",
  //   },
  // ];
  return (
    <div className="mt-3">
      <Modal.Header>
        <h4 className="text-black">Reminder</h4>
        <Button variant="" className="close" onClick={() => showModal(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <p>
          To match data, cannot edit test / optimize back test once started
          forward test. Please create a new test.
        </p>
        {/* <p>
          Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
          Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
        </p>
        <p>
          Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus
          magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec
          ullamcorper nulla non metus auctor fringilla.
        </p>
        <p>
          Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
          dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
          consectetur ac, vestibulum at eros.
        </p>
        <p>
          Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
          Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
        </p>
        <p>
          Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus
          magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec
          ullamcorper nulla non metus auctor fringilla.
        </p>
        <p>
          Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
          dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
          consectetur ac, vestibulum at eros.
        </p>
        <p>
          Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
          Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
        </p>
        <p>
          Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus
          magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec
          ullamcorper nulla non metus auctor fringilla.
        </p>
        <p>
          Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
          dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
          consectetur ac, vestibulum at eros.
        </p>
        <p>
          Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
          Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
        </p>
        <p>
          Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus
          magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec
          ullamcorper nulla non metus auctor fringilla.
        </p>
        <p>
          Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
          dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
          consectetur ac, vestibulum at eros.
        </p>
        <p>
          Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
          Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
        </p>
        <p>
          Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus
          magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec
          ullamcorper nulla non metus auctor fringilla.
        </p>
        <p>
          Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
          dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
          consectetur ac, vestibulum at eros.
        </p>
        <p>
          Praesent commodo cursus magna, vel scelerisque nisl consectetur et.
          Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
        </p>
        <p>
          Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus
          magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec
          ullamcorper nulla non metus auctor fringilla.
        </p> */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => showModal(false)}>
          Close
        </Button>
        {/* <Button variant="primary">Save changes</Button> */}
      </Modal.Footer>
    </div>
  );
}

export default InfoBox;
