import React from "react";
import ReactApexChart from "react-apexcharts";

class ReturnComparisonLine extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [],
      //   {
      //     name: "1st BackTest",
      //     data: [],
      //     // data: [500, 230, 600, 360, 700, 890, 750, 420, 600, 300, 420, 220],
      //   },
      //   {
      //     name: "2nd BackTest",
      //     data: [],
      //     // data: [
      //     //   250,
      //     //   380,
      //     //   200,
      //     //   300,
      //     //   200,
      //     //   520,
      //     //   "NA",
      //     //   "NA",
      //     //   "NA",
      //     //   "NA",
      //     //   "NA",
      //     //   "NA",
      //     // ],
      //   },
      // ],
      options: {
        chart: {
          // height: 100,
          type: "area",
          group: "social",
          toolbar: {
            show: false,
          },
          zoom: {
            // enabled: false,
            type: "xy",
            enabled: true,
          },
          toolbar: {
            autoSelected: "zoom",
            // autoSelected: "pan",
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: true,
              reset: true,
              // reset: true | '<img src="/static/icons/reset.png" width="20">',
              // customIcons: [],
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        // stroke: {
        //   width: [2, 2],
        //   // colors: ["#EB8153", "#2BC155"],
        //   curve: "smooth",
        //   // lineCap: "square",
        // },
        legend: {
          // tooltipHoverFormatter: function (val, opts) {
          //   return (
          //     val +
          //     " - " +
          //     opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
          //     ""
          //   );
          // },
          position: "top",
          markers: {
            // fillColors: ["#EB8153", "#2BC155"],
            width: 19,
            height: 19,
            strokeWidth: 0,
            radius: 19,
          },
        },
        tooltip: {
          // x: {
          //   formatter: function (val, opts) {
          //     return (
          //       opts.w.globals.categoryLabels[opts.dataPointIndex] +
          //       " month equity"
          //     );
          //   },
          // },
          enabled: true,
          shared: true,
          followCursor: true,
        },
        title: {
          text: "Equity Comparison",
          align: "left",
        },
        subtitle: {
          text: "USD",
          align: "left",
        },
        markers: {
          size: 0,
          border: 0,
          // colors: ["#EB8153", "#2BC155"],
          hover: {
            size: 6,
          },
        },
        // xaxis: [
        //   {
        //     type: "datetime",
        //     // categories: firstXAxisCategories,
        //   },
        //   {
        //     type: "category",
        //     // categories: secondXAxisCategories,
        //     position: "top",
        //     axisBorder: {
        //       show: true,
        //     },
        //     axisTicks: {
        //       show: true,
        //     },
        //     tooltip: {
        //       enabled: false,
        //     },
        //   },
        // ],
        xaxis: {
          // categories: [
          //   "1st",
          //   "2nd",
          //   "3rd",
          //   "4th",
          //   "5th",
          //   "6th",
          //   "7th",
          //   "8th",
          //   "9th",
          //   "10th",
          //   "11st",
          //   "12nd",
          // ],
          type: "datetime",
          // tickAmount: "dataPoints", // Adjust the number of ticks based on the number of data points
          // labels: {
          //   format: "",
          // },
          // title: {
          //   text: "Month",
          //   position: "bottom",
          // },
        },
        // annotations: {
        //   yaxis: [
        //     {
        //       x: -10,
        //       borderColor: "#999",
        //       label: {
        //         show: true,
        //         orientation: "horizontal",
        //         borderColor: "#FF4560",
        //         offsetY: 20,
        //         style: {
        //           color: "#fff",
        //           background: "#FF4560",
        //         },
        //         text: "First X-Axis Annotation",
        //       },
        //     },
        //   ],
        // },
        yaxis: [
          {
            labels: {
              formatter: function (val) {
                return val.toFixed(0);
              },
              style: {
                colors: "#008ffb",
                // fontSize: "14px",
                // // fontFamily: "Poppins",
                // fontWeight: 100,
              },
            },
            title: {
              text: "Equity",
              // offsetX: 10,
            },
          },
          {
            opposite: true,
            labels: {
              show: true,
              formatter: function (val) {
                return val.toFixed(0);
              },
              style: {
                colors: "#00e396",
                // fontSize: "14px",
                // // fontFamily: "Poppins",
                // fontWeight: 100,
              },
            },
            // title: {
            //   text: " ",
            // },
          },
        ],
        fill: {
          // colors: ["#EB8153", "#2BC155"],
          type: "solid",
          opacity: 0.07,
        },
        grid: {
          borderColor: "#f1f1f1",
        },
      },
    };
  }

  render() {
    return (
      <div id="chart" className="line-chart-style bar-chart apex-chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="area"
          height={330}
        />
      </div>
    );
  }
  componentDidMount() {
    console.log("Did mount, updated Comparison Equity");
    try {
      this.updateEquityCurveData();
      // this.updateNewsAnalysisData();
    } catch (error) {
      console.log(error);
    }
  }

  componentDidUpdate(prevProps) {
    // Check if stockPriceData props has changed
    // console.log("prevProps.stockPriceData: ", prevProps.stockPriceData);
    // console.log("this.props.equityCurveData: ", this.props.equityCurveData);
    if (
      prevProps.equityCurveData !== this.props.equityCurveData
      // prevProps.newsAnalysisData !== this.props.newsAnalysisData ||
      // prevProps.transactionsData !== this.props.transactionsData
    ) {
      // Update stockPriceData in state
      try {
        this.updateEquityCurveData();
        // if (prevProps.stockPriceData !== this.props.stockPriceData) {
        //   console.log("Did update, updated stockPrice and news");
        //   this.updateEquityCurveData();
        //   this.updateNewsAnalysisData();
        // }
        // if (prevProps.newsAnalysisData !== this.props.newsAnalysisData) {
        //   console.log("Did update, updated News");
        //   this.updateNewsAnalysisData();
        // }
        // if (prevProps.transactionsData !== this.props.transactionsData) {
        //   console.log("Did update, updated Transactions");
        //   this.updateNewsAnalysisData();
        // }
      } catch (error) {
        console.log(error);
      }
    }
  }
  updateEquityCurveData() {
    const { equityCurveData } = this.props;

    // console.log(equityCurveData);

    if (
      equityCurveData &&
      equityCurveData.data &&
      equityCurveData.data.equity_per_day &&
      equityCurveData.data.equity_per_day["bt_1st_equity_per_day"] &&
      equityCurveData.data.equity_per_day["bt_2nd_equity_per_day"]
    ) {
      const firstBackTestData =
        equityCurveData.data.equity_per_day["bt_1st_equity_per_day"].data;
      const secondBackTestData =
        equityCurveData.data.equity_per_day["bt_2nd_equity_per_day"].data;

      // console.log(firstBackTestData);
      // console.log(secondBackTestData);

      // Find the minimum and maximum datetime values for both data sets
      // const minDate = Math.min(
      //   ...firstBackTestData.map((dataPoint) => new Date(dataPoint.x).getTime())
      // );
      // const maxFirstDate = Math.max(
      //   ...firstBackTestData.map((dataPoint) => new Date(dataPoint.x).getTime())
      // );
      const firstStartDate = new Date(firstBackTestData[0].x).getTime();
      const firstStartValue = firstBackTestData[0].y;
      // const firstEndDate = new Date(
      //   firstBackTestData[firstBackTestData.length - 1].x
      // ).getTime();
      // const firstEndValue = firstBackTestData[firstBackTestData.length - 1].y;
      const secondStartDate = new Date(secondBackTestData[0].x).getTime();
      const secondStartValue = secondBackTestData[0].y;
      // const secondEndDate = new Date(
      //   secondBackTestData[secondBackTestData.length - 1].x
      // ).getTime();
      // const secondEndValue =
      //   secondBackTestData[secondBackTestData.length - 1].y;

      let minFirstBackTestData = Infinity; // Initialize with a very high value
      for (const data of firstBackTestData) {
        const yValue = parseInt(data.y); // Convert y value to a number
        if (yValue < minFirstBackTestData) {
          minFirstBackTestData = yValue;
        }
      }
      // console.log("minFirstBackTestData: ", minFirstBackTestData);

      let maxFirstBackTestData = -Infinity; // Initialize with a very low value
      for (const data of firstBackTestData) {
        const yValue = parseInt(data.y); // Convert y value to a number
        if (yValue > maxFirstBackTestData) {
          maxFirstBackTestData = yValue;
        }
      }
      // console.log("maxFirstBackTestData: ", maxFirstBackTestData);

      let minSecondBackTestData = Infinity; // Initialize with a very high value
      for (const data of secondBackTestData) {
        const yValue = parseInt(data.y); // Convert y value to a number
        if (yValue < minSecondBackTestData) {
          minSecondBackTestData = yValue;
        }
      }
      // console.log("minSecondBackTestData: ", minSecondBackTestData);

      let maxSecondBackTestData = -Infinity; // Initialize with a very low value
      for (const data of secondBackTestData) {
        const yValue = parseInt(data.y); // Convert y value to a number
        if (yValue > maxSecondBackTestData) {
          maxSecondBackTestData = yValue;
        }
      }
      // console.log("maxSecondBackTestData ", maxSecondBackTestData);

      // console.log(maxFirstEquity);
      // const minFirstEquity = Math.min(...firstBackTestData);
      // console.log(minFirstEquity);

      const timeDifference = secondStartDate - firstStartDate;

      const firstDrop = Math.abs(firstStartValue - minFirstBackTestData);
      const secondDrop = Math.abs(secondStartValue - minSecondBackTestData);
      const firstRaise = Math.abs(maxFirstBackTestData - firstStartValue);
      const secondRaise = Math.abs(maxSecondBackTestData - secondStartValue);

      // console.log("firstDrop: ", firstDrop);
      // console.log("secondDrop: ", secondDrop);
      // console.log("firstRaise: ", firstRaise);
      // console.log("secondRaise: ", secondRaise);

      // console.log("firstStartValue: ", firstStartValue);
      // console.log("secondStartValue: ", secondStartValue);

      const minFirstAxis =
        parseFloat(firstStartValue) -
        Math.max(parseFloat(firstDrop), parseFloat(secondDrop));
      const maxFirstAxis =
        parseFloat(firstStartValue) +
        Math.max(parseFloat(firstRaise), parseFloat(secondRaise));
      const minSecondAxis =
        parseFloat(secondStartValue) -
        Math.max(parseFloat(firstDrop), parseFloat(secondDrop));
      const maxSecondAxis =
        parseFloat(secondStartValue) +
        Math.max(parseFloat(firstRaise), parseFloat(secondRaise));

      // console.log("minFirstAxis: ", minFirstAxis);
      // console.log("maxFirstAxis: ", maxFirstAxis);
      // console.log("minSecondAxis: ", minSecondAxis);
      // console.log("maxSecondAxis: ", maxSecondAxis);

      // Create a new array with shifted X-axis values for the second data set
      const shiftedSecondData = secondBackTestData.map((dataPoint) => ({
        x: new Date(new Date(dataPoint.x).getTime() - timeDifference),
        y: dataPoint.y,
      }));

      // const shiftedSecondData = [];

      // // Add null data points before the shiftedSecondData
      // for (let i = 0; i < firstBackTestData.length; i++) {
      //   shiftedSecondData.push({
      //     x: new Date(
      //       new Date(firstBackTestData[i].x).getTime() - timeDifference
      //     ),
      //     y: null,
      //   });
      // }

      // // Add the actual data points from secondBackTestData
      // for (let i = 0; i < secondBackTestData.length; i++) {
      //   shiftedSecondData.push({
      //     x: new Date(
      //       new Date(secondBackTestData[i].x).getTime() - timeDifference
      //     ),
      //     y: secondBackTestData[i].y,
      //   });
      // }

      // function ordinalSuffix(monthIndex) {
      //   const suffixes = ["th", "st", "nd", "rd"];
      //   const suffixIndex =
      //     monthIndex % 10 > 3
      //       ? 0
      //       : (monthIndex % 100) - 10 !== 0
      //       ? monthIndex % 10
      //       : 0;
      //   return suffixes[suffixIndex];
      // }

      function getMonthLabel(date) {
        const monthIndex = date.getMonth();
        const monthNumber = monthIndex + 1;
        let suffix = "th";

        if (monthNumber < 11 || monthNumber > 13) {
          switch (monthNumber % 10) {
            case 1:
              suffix = "st";
              break;
            case 2:
              suffix = "nd";
              break;
            case 3:
              suffix = "rd";
              break;
          }
        }
        return `${monthNumber}${suffix}`;
      }

      this.setState((prevState) => ({
        options: {
          ...prevState.options,
          xaxis: {
            // type: "datetime",
            tickAmount: "dataPoints",
            labels: {
              formatter: function (value) {
                const date = new Date(value);
                return getMonthLabel(date);
              },
            },
            title: {
              text: "Month",
              position: "bottom",
            },
            tickAmount: 12,
            tickPlacement: "between",
          },
          yaxis: [
            {
              ...prevState.options.yaxis[0],
              min: minFirstAxis,
              max: maxFirstAxis,
            },
            {
              ...prevState.options.yaxis[1],
              min: minSecondAxis,
              max: maxSecondAxis,
            },
          ],
          tooltip: {
            ...prevState.options.tooltip,
            x: {
              formatter: function (value, { series, seriesIndex }) {
                if (seriesIndex === 0) {
                  // For firstBackTestData series, display the original date
                  return new Date(value).toLocaleDateString();
                } else {
                  // For shiftedSecondData series, display the shifted date
                  const originalDate = new Date(value);
                  const shiftedDate = new Date(
                    originalDate.getTime() + timeDifference
                  );
                  return shiftedDate.toLocaleDateString(); // Adjust the formatting as desired
                }
              },
            },
          },
        },
        series: [
          {
            name: "1st BackTest",
            data: firstBackTestData,
          },
          {
            name: "2nd BackTest",
            data: shiftedSecondData,
          },
        ],
      }));

      // if (equityCurveData.data.length > 0) {
      //   console.log(equityCurveData.data[0]);

      // // Update stockPriceData in series state
      // this.setState((prevState) => ({
      //   series: [
      //     {
      //       ...prevState.series[0],
      //       data: stockPriceData.data[0].stock_close_price.data,
      //     },
      //     {
      //       ...prevState.series[1],
      //     },
      //   ],
      //   // seriesVolume: [
      //   //   {
      //   //     ...prevState.seriesVolume[0],
      //   //     data: stockPriceData.data[0].stock_volume.data,
      //   //   },
      //   // ],
      // }));
      // // console.log(this.state.optionsPrice.chart.id);
      // this.updateData(
      //   "all",
      //   this.state.optionsPrice.chart.id,
      //   `volume-${this.props.testId}`
      //   // this.state.optionsVolume.chart.id
      // );
    } else {
      console.log("no equity curve for updating graph");
    }
  }
}

export default ReturnComparisonLine;
