let direction = "ltr";

export const dezThemeSet = [
  {
    /* Default */ typography: "poppins",
    version: "light",
    layout: "vertical",
    headerBg: "color_1",
    navheaderBg: "color_1",
    sidebarBg: "color_1",
    sidebarStyle: "full",
    sidebarPosition: "fixed",
    headerPosition: "fixed",
    containerLayout: "full",
    direction: direction,
  },
  {
    /* Demo Theme 1 */ typography: "poppins",
    version: "light",
    layout: "vertical",
    primary: "color_5",
    headerBg: "color_1",
    navheaderBg: "color_5",
    sidebarBg: "color_5",
    sidebarStyle: "full",
    sidebarPosition: "fixed",
    headerPosition: "fixed",
    containerLayout: "wide",
    direction: direction,
  },
  {
    /* Demo Theme 2 */ typography: "poppins",
    version: "light",
    layout: "vertical",
    primary: "color_7",
    headerBg: "color_1",
    navheaderBg: "color_7",
    sidebarBg: "color_1",
    sidebarStyle: "modern",
    sidebarPosition: "static",
    headerPosition: "fixed",
    containerLayout: "wide",
    direction: direction,
  },
  {
    /* Demo Theme 3 */
    typography: "poppins",
    version: "light",
    layout: "vertical",
    primary: "color_15",
    headerBg: "color_1",
    navheaderBg: "color_13",
    sidebarBg: "color_13",
    sidebarStyle: "full",
    sidebarPosition: "fixed",
    headerPosition: "fixed",
    containerLayout: "wide",
    direction: direction,
  },
  {
    /* Demo Theme 4 */
    typography: "poppins",
    version: "light",
    layout: "vertical",
    primary: "color_9",
    headerBg: "color_9",
    navheaderBg: "color_9",
    sidebarBg: "color_1",
    sidebarStyle: "compact",
    sidebarPosition: "fixed",
    headerPosition: "fixed",
    containerLayout: "wide",
    direction: direction,
  },
  {
    /* Demo Theme 5 */
    typography: "poppins",
    version: "light",
    layout: "vertical",
    primary: "color_1",
    headerBg: "color_1",
    navheaderBg: "color_1",
    sidebarBg: "color_1",
    sidebarStyle: "full",
    sidebarPosition: "fixed",
    headerPosition: "fixed",
    containerLayout: "wide",
    direction: direction,
  },
  {
    /* Demo Theme 6 */
    typography: "poppins",
    version: "light",
    layout: "vertical",
    navheaderBg: "color_3",
    headerBg: "color_1",
    sidebarStyle: "mini",
    sidebarBg: "color_1",
    sidebarPosition: "fixed",
    headerPosition: "fixed",
    containerLayout: "wide",
    primary: "color_1",
    direction: direction,
  },
  {
    /* Demo Theme 7 */
    typography: "poppins",
    version: "light",
    layout: "vertical",
    navheaderBg: "color_2",
    headerBg: "color_1",
    sidebarStyle: "mini",
    sidebarBg: "color_2",
    sidebarPosition: "fixed",
    headerPosition: "fixed",
    containerLayout: "wide",
    primary: "color_2",
    direction: direction,
  },
  {
    /* Demo Theme 8 */
    typography: "poppins",
    version: "light",
    layout: "vertical",
    navheaderBg: "color_14",
    headerBg: "color_14",
    sidebarStyle: "full",
    sidebarBg: "color_2",
    sidebarPosition: "fixed",
    headerPosition: "fixed",
    containerLayout: "wide",
    primary: "color_2",
    direction: direction,
  },
];
