import { Nav, Tab } from "react-bootstrap";
import {
  CoinIcon1,
  CoinIcon2,
  CoinIcon3,
  CoinIcon4,
} from "../components/zenix/Transactions/TableData";

function StrategyTab({ handleStrategyTypeClick }) {
  const tabData = [
    {
      name: "Trend Following",
      // icon: <CoinIcon4 />,/
      icon: "fa-arrow-trend-up",
      color: "#374C98",
      strategyType: "TrendFollowing",
      content:
        "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove.",
    },
    {
      name: "Scalping",
      // icon: <CoinIcon3 />,
      icon: "fa-gauge-simple-high",
      color: "#FFAB2D",
      strategyType: "Scalping",
      content:
        "Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor.      ",
    },
    {
      name: "Mean Reversion",
      // icon: <CoinIcon2 />,
      icon: "fa-rotate",
      color: "#00ADA3",
      strategyType: "MeanReversion",
      content:
        "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove.",
    },
    {
      name: "Breakout Strategy",
      // icon: <CoinIcon1 />,
      icon: "fa-bacon",
      color: "#FF782C",
      strategyType: "BreakOutStrategy",
      content:
        "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove.",
    },
  ];
  return (
    <div className="card-action coin-tabs my-3 mt-sm-0">
      <div className="custom-tab-1">
        <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
          <Nav as="ul" className="nav-tabs">
            {tabData.map((data, i) => (
              <Nav.Item as="li" key={i}>
                <Nav.Link
                  eventKey={data.name.toLowerCase()}
                  onClick={() => handleStrategyTypeClick(data.strategyType)}
                >
                  {/* <i className={`la la-${data.icon} me-2`} /> */}
                  {/* {data.icon} */}
                  <div className="display-flex align-items-center">
                    <div
                      className="strategy-icon me-2"
                      style={{ backgroundColor: data.color }}
                    >
                      <i className={`fa-solid ${data.icon}`} />
                    </div>
                    <span className="tab-text">{data.name}</span>
                  </div>
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Tab.Container>
      </div>
      {/* <Nav as="ul" className="nav nav-tabs" role="tablist">
        <Nav.Item as="li">
          <Nav.Link
            to={"#"}
            // eventKey="TrendFollowing"
            onClick={() => handleStrategyTypeClick("TrendFollowing")}
          >
            <CoinIcon4 />
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link
            to={"#"}
            // eventKey="Scalping"
            onClick={() => handleStrategyTypeClick("Scalping")}
          >
            <CoinIcon3 />
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link
            to={"#"}
            // eventKey="MeanReversion"
            onClick={() => handleStrategyTypeClick("MeanReversion")}
          >
            <CoinIcon2 />
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link
            to={"#"}
            // eventKey="BreakOutStrategy"
            onClick={() => handleStrategyTypeClick("BreakOutStrategy")}
          >
            <CoinIcon1 />
          </Nav.Link>
        </Nav.Item>
      </Nav> */}
    </div>
  );
}

export default StrategyTab;
