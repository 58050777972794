export const GET_TRANSACTIONS_SUCCESS_ACTION =
  "[strategytest action] Confirmed Get Transactions Back Test";
export const GET_TRANSACTIONS_FAILED_ACTION =
  "[strategytest action] Failed Get Transactions Back Test";

const baseURL =
  "https://5xb3pvggaf.execute-api.ap-southeast-1.amazonaws.com/dev";

export function getTransactionsAction(test_id) {
  return async (dispatch) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          test_id: test_id,
        }),
      };
      const res = await fetch(`${baseURL}/transaction`, requestOptions);
      const result = await res.json();
      dispatch(getTransactionsSuccessAction(result));
    } catch (error) {
      dispatch(getTransactionsFailedAction(error));
    }
  };
}

export function getTransactionsSuccessAction(payload) {
  return {
    type: GET_TRANSACTIONS_SUCCESS_ACTION,
    payload,
  };
}

export function getTransactionsFailedAction(message) {
  return {
    type: GET_TRANSACTIONS_FAILED_ACTION,
    payload: message,
  };
}
