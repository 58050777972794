import React from "react";
import ReactApexChart from "react-apexcharts";

class CryptoStatistics extends React.Component {
  constructor(props) {
    super(props);

    // console.log(props.equityCurveData);

    this.state = {
      series: [
        {
          name: "Equity",
          type: "line",
          // data: [
          //   150000000, 160379978, 170493749, 160785250, 167391904, 161576838,
          //   161413854, 152177211, 140762210, 144381072, 154352310, 165531790,
          //   145748881, 157064037, 197520685, 210176418, 196122924, 207337480,
          // ],
          data: [],
        },
        // {
        //   name: "Entries",
        //   type: "scatter",
        //   data: [
        //     {
        //       x: new Date("2017-01-16").getTime(),
        //       y: 160000000,
        //     },
        //     {
        //       x: new Date("2017-01-17").getTime(),
        //       y: 170379978,
        //     },
        //     {
        //       x: new Date("2017-01-18").getTime(),
        //       y: 180493749,
        //     },
        //     {
        //       x: new Date("2017-01-19").getTime(),
        //       y: 150785250,
        //     },
        //     {
        //       x: new Date("2017-01-20").getTime(),
        //       y: 177391904,
        //     },
        //     {
        //       x: new Date("2017-01-21").getTime(),
        //       y: 151576838,
        //     },
        //     {
        //       x: new Date("2017-01-22").getTime(),
        //       y: 151413854,
        //     },
        //     {
        //       x: new Date("2017-01-23").getTime(),
        //       y: 142177211,
        //     },
        //     {
        //       x: new Date("2017-01-24").getTime(),
        //       y: 130762210,
        //     },
        //     {
        //       x: new Date("2017-01-25").getTime(),
        //       y: 154381072,
        //     },
        //     {
        //       x: new Date("2017-01-26").getTime(),
        //       y: 164352310,
        //     },
        //     {
        //       x: new Date("2017-01-27").getTime(),
        //       y: 175531790,
        //     },
        //     {
        //       x: new Date("2017-01-28").getTime(),
        //       y: 185748881,
        //     },
        //     {
        //       x: new Date("2017-01-29").getTime(),
        //       y: 197064037,
        //     },
        //     {
        //       x: new Date("2017-01-30").getTime(),
        //       y: 207520685,
        //     },
        //     {
        //       x: new Date("2017-01-31").getTime(),
        //       y: 220176418,
        //     },
        //     {
        //       x: new Date("2017-02-01").getTime(),
        //       y: 186122924,
        //     },
        //     {
        //       x: new Date("2017-02-02").getTime(),
        //       y: 197337480,
        //     },
        //     // {
        //     //   x: new Date("2017-05-15").getTime(),
        //     //   y: 107956992,
        //     // },
        //   ],
        // },
        {
          name: "1st BT Average",
          type: "line",
          data: [],
          // [
          //   {
          //     x: new Date("2017-01-16").getTime(),
          //     y: 160379978,
          //   },
          //   {
          //     x: new Date("2017-01-24").getTime(),
          //     y: 150762210,
          //   },
          // ],
        },
        {
          name: "2nd BT Average",
          type: "line",
          data: [],
          // [
          //   {
          //     x: new Date("2017-01-24").getTime(),
          //     y: 145762210,
          //   },
          //   {
          //     x: new Date("2017-01-29").getTime(),
          //     y: 160064037,
          //   },
          // ],
        },
        {
          name: "Forward Average",
          type: "line",
          data: [],
          // [
          //   {
          //     x: new Date("2017-01-29").getTime(),
          //     y: 167064037,
          //   },
          //   {
          //     x: new Date("2017-02-02").getTime(),
          //     y: 217337480,
          //   },
          // ],
        },
      ],
      // series: [
      //   {
      //     name: "series1",
      //     data: [
      //       30, 50, 40, 50, 50, 40, 30, 45, 55, 65, 50, 30, 50, 40, 50, 50, 40,
      //       30, 45, 55, 65, 50,
      //     ],
      //   },
      //   {
      //     name: "series2",
      //     data: [
      //       20, 40, 30, 40, 40, 30, 20, 35, 45, 55, 40, 20, 40, 30, 40, 40, 30,
      //       20, 35, 45, 55, 40,
      //     ],
      //   },
      // ],
      options: {
        chart: {
          // type: "area",
          // type: "line",
          stacked: false,
          height: 350,
          width: 100,
          zoom: {
            // enabled: false,
            type: "xy",
            enabled: true,
            // autoScaleYaxis: true,
            // autoScaleXaxis: true,
          },
          toolbar: {
            autoSelected: "zoom",
            tools: {
              download: false,
              selection: true,
              zoom: true,
              zoomin: false,
              zoomout: false,
              pan: true,
              reset: true,
              // reset: true | '<img src="/static/icons/reset.png" width="20">',
              // customIcons: [],
            },
            // export: {
            //   // csv: {
            //   //   filename: undefined,
            //   //   columnDelimiter: ",",
            //   //   headerCategory: "category",
            //   //   headerValue: "value",
            //   //   dateFormatter(timestamp) {
            //   //     return new Date(timestamp).toDateString();
            //   //   },
            //   // },
            //   svg: {
            //     filename: undefined,
            //   },
            //   png: {
            //     filename: undefined,
            //   },
            // },
          },
          // events: {
          //   dataPointSelection: function (event, chartContext, config) {
          //     console.log(chartContext, config);
          //   },
          // },
        },
        colors: ["#008FFB", "#00E396", "#fec960", "#c960fe"],
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          // size: [0, 0, 6],
          hover: {
            size: 6,
          },
        },
        title: {
          text: "Equity Curve",
          align: "left",
        },
        subtitle: {
          text: "USD",
          align: "left",
        },
        fill: {
          type: "solid",
          // type: "gradient",
          // gradient: {
          //   shadeIntensity: 1,
          //   inverseColors: false,
          //   opacityFrom: 0.5,
          //   opacityTo: 0,
          //   stops: [0, 90, 100],
          // },
        },
        legend: {
          show: false,
        },
        yaxis: {
          // labels: {
          //   formatter: function (val) {
          //     return (val / 1000000).toFixed(0);
          //   },
          // },
          labels: {
            formatter: function (val) {
              return val.toFixed(0);
            },
          },
          title: {
            text: "Equity",
          },
        },
        xaxis: {
          type: "datetime",
        },
        stroke: {
          width: [5, 4, 4, 4],
          curve: ["smooth", "straight", "straight", "straight"],
          dashArray: [0, 4, 4, 4],
        },
        tooltip: {
          enabled: true,
          // shared: false,
          followCursor: true,
          // y: {
          //   formatter: function (val) {
          //     return (val / 1000000).toFixed(0);
          //   },
          // },
        },
        annotations: {
          xaxis: [
            {
              // x: new Date("2017-01-16").getTime(),
              // x2: new Date("2017-01-24").getTime(),
              x: "",
              x2: "",
              // borderColor: "#00E396",
              fillColor: "#B3F7CA",
              opacity: 0.1,
              label: {
                borderColor: "#68CF29",
                style: {
                  fontSize: "12px",
                  color: "#fff",
                  background: "#68CF29",
                },
                orientation: "horizontal",
                offsetY: -10,
                text: "1st Back Test",
                textAnchor: "start",
              },
            },
            {
              // x: new Date("2017-01-24").getTime(),
              // x2: new Date("2017-01-29").getTime(),
              x: "",
              x2: "",
              // borderColor: "#00E396",
              fillColor: "#ffe1a6",
              opacity: 0.1,
              label: {
                borderColor: "#eb8153",
                style: {
                  fontSize: "12px",
                  color: "#fff",
                  background: "#eb8153",
                },
                orientation: "horizontal",
                offsetY: -10,
                text: "2nd Back Test",
                textAnchor: "start",
              },
            },
            {
              // x: new Date("2017-01-29").getTime(),
              // x2: new Date("2017-02-02").getTime(),
              x: "",
              x2: "",
              fillColor: "#baacfe",
              opacity: 0.1,
              // borderColor: "#eb8153",
              label: {
                borderColor: "#7a60fe",
                style: {
                  fontSize: "12px",
                  color: "#fff",
                  background: "#7a60fe",
                },
                orientation: "horizontal",
                offsetY: -10,
                text: "Forward Test",
                // textAnchor: "start",
              },
            },
          ],
          // points: [
          //   {
          //     x: new Date("2017-01-28").getTime(),
          //     y: 175531790,
          //     marker: {
          //       offsetX: 23,
          //       size: 6,
          //       fillColor: "#fff",
          //       strokeColor: "#2698FF",
          //       radius: 2,
          //     },
          //     label: {
          //       borderColor: "#FF4560",
          //       offsetY: 0,
          //       offsetX: 23,
          //       style: {
          //         color: "#fff",
          //         background: "#FF4560",
          //       },

          //       text: "News 1",
          //     },
          //   },
          // ],
        },
      },
      // options: {
      //   chart: {
      //     height: 360,
      //     toolbar: {
      //       show: false,
      //     },
      //     type: "area",
      //   },
      //   colors: ["#FFAB2D", "#AC4CBC"],
      //   legend: {
      //     show: false,
      //   },
      //   dataLabels: {
      //     enabled: false,
      //   },
      //   stroke: {
      //     width: 4,
      //     curve: "smooth",
      //   },
      //   grid: {
      //     borderColor: "#eee",
      //     strokeDashArray: 8,
      //   },
      //   xaxis: {
      //     categories: [
      //       "Jan",
      //       "Feb",
      //       "Mar",
      //       "Apr",
      //       "May",
      //       "Jun",
      //       "Jul",
      //       "Aug",
      //       "Sep",
      //       "Oct",
      //       "Nov",
      //       "Jan",
      //       "Feb",
      //       "Mar",
      //       "Apr",
      //       "May",
      //       "Jun",
      //       "Jul",
      //       "Aug",
      //       "Sep",
      //       "Oct",
      //       "Nov",
      //     ],
      //     labels: {
      //       style: {
      //         colors: "#787878",
      //         fontSize: "14px",
      //         fontFamily: "Poppins",
      //         fontWeight: 100,
      //       },
      //     },
      //   },
      //   yaxis: {
      //     show: false,
      //   },
      //   fill: {
      //     opacity: 0.2,
      //     type: "solid",
      //   },
      //   tooltip: {
      //     x: {
      //       format: "dd/MM/yy HH:mm",
      //     },
      //   },
      // },
    };
  }

  render() {
    return (
      <div id="chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          // type="line"
          height={320}
        />
        {/* {console.log(this.props.equityCurveData)} */}
      </div>
    );
  }
  componentDidMount() {
    console.log("Did mount, updated equityCurve");
    try {
      this.updateEquityCurveData();
      // this.updateTestDates();
      // this.updateAverageLine();
    } catch (error) {
      console.log(error);
    }
  }

  componentDidUpdate(prevProps) {
    // Check if stockPriceData props has changed
    // console.log("prevProps.stockPriceData: ", prevProps.stockPriceData);
    // console.log("this.props.stockPriceData: ", this.props.stockPriceData);
    if (prevProps.equityCurveData !== this.props.equityCurveData) {
      // Update stockPriceData in state
      console.log("Did update, updated equityCurve");
      // try {
      this.updateEquityCurveData();
      // this.updateTestDates();
      // this.updateAverageLine();
      // const executeFunctions = async () => {
      //   await this.updateTestDates();
      //   await this.updateEquityCurveData();
      //   // await this.updateAverageLine();
      //   // setTimeout(() => {
      //   //   this.updateAverageLine();
      //   // }, 5000); // 5 seconds delay
      // };

      // executeFunctions();
      // } catch (error) {
      //   console.log(error);
      // }
    }
  }

  updateEquityCurveData() {
    const { equityCurveData, overviewData } = this.props;
    // console.log(equityCurveData);
    // console.log(overviewData);
    if (
      equityCurveData &&
      equityCurveData.data.equity_per_day &&
      equityCurveData.data.equity_per_day["bt_1st_equity_per_day"] &&
      equityCurveData.data.equity_per_day["bt_2nd_equity_per_day"] &&
      equityCurveData.data.equity_per_day["ft_equity_per_day"] &&
      equityCurveData.data.annotations["xaxis"] &&
      equityCurveData.data.annotations["xaxis"].length >= 3
    ) {
      // console.log(equityCurveData.data.equity_per_day);
      const firstBTData =
        equityCurveData.data.equity_per_day["bt_1st_equity_per_day"].data;
      const secondBTData =
        equityCurveData.data.equity_per_day["bt_2nd_equity_per_day"].data;
      const forwardData =
        equityCurveData.data.equity_per_day["ft_equity_per_day"].data;

      // Update 1st BT Average
      let firstBTStart;
      let firstBTEnd;
      let firstBTStartValue;
      let firstBTEndValue;
      if (firstBTData && firstBTData.length > 0) {
        firstBTStart = new Date(firstBTData[0].x).getTime();
        firstBTEnd = new Date(firstBTData[firstBTData.length - 1].x).getTime();
        firstBTStartValue = parseFloat(firstBTData[0].y);
        firstBTEndValue = parseFloat(firstBTData[firstBTData.length - 1].y);
        // console.log(firstBTData[0].x);
        // console.log(firstBTData[firstBTData.length - 1].x);
      } else {
        console.log("No First Back Test Equity Curve data");
      }

      // series[1].data = [
      //   { x: firstBTStart, y: firstBTStartValue },
      //   { x: firstBTEnd, y: firstBTEndValue },
      // ];
      // } else {
      //   console.log("no 1st Equity Curve data");
      // }

      // Update 2nd BT Average
      let secondBTStart;
      let secondBTEnd;
      let secondBTStartValue;
      let secondBTEndValue;
      if (secondBTData && secondBTData.length > 0) {
        secondBTStart = new Date(secondBTData[0].x).getTime();
        secondBTEnd = new Date(
          secondBTData[secondBTData.length - 1].x
        ).getTime();
        secondBTStartValue = parseFloat(secondBTData[0].y);
        secondBTEndValue = parseFloat(secondBTData[secondBTData.length - 1].y);
        // console.log(secondBTData[0].x);
        // console.log(secondBTData[secondBTData.length - 1].x);
      } else {
        console.log("No Second Back Test Equity Curve data");
      }

      // series[2].data = [
      //   { x: secondBTStart, y: secondBTStartValue },
      //   { x: secondBTEnd, y: secondBTEndValue },
      // ];
      // } else {
      //   console.log("no 2nd Equity Curve data");
      // }

      // Update Forward Average
      let forwardStart;
      let forwardEnd;
      let forwardStartValue;
      let forwardEndValue;
      if (forwardData && forwardData.length > 0) {
        forwardStart = new Date(forwardData[0].x).getTime();
        forwardEnd = new Date(forwardData[forwardData.length - 1].x).getTime();
        forwardStartValue = parseFloat(forwardData[0].y);
        forwardEndValue = parseFloat(forwardData[forwardData.length - 1].y);
        // console.log(forwardData[0].x);
        // console.log(forwardData[forwardData.length - 1].x);
        // series[3].data = [
        //   { x: forwardStart, y: forwardStartValue },
        //   { x: forwardEnd, y: forwardEndValue },
        // ];
      } else {
        console.log("No Forward Test Equity Curve data");
      }

      const annotationsXaxis = this.state.options.annotations.xaxis;
      // const equityCurveXaxis = equityCurveData.data.annotations["xaxis"];
      // const [first, second, third] = equityCurveXaxis;

      // optionsXaxis[0].x = new Date(first.x).getTime();
      // optionsXaxis[0].x2 = new Date(second.x).getTime();
      // optionsXaxis[1].x = new Date(second.x).getTime();
      // optionsXaxis[1].x2 = new Date(third.x).getTime();
      // optionsXaxis[2].x = new Date(third.x).getTime();

      annotationsXaxis[0].x = new Date(overviewData.bt_start_date).getTime();
      annotationsXaxis[0].x2 = new Date(overviewData.bt_end_date).getTime();
      annotationsXaxis[1].x = new Date(
        overviewData.bt_2nd_start_date
      ).getTime();
      annotationsXaxis[1].x2 = new Date(overviewData.bt_2nd_end_date).getTime();
      annotationsXaxis[2].x = new Date(overviewData.ft_start_date).getTime();
      annotationsXaxis[2].x2 = new Date().getTime();

      // if (this.props.equityCurveData.data.equity_per_day) {
      // console.log(this.props.stockPriceData.data[0].stock_close_price.data);
      this.setState((prevState) => {
        const updatedSeries = [...prevState.series];
        let btOverallData;
        let overallData;
        if (
          equityCurveData &&
          equityCurveData.data &&
          equityCurveData.data.equity_per_day &&
          equityCurveData.data.equity_per_day["bt_overall_equity_per_day"] &&
          equityCurveData.data.equity_per_day["bt_overall_equity_per_day"].data
        ) {
          btOverallData =
            equityCurveData.data.equity_per_day["bt_overall_equity_per_day"]
              .data;

          //add one more date in front the same in order to show in chart
          if (btOverallData && btOverallData.length > 1) {
            const firstData = btOverallData[0];
            const secondData = btOverallData[1];

            if (firstData.y !== secondData.y) {
              const firstDate = new Date(firstData.x);
              firstDate.setDate(firstDate.getDate() - 1);
              const newFirstData = {
                x: firstDate.toISOString(),
                y: firstData.y,
              };

              btOverallData.unshift(newFirstData);
            }
          }
          if (forwardData && forwardData.length > 0) {
            const gapData = [];

            // Get the last date from btOverallData
            const lastDate = new Date(
              btOverallData[btOverallData.length - 1].x
            );

            // Get the first date from forwardData
            const firstDate = new Date(forwardData[0].x);

            // Iterate over the dates between lastDate and firstDate
            const currentDate = new Date(lastDate);
            currentDate.setDate(currentDate.getDate() + 1);

            while (currentDate < firstDate) {
              gapData.push({ x: currentDate.toISOString(), y: "0" });
              currentDate.setDate(currentDate.getDate() + 1);
            }

            gapData.push(...forwardData);
            overallData = [...btOverallData, ...gapData];
          } else {
            overallData = btOverallData;
          }
          // console.log(btOverallData);
          // console.log(overallData);
          // updatedSeries[0] = {
          //   ...prevState.series[0],
          //   data: overallData,
          // };
          updatedSeries[0] = {
            ...prevState.series[0],
            // data: equityCurveData.data.equity_per_day[
            //   "bt_overall_equity_per_day"
            // ].data,
            data: overallData,
          };
        }

        if (
          firstBTStart &&
          firstBTStartValue &&
          firstBTEnd &&
          firstBTEndValue
        ) {
          updatedSeries[1] = {
            ...prevState.series[1],
            data: [
              { x: firstBTStart, y: firstBTStartValue },
              { x: firstBTEnd, y: firstBTEndValue },
            ],
          };
        } else {
          updatedSeries[1] = {
            ...prevState.series[1],
            data: [],
          };
        }
        if (
          secondBTStart &&
          secondBTStartValue &&
          secondBTEnd &&
          secondBTEndValue
        ) {
          updatedSeries[2] = {
            ...prevState.series[2],
            data: [
              { x: secondBTStart, y: secondBTStartValue },
              { x: secondBTEnd, y: secondBTEndValue },
            ],
          };
        } else {
          updatedSeries[2] = {
            ...prevState.series[2],
            data: [],
          };
        }
        if (
          forwardStart &&
          forwardStartValue &&
          forwardEnd &&
          forwardEndValue
        ) {
          updatedSeries[3] = {
            ...prevState.series[3],
            data: [
              { x: forwardStart, y: forwardStartValue },
              { x: forwardEnd, y: forwardEndValue },
            ],
          };
        } else {
          updatedSeries[3] = {
            ...prevState.series[3],
            data: [],
          };
        }
        return {
          series: updatedSeries,
          // series: [
          //   {
          //     ...prevState.series[0],
          //     data: equityCurveData.data.equity_per_day[
          //       "bt_overall_equity_per_day"
          //     ].data,
          //   },
          //   {
          //     ...prevState.series[1],
          //     data: [
          //       { x: firstBTStart, y: firstBTStartValue },
          //       { x: firstBTEnd, y: firstBTEndValue },
          //     ],
          //   },
          //   {
          //     ...prevState.series[2],
          //     data: [
          //       { x: secondBTStart, y: secondBTStartValue },
          //       { x: secondBTEnd, y: secondBTEndValue },
          //     ],
          //   },
          //   // {
          //   //   ...prevState.series[3],
          //   //   data: [
          //   //     { x: forwardStart, y: forwardStartValue },
          //   //     { x: forwardEnd, y: forwardEndValue },
          //   //   ],
          //   // },
          // ],
          options: {
            ...prevState.options,
            annotations: {
              ...prevState.options.annotations,
              xaxis: [...annotationsXaxis],
            },
          },
        };
      });
    } else {
      console.log("no equity curve data");
    }
  }
  updateTestDates = () => {
    const { equityCurveData } = this.props;
    const annotationsXaxis = this.state.options.annotations.xaxis;
    // console.log(overviewData);
    // console.log(equityCurveData);
    // console.log(optionsXaxis);
    // console.log(equityCurveData.data.annotations["xaxis"]);

    if (
      equityCurveData &&
      equityCurveData.data &&
      equityCurveData.data.annotations["xaxis"] &&
      equityCurveData.data.annotations["xaxis"].length >= 3
    ) {
      const equityCurveXaxis = equityCurveData.data.annotations["xaxis"];
      const [first, second, third] = equityCurveXaxis;

      annotationsXaxis[0].x = new Date(first.x).getTime();
      annotationsXaxis[0].x2 = new Date(second.x).getTime();
      annotationsXaxis[1].x = new Date(second.x).getTime();
      annotationsXaxis[1].x2 = new Date(third.x).getTime();
      annotationsXaxis[2].x = new Date(third.x).getTime();
      annotationsXaxis[2].x2 = new Date().getTime();

      this.setState((prevState) => ({
        options: {
          ...prevState.options,
          annotations: {
            ...prevState.options.annotations,
            xaxis: [...annotationsXaxis],
          },
        },
      }));
    } else {
      console.log("no Test Dates data");
    }
  };
  updateAverageLine = () => {
    const { equityCurveData } = this.props;
    const { series } = this.state;
    console.log(series);
    if (
      equityCurveData &&
      equityCurveData.data.equity_per_day["bt_1st_equity_per_day"] &&
      equityCurveData.data.equity_per_day["bt_2nd_equity_per_day"] &&
      equityCurveData.data.equity_per_day["ft_equity_per_day"]
    ) {
      // console.log(equityCurveData.data.equity_per_day);
      const firstBTData =
        equityCurveData.data.equity_per_day["bt_1st_equity_per_day"].data;
      const secondBTData =
        equityCurveData.data.equity_per_day["bt_2nd_equity_per_day"].data;
      const forwardData =
        equityCurveData.data.equity_per_day["ft_equity_per_day"].data;

      // Update 1st BT Average
      if (firstBTData && firstBTData.length > 0) {
        const firstBTStart = new Date(firstBTData[0].x).getTime();
        // const firstBTStartDate = new Date(firstBTData[0].x);
        // firstBTStartDate.setDate(firstBTStartDate.getDate() + 10);
        // const firstBTStart = firstBTStartDate.getTime();
        const firstBTEnd = new Date(
          firstBTData[firstBTData.length - 1].x
        ).getTime();
        const firstBTStartValue = parseFloat(firstBTData[0].y);
        const firstBTEndValue = parseFloat(
          firstBTData[firstBTData.length - 1].y
        );

        series[1].data = [
          { x: firstBTStart, y: firstBTStartValue },
          { x: firstBTEnd, y: firstBTEndValue },
        ];
      } else {
        console.log("no 1st Equity Curve data");
      }

      // Update 2nd BT Average
      if (secondBTData && secondBTData.length > 0) {
        const secondBTStart = new Date(secondBTData[0].x).getTime();
        const secondBTEnd = new Date(
          secondBTData[secondBTData.length - 1].x
        ).getTime();
        const secondBTStartValue = parseFloat(secondBTData[0].y);
        const secondBTEndValue = parseFloat(
          secondBTData[secondBTData.length - 1].y
        );

        series[2].data = [
          { x: secondBTStart, y: secondBTStartValue },
          { x: secondBTEnd, y: secondBTEndValue },
        ];
      } else {
        console.log("no 2nd Equity Curve data");
      }

      // Update Forward Average
      if (forwardData && forwardData.length > 0) {
        const forwardStart = new Date(forwardData[0].x).getTime();
        const forwardEnd = new Date(
          forwardData[forwardData.length - 1].x
        ).getTime();
        const forwardStartValue = parseFloat(forwardData[0].y);
        const forwardEndValue = parseFloat(
          forwardData[forwardData.length - 1].y
        );

        series[3].data = [
          { x: forwardStart, y: forwardStartValue },
          { x: forwardEnd, y: forwardEndValue },
        ];
      } else {
        console.log("no forward Equity Curve data");
      }

      console.log(series);

      this.setState({ series });
    } else {
      console.log("no average line date");
    }
  };
}

export default CryptoStatistics;
