import React from "react";
import ReactApexChart from "react-apexcharts";
import ApexCharts from "apexcharts";
import { Nav, Button, Collapse } from "react-bootstrap";

class StockVolume extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selection: "all",

      seriesVolume: [
        {
          name: "volume",
          data: [],
        },
      ],
      optionsVolume: {
        chart: {
          id: `volume-${props.testId}`,
          // group: `${props.testId}`,
          type: "bar",
          toolbar: {
            show: false,
          },
          offsetY: -10,
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          // enabled: false,
          // followCursor: true,
          x: {
            format: "dd MMM yyyy",
          },
          intersect: false,
          shared: true,
        },
        plotOptions: {
          bar: {
            columnWidth: "100%",
            colors: {
              ranges: [
                {
                  from: -1000,
                  to: 0,
                  color: "#F15B46",
                },
                {
                  from: 1,
                  to: 10000,
                  color: "#FEB019",
                },
              ],
            },
          },
        },
        stroke: {
          width: 0,
        },
        // title: {
        //   text: "Volume",
        //   align: "left",
        //   style: {
        //     fontSize: "12px",
        //   },
        // },
        xaxis: {
          type: "datetime",
        },
        yaxis: {
          labels: {
            show: true,
            minWidth: 50,
            maxWidth: 50,
          },
          // title: {
          //   text: "Volume",
          // },
        },
      },
    };
    // this.changeMouseState = this.changeMouseState.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
  }
  // changeMouseState() {
  //   this.setState({ mouse: "out" });
  // }

  toggleCollapse() {
    console.log("collapse volume");
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  }

  updateData(timeline, volumeId) {
    this.setState({
      selection: timeline,
    });

    // const { series } = this.state;
    // const stockPriceData = series[0].data;

    const { stockPriceData } = this.props;
    // console.log(stockPriceData);

    if (stockPriceData.length > 0) {
      // Get the last row of data from stockPriceData
      const lastRow = stockPriceData[stockPriceData.length - 1];
      const lastRowDate = new Date(lastRow.x);

      // Calculate one month ago from the last row's date
      const oneMonthAgo = new Date(
        lastRowDate.getFullYear(),
        lastRowDate.getMonth() - 1,
        lastRowDate.getDate()
      ).getTime();

      const sixMonthsAgo = new Date(
        lastRowDate.getFullYear(),
        lastRowDate.getMonth() - 6,
        lastRowDate.getDate()
      ).getTime();

      const twelveMonthsAgo = new Date(
        lastRowDate.getFullYear() - 1,
        lastRowDate.getMonth(),
        lastRowDate.getDate()
      ).getTime();

      const firstRow = stockPriceData[0];
      const firstRowDate = new Date(firstRow.x);
      const yearToDate = new Date(
        firstRowDate.getFullYear(),
        0, // January (month index 0)
        1 // First day of the month
      ).getTime();

      // console.log(this.state.options.chart.id);
      switch (timeline) {
        case "one_month":
          // ApexCharts.exec(stockId, "zoomX", oneMonthAgo, lastRow.x);
          ApexCharts.exec(volumeId, "zoomX", oneMonthAgo, lastRow.x);
          this.setState({ mouse: "in" });
          break;
        case "six_months":
          // ApexCharts.exec(stockId, "zoomX", sixMonthsAgo, lastRow.x);
          ApexCharts.exec(volumeId, "zoomX", sixMonthsAgo, lastRow.x);
          this.setState({ mouse: "in" });
          break;
        case "one_year":
          // ApexCharts.exec(stockId, "zoomX", twelveMonthsAgo, lastRow.x);
          ApexCharts.exec(volumeId, "zoomX", twelveMonthsAgo, lastRow.x);
          this.setState({ mouse: "in" });
          break;
        case "ytd":
          // ApexCharts.exec(stockId, "zoomX", yearToDate, lastRow.x);
          ApexCharts.exec(volumeId, "zoomX", yearToDate, lastRow.x);
          // this.state.options.chart.toolbar.autoSelected = "pan";
          // ApexCharts.exec("stock-price", "updateOptions", {
          //   chart: this.state.options.chart,
          // });
          this.setState({ mouse: "in" });
          break;
        case "all":
          // ApexCharts.exec(stockId, "zoomX", firstRow.x, lastRow.x);
          ApexCharts.exec(volumeId, "zoomX", firstRow.x, lastRow.x);
          this.setState({ mouse: "in" });
          break;
        default:
      }
    }
  }

  render() {
    return (
      <div>
        <div id="stock-price-chart">
          <div class="chart-box">
            {/* <div id="chart-timeline">
              <ReactApexChart
                options={this.state.optionsPrice}
                series={this.state.series}
                type="area"
                height={250}
                // ref={(ref) => (this.chartRef = ref)}
              />
            </div> */}
            <div className="card-body py-0 px-0 display-flex justify-content-center">
              <Button
                onClick={this.toggleCollapse}
                className="me-2"
                variant="outline-primary btn-xs"
              >
                {this.state.isOpen ? "Hide Volume" : "Show Volume"}
              </Button>
            </div>
            <Collapse in={this.state.isOpen}>
              <div id="chart-bar">
                <ReactApexChart
                  options={this.state.optionsVolume}
                  series={this.state.seriesVolume}
                  type="bar"
                  height={100}
                  // width={90}
                />
              </div>
            </Collapse>
          </div>
          {/* {this.props.stockPriceData.data.length > 0
            ? console.log(
                this.props.stockPriceData.data[0].stock_close_price.data
              )
            : // this.setState({
              //   stockPriceData:
              //     this.props.stockPriceData.data[0].stock_close_price.data,
              // })
              console.log("no stock price data")} */}
          {/* {console.log(this.state.options.chart.id)} */}
          {/* {console.log(this.props.newsAnalysisData)} */}
          {/* {console.log(this.state.options.annotations.xaxis)} */}
          {/* {console.log(this.props.testId)} */}
          {/* {this.setState({ testId: this.props.testId })} */}
        </div>
        <div id="html-dist"></div>
      </div>
    );
  }

  // updateBackgroundProperty() {
  //   this.setState((prevState) => {
  //     const updatedOptions = { ...prevState.optionsPrice }; // Create a copy of options
  //     const labelStyle = { ...updatedOptions.annotations.xaxis[0].label.style }; // Create a copy of label style
  //     labelStyle.background = "#2d3e91"; // Update the background property
  //     updatedOptions.annotations.xaxis[0].label.style = labelStyle; // Assign the updated label style back to options

  //     return { optionsPrice: updatedOptions }; // Return the updated options object
  //   });
  // }
  componentDidMount() {
    console.log("Did mount, updated stockPrice");
    try {
      this.updateStockVolumeData();
    } catch (error) {
      console.log(error);
    }
    // this.updateNewsAnalysisData();

    // this.setState({ testId: this.props.testId });

    // const container = document.getElementById(
    //   `apexchartsstock-price-${this.props.testId}`
    // );
    // console.log(container);
    // const labels = container.querySelectorAll(
    //   ".apexcharts-xaxis-annotations > rect"
    // );
    // console.log(labels);

    // labels.forEach((item, index) => {
    //   // console.log(item.outerHTML);
    //   // console.log(item.textContent);
    //   // console.log(index);

    //   item.addEventListener("mouseover", (event) => {
    //     // if (item.textContent) {
    //     // this.setState({ newsFocus: item.textContent });
    //     this.setState({ mouse: "out" });
    //     this.setState({ newsIndex: index });
    //     let newsIndex = index;
    //     console.log("newsIndex: " + newsIndex);
    //     this.props.setNewsIndex(newsIndex, true);

    //     // if (item.textContent == this.state.newsFocus) {
    //     // console.log(item.outerHTML);
    //     if (newsIndex >= 0) {
    //       this.state.optionsPrice.annotations.xaxis[
    //         newsIndex
    //       ].label.style.background = "#4ecf2d";
    //       this.state.optionsPrice.annotations.xaxis[
    //         newsIndex
    //       ].label.borderColor = "#4ecf2d";
    //       console.log(this.state.optionsPrice.chart.id);
    //       ApexCharts.exec(
    //         this.state.optionsPrice.chart.id,
    //         "updateOptions",
    //         {
    //           annotations: this.state.optionsPrice.annotations,
    //         },
    //         false,
    //         true
    //       );
    //     }
    //   });

    //   // item.addEventListener("mouseout", (event) => {

    //   //   // console.log("mouseout");
    //   //   if (item.textContent == "News 1") {
    //   //     console.log(item.outerHTML);
    //   //     this.state.options.annotations.xaxis[0].label.style.background =
    //   //       "#4ecf2d";
    //   //     ApexCharts.exec(
    //   //       "stock-price",
    //   //       "updateOptions",
    //   //       {
    //   //         annotations: this.state.options.annotations,
    //   //       },
    //   //       false,
    //   //       true
    //   //     );
    //   //   }
    //   // });
    // });
  }

  componentDidUpdate(prevProps) {
    // Check if stockPriceData props has changed
    // console.log("prevProps.stockPriceData: ", prevProps.stockPriceData);
    // console.log("this.props.stockPriceData: ", this.props.stockPriceData);
    if (prevProps.stockPriceData !== this.props.stockPriceData) {
      // Update stockPriceData in state
      console.log("Did update, updated stockVolume");
      try {
        this.updateStockVolumeData();
      } catch (error) {
        console.log(error);
      }
    }
    // if (prevProps.newsAnalysisData !== this.props.newsAnalysisData) {
    //   // Update stockPriceData in state
    //   console.log("Did update, updated newsAnlysis");
    //   this.updateNewsAnalysisData();
    // }
  }

  updateStockVolumeData() {
    const { stockPriceData } = this.props;

    if (this.props.stockPriceData.data.length > 0) {
      // console.log(this.props.stockPriceData.data[0].stock_close_price.data);

      // Update stockPriceData in series state
      this.setState((prevState) => ({
        // series: [
        //   {
        //     ...prevState.series[0],
        //     data: stockPriceData.data[0].stock_close_price.data,
        //   },
        // ],
        seriesVolume: [
          {
            ...prevState.seriesVolume[0],
            data: stockPriceData.data[0].stock_volume.data,
          },
        ],
      }));
      // this.updateData(
      //   "all",
      //   // this.state.optionsPrice.chart.id,
      //   this.state.optionsVolume.chart.id
      // );
    } else {
      console.log("no stock volume data");
    }
  }
}

export default StockVolume;
