export const EQUITY_CURVE_SUCCESS_ACTION =
  "[equitycurve action] confirmed equitycurve";
export const EQUITY_CURVE_FAILED_ACTION =
  "[equitycurve action] failed equitycurve";

const baseURL =
  "https://5xb3pvggaf.execute-api.ap-southeast-1.amazonaws.com/dev";

export function getEquityCurveAction(test_id) {
  return async (dispatch) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          test_id: test_id,
        }),
      };

      //   const res = await getHighestReturn();
      const res = await fetch(`${baseURL}/equityCurve`, requestOptions);
      const result = await res.json();
      dispatch(equityCurveSuccessAction(result));
    } catch (error) {
      dispatch(equityCurveFailAction(error));
    }
  };
}

export function equityCurveSuccessAction(payload) {
  return {
    type: EQUITY_CURVE_SUCCESS_ACTION,
    payload,
  };
}

export function equityCurveFailAction(message) {
  return {
    type: EQUITY_CURVE_FAILED_ACTION,
    payload: message,
  };
}
