import React from "react";
import ReactApexChart from "react-apexcharts";

class ReturnComparisonBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          name: "1st BackTest",
          data: [500, 230, 600, 360, 700, 890, 750, 420, 600, 300, 420, 220],
        },
        {
          name: "2nd BackTest",
          data: [
            250,
            380,
            -200,
            -300,
            200,
            520,
            "NA",
            "NA",
            "NA",
            "NA",
            "NA",
            "NA",
          ],
        },
      ],
      options: {
        chart: {
          height: 200,
          type: "bar",
          // group: "social",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        // stroke: {
        //   width: [2, 2],
        //   // colors: ["#EB8153", "#2BC155"],
        //   curve: "smooth",
        //   // lineCap: "square",
        // },
        legend: {
          // tooltipHoverFormatter: function (val, opts) {
          //   return (
          //     val +
          //     " - " +
          //     opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
          //     ""
          //   );
          // },
          position: "top",
          markers: {
            // fillColors: ["#EB8153", "#2BC155"],
            width: 19,
            height: 19,
            strokeWidth: 0,
            radius: 19,
          },
        },
        tooltip: {
          x: {
            formatter: function (val, opts) {
              return val + " month return";
            },
          },
          enabled: true,
          // shared: true,
          followCursor: true,
        },
        title: {
          text: "Return Comparison",
          align: "left",
        },
        subtitle: {
          text: "USD",
          align: "left",
        },
        markers: {
          size: 0,
          border: 0,
          // colors: ["#EB8153", "#2BC155"],
          hover: {
            size: 6,
          },
        },
        xaxis: {
          categories: [
            "1st",
            "2nd",
            "3rd",
            "4th",
            "5th",
            "6th",
            "7th",
            "8th",
            "9th",
            "10th",
            "11st",
            "12nd",
          ],
          title: {
            text: "Month",
            position: "bottom",
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: "#3e4954",
              // fontSize: "14px",
              // fontFamily: "Poppins",
              // fontWeight: 100,
            },
          },
          title: {
            text: "Return",
          },
        },
        // fill: {
        //   // colors: ["#EB8153", "#2BC155"],
        //   type: "solid",
        //   opacity: 0.07,
        // },
        grid: {
          borderColor: "#f1f1f1",
        },
      },
    };
  }

  render() {
    return (
      <div id="chart" className="line-chart-style bar-chart apex-chart">
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height={350}
        />
      </div>
    );
  }
}

export default ReturnComparisonBar;
