import React, {
  useMemo,
  useReducer,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useTable, useSortBy } from "react-table";
import { Dropdown } from "react-bootstrap";
// import PageTitle from "../../../layouts/PageTitle";
import MOCK_DATA from "./MOCK_DATA_3.json";
import { COLUMNS } from "./Columns";
import { MenuList } from "./Menu";
import { Link } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import { ProgressBar } from "react-bootstrap";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import StrategyOverview from "../../../pages/StretegyOverview";
import TransactionTab from "../../../pages/TransactionTab";
import { FirstBackTestTransactionTable } from "../FilteringTable/FirstBackTestTransactionTable";
import { SecondBackTestTransactionTable } from "../FilteringTable/SecondBackTestTransactionTable";
import { ForwardTestTransactionTable } from "../FilteringTable/ForwardTestTransactionTable";
import NewsTimeline from "../../../pages/NewsTimeline";
import EquityCurve from "./EquityCurve";
// import TestGraph from "./TestGraph";
import { useDispatch, useSelector } from "react-redux";
import { getStockPriceAction } from "../../../../../src/store/actions/StockPriceAction";
import {
  getStrategyTestAction,
  startForwardTestAction,
  stopForwardTestAction,
  removeTestAction,
  getForwardTestResultAction,
  getForwardTestProgressAction,
  findBestParametersAction,
  findBestParametersProgressAction,
} from "../../../../../src/store/actions/StrategyTestAction";
import { getTransactionsAction } from "../../../../store/actions/TransactionsAction";
import { getNewsAnalysisAction } from "../../../../../src/store/actions/NewsAnalysisAction";
import { getEquityCurveAction } from "../../../../../src/store/actions/EquityCurveAction";
import StockVolume from "./StockVolume";
import Loading from "../../../pages/Loading";
//import './table.css';

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  activeRow: "0",
  activeSubmenu: "",
};

const CryptoStatistics = loadable(() =>
  pMinDelay(import("./CryptoStatistics"), 1000)
);
const CryptoStatisticsDark = loadable(() =>
  pMinDelay(import("./CryptoStatisticsDark"), 1000)
);

const StockPrice = loadable(() => pMinDelay(import("./StockPrice"), 1000));

// const EquityCurve = loadable(() => pMinDelay(import("./EquityCurve"), 1000));

// const TransactionFilteringTable = loadable(() =>
//   pMinDelay(import("../FilteringTable/TransactionFilteringTable"), 1000)
// );

export const StrategyTestTable = ({
  strategy,
  setEditStrategyModal,
  setAddStrategyModal,
  setInfoModal,
  setQRModal,
  setQRCodeLink,
  user,
  setEditTestId,
  loadTop,
  setLoadTop,
  loading,
}) => {
  const dispatch = useDispatch();
  const [selectedTestId, setSelectedTestId] = useState("");
  // const [user, setUser] = useState("test_user");
  const [testStrategyName, setTestStrategyName] = useState(strategy);
  const [firstTimeLoad, setFirstTimeLoad] = useState(true);
  const [ftProgress, setFTProgress] = useState(0);
  const [btProgress, setBTProgress] = useState(0);
  const [ftStatus, setFTStatus] = useState("");
  const [btStatus, setBTStatus] = useState("");
  const [ftProgressRow, setFTProgressRow] = useState();
  const [btProgressRow, setBTProgressRow] = useState();
  const stockPriceData = useSelector((state) => state.stockPriceData);
  const strategyTestData = useSelector((state) => state.strategyTestData);
  const newsAnalysisData = useSelector((state) => state.newsAnalysisData);
  const equityCurveData = useSelector((state) => state.equityCurveData);
  const transactionsData = useSelector((state) => state.transactionsData);

  // const stockPriceDataTemp = { ...stockPriceData };
  // console.log(stockPriceDataTemp);

  useEffect(() => {
    setTestStrategyName(strategy);
  }, [strategy]);

  function getStockPrice(testId) {
    dispatch(getStockPriceAction(testId));
  }
  function getStrategyTest(user, testStrategyName) {
    dispatch(getStrategyTestAction(user, testStrategyName));
  }
  function getNewsAnalysis(
    test_id,
    limit,
    start_data,
    end_date,
    symbol,
    impact_above,
    impact_below
  ) {
    dispatch(
      getNewsAnalysisAction(
        test_id,
        limit,
        start_data,
        end_date,
        symbol,
        impact_above,
        impact_below
      )
    );
  }
  function getEquityCurve(testId) {
    dispatch(getEquityCurveAction(testId));
  }

  function getTransactionsData(testId) {
    try {
      dispatch(getTransactionsAction(testId));
    } catch (error) {
      console.log("Error occurred while starting back test:", error);
    }
  }

  const startForwardTest = async (row) => {
    try {
      const testId = strategyTestData.data[row].test_id;
      await dispatch(startForwardTestAction(testId));
    } catch (error) {
      console.log(
        "Error occurred while starting forward strategy test:",
        error
      );
    }
  };
  const stopForwardTest = async (row) => {
    try {
      const testId = strategyTestData.data[row].test_id;
      await dispatch(stopForwardTestAction(testId));
    } catch (error) {
      console.log(
        "Error occurred while stopping forward strategy test:",
        error
      );
    }
  };
  const getTelegramQRCode = async (row) => {
    try {
      const tg_invite_link = strategyTestData.data[row].tg_invite_link;
      console.log(tg_invite_link);
      setQRCodeLink(tg_invite_link);
      setQRModal(true);
    } catch (error) {
      console.log("Error occurred while getting Telegram Link:", error);
    }
  };
  const getForwardTestResult = async (row) => {
    try {
      setFTProgressRow(row);
      const testId = strategyTestData.data[row].test_id;
      // console.log(testId);
      const result = await dispatch(getForwardTestResultAction(testId));
      console.log(result);

      if (result.success) {
        const intervalId = setInterval(async () => {
          if (ftProgress < 100 || ftStatus !== "calculated") {
            try {
              const result = await dispatch(
                getForwardTestProgressAction(testId)
              );
              console.log(result);
              if (result.status === "calculating") {
                const currentPercentage = result.percentage;
                const currentStatus = result.status;
                setFTStatus(currentStatus);
                setFTProgress(currentPercentage);
              } else {
                clearInterval(intervalId);
                setFTStatus("");
                setFTProgress(0);
              }
            } catch (error) {
              console.log(
                "Error occurred while getting forward test progress:",
                error
              );
              clearInterval(intervalId);
              setFTStatus("");
              setFTProgress(0);
            }
          } else {
            clearInterval(intervalId);
            setFTStatus("");
            setFTProgress(0);
          }
        }, 4000); // Adjust the interval duration as needed
      }
    } catch (error) {
      console.log("Error occurred while get forward test result:", error);
    }
  };
  const findBestParameters = async (row) => {
    try {
      setBTProgressRow(row);
      const testId = strategyTestData.data[row].test_id;
      // console.log(testId);
      const result = await dispatch(findBestParametersAction(testId));
      console.log(result);

      if (result.success) {
        const intervalId = setInterval(async () => {
          if (ftProgress < 100 || btStatus !== "calculated") {
            try {
              const result = await dispatch(
                findBestParametersProgressAction(testId)
              );
              if (result.status === "calculating") {
                const currentPercentage = result.percentage;
                const currentStatus = result.status;
                setBTStatus(currentStatus);
                setBTProgress(currentPercentage);
              } else {
                clearInterval(intervalId);
                setBTStatus("");
                setBTProgress(0);
              }
            } catch (error) {
              console.log(
                "Error occurred while getting best parameters progress:",
                error
              );
              clearInterval(intervalId);
              setBTStatus("");
              setBTProgress(0);
            }
          } else {
            clearInterval(intervalId);
            setBTStatus("");
            setBTProgress(0);
          }
        }, 4000); // Adjust the interval duration as needed
      }
    } catch (error) {
      console.log("Error occurred while get best parameters result:", error);
    }
  };
  function removeTest(row) {
    // handleMenuActive(row);
    try {
      // setFirstTimeLoad(true);
      const testId = strategyTestData.data[row].test_id;
      dispatch(removeTestAction(testId));
      setState({ activeRow: "" });
      // const itemIndex = strategyTestData.data.findIndex((test) => test.test_id === selectedTestId);
      // const order = itemIndex + 1;

      // if (state.activeRow === row) {
      //   setState({ activeRow: "" });
      // } else
      // if (state.activeRow > row) {
      //   const newActiveRow = state.activeRow - 1;
      //   // setState({ activeRow: newActiveRow.toString() });
      //   handleMenuActive(newActiveRow.toString());
      // }
    } catch (error) {
      console.log("Error occurred while remove test:", error);
    }
  }

  useEffect(() => {
    try {
      const fetchData = async () => {
        await getStrategyTest(user, testStrategyName);
      };
      fetchData();
    } catch (error) {
      // Handle the error here
      console.log("Error occurred while getting strategy test:", error);
    }
  }, [user, testStrategyName]);

  useEffect(() => {
    if (
      strategyTestData &&
      strategyTestData.data &&
      strategyTestData.data.length > 0
    ) {
      // if (firstTimeLoad || loadTop) {
      if (firstTimeLoad) {
        const firstTestData = strategyTestData.data[0];
        setSelectedTestId(firstTestData.test_id);
        // setEditTestId(firstTestData.test_id);
        setFirstTimeLoad(false);
        // if (loadTop) {
        //   setState({ activeRow: "0" });
        //   setLoadTop(false);
        // }
      }
      if (loadTop) {
        setState({ activeRow: "" });
        setLoadTop(false);
      }
      console.log(strategyTestData.data);
    }
  }, [strategyTestData]);

  useEffect(() => {
    if (selectedTestId !== "") {
      try {
        // console.log("GET EQUITY CURVE");
        getEquityCurve(selectedTestId);
      } catch (error) {
        // Handle the error here
        console.log("Error occurred while getting stock price:", error);
      }
    }
  }, [selectedTestId, strategyTestData]);

  useEffect(() => {
    if (selectedTestId !== "") {
      try {
        getStockPrice(selectedTestId);
      } catch (error) {
        // Handle the error here
        console.log("Error occurred while getting stock price:", error);
      }
    }
  }, [selectedTestId, strategyTestData]);

  useEffect(() => {
    if (selectedTestId !== "") {
      try {
        const item = strategyTestData.data.find(
          (test) => test.test_id === selectedTestId
        );
        // console.log(item);
        getNewsAnalysis(
          selectedTestId,
          100,
          item.bt_start_date,
          item.bt_2nd_end_date,
          item.ft_symbol,
          82,
          -82
        );
      } catch (error) {
        // Handle the error here
        console.log("Error occurred while getting news analysis:", error);
      }
    }
  }, [selectedTestId, strategyTestData]);

  useEffect(() => {
    if (selectedTestId !== "") {
      try {
        console.log("Get Transactions");
        getTransactionsData(selectedTestId);
      } catch (error) {
        // Handle the error here
        console.log("Error occurred while getting Back Test Data:", error);
      }
    }
  }, [selectedTestId, strategyTestData]);

  useEffect(() => {
    // console.log(equityCurveData.data);
    if (
      equityCurveData &&
      equityCurveData.data &&
      // equityCurveData.data.length > 0
      equityCurveData.data.equity_per_day
    ) {
      console.log("Equity Curve Data: ");
      console.log(
        equityCurveData.data.equity_per_day["bt_overall_equity_per_day"].data
      );
      // const data = changeDateTimeOfEquityCurve(equityCurveData);
      // console.log(data);
    }
  }, [equityCurveData]);

  useEffect(() => {
    // console.log(stockPriceData);
    if (
      stockPriceData &&
      stockPriceData.data &&
      stockPriceData.data.length > 0
    ) {
      console.log("Stock Price Data: ");
      console.log(stockPriceData.data[0].stock_close_price.data);
      // const data = changeDateTimeOfStockPrice(stockPriceData);
      // console.log(data);
    }
  }, [stockPriceData]);

  useEffect(() => {
    // console.log(newsAnalysisData);
    if (
      newsAnalysisData &&
      newsAnalysisData.data &&
      newsAnalysisData.data.length > 0
    ) {
      console.log("News Analysis Data: ");
      console.log(newsAnalysisData.data);
    }
  }, [newsAnalysisData]);

  useEffect(() => {
    // console.log(backTestData);
    if (
      transactionsData &&
      transactionsData.data
      // transactionsData.data.length > 0
    ) {
      console.log("Transactions Data: ");
      console.log(transactionsData.data);
    }
  }, [transactionsData]);

  // function changeDateTimeOfStockPrice(stockPriceData) {
  //   let data = stockPriceData.data[0].stock_close_price.data;

  //   data.forEach((obj) => {
  //     obj.x = new Date(obj.x).getTime();
  //   });

  //   return data;
  // }

  const columns = useMemo(() => COLUMNS, []);
  // const data = useMemo(() => strategyTestData.data, [strategyTestData]);
  // const data = useMemo(() => MOCK_DATA, []);
  let data = [];

  if (strategyTestData.data) {
    data = strategyTestData.data;
  }
  // console.log(data);
  const tableInstance = useTable({ columns, data }, useSortBy);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = tableInstance;
  const [state, setState] = useReducer(reducer, initialState);
  const handleMenuActive = (row) => {
    console.log(row);
    setState({ activeRow: row });
    setSelectedTestId(strategyTestData.data[row].test_id);
    // setEditTestId(strategyTestData.data[row].test_id);

    if (state.activeRow === row) {
      setState({ activeRow: "" });
    }
  };
  // const handleSubmenuActive = (row) => {
  //   setState({ activeSubmenu: row });
  //   if (state.activeSubmenu === row) {
  //     setState({ activeSubmenu: "" });
  //   }
  // };
  const [activeTransactions, setActiveTransactions] = useState("FirstBackTest");
  const handleTransactionTabClick = (transactionType) => {
    setActiveTransactions(transactionType);
  };

  const [newsIndex, setNewsIndex] = useState();
  const [newsHighlighted, setNewsHighlighted] = useState(false);
  const selectNews = (index, state) => {
    // setNewsIndex(index);
    setNewsIndex(index);
    // console.log("setIndex: " + index);
    setNewsHighlighted(state);
    // console.log("setHighlighted: " + newsHighlighted);
  };

  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  const moreIcon = `
    <svg
    xmlns="http://www.w3.org/2000/svg"
    //    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="18px"
    height="18px"
    viewBox="0 0 24 24"
    version="1.1"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <rect
          x="0"
          y="0"
          width="24"
          height="24"
        ></rect>
        <circle
          fill="#000000"
          cx="5"
          cy="12"
          r="2"
        ></circle>
        <circle
          fill="#000000"
          cx="12"
          cy="12"
          r="2"
        ></circle>
        <circle
          fill="#000000"
          cx="19"
          cy="12"
          r="2"
        ></circle>
      </g>
    </svg>
  `;

  const filteredRows = rows.filter(
    (row) =>
      row.original.test_strategy_name &&
      strategy &&
      row.original.test_strategy_name
        .toLowerCase()
        .includes(strategy.toLowerCase())
  );

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Strategy Tests</h4>
          <Link
            to={"#"}
            className="btn btn-secondary mb-2"
            onClick={() => setAddStrategyModal(true)}
          >
            + Add Strategy Test
          </Link>
        </div>
        <div className="table-area card-body">
          <div className="table-responsive">
            <div className="outer-table-container">
              <table {...getTableProps()} className="table outer-table">
                <colgroup>
                  {window.innerWidth > 768 ? (
                    <>
                      <col className="col-2" />
                      <col className="col-4" />
                      <col className="col-4 hide-on-mobile" />
                      <col className="col-4" />
                      <col className="col-4" />
                      <col className="col-3" />
                      <col className="col-3" />
                    </>
                  ) : (
                    <>
                      <col className="col-4" />
                      <col className="col-8" />
                      <col className="col-6 hide-on-mobile" />
                      <col className="col-7" />
                      <col className="col-8" />
                      <col className="col-4" />
                    </>
                  )}
                </colgroup>
                <thead>
                  {headerGroups.map((headerGroup, index) => (
                    <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                      <th></th>
                      {headerGroup.headers.map(
                        (column, columnIndex) =>
                          // (columnIndex !== 1 &&
                          // columnIndex !== 2 &&
                          // columnIndex !== 3) ||
                          (columnIndex !== 1 || window.innerWidth > 768) && (
                            <th
                              key={column.id}
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                              className={`text-center ${
                                window.innerWidth <= 768 ? "small-header" : ""
                              }`}
                            >
                              {column.render("Header")}
                              <span className="ms-1">
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <i className="fa fa-arrow-down" />
                                  ) : (
                                    <i className="fa fa-arrow-up" />
                                  )
                                ) : (
                                  ""
                                )}
                              </span>
                            </th>
                          )
                      )}
                      <th></th>
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  <tr>
                    <td colspan="8">{loading && <Loading />}</td>
                  </tr>
                  {/* {rows.filter((row) => console.log(row.original))} */}
                  {filteredRows.length === 0 ? (
                    <tr className="">
                      <td colspan="8">
                        <div
                          className="display-flex justify-content-center align-items-center mt-4 empty"
                          onClick={() => setAddStrategyModal(true)}
                        >
                          <i className="fa fa-plus text-primary me-2" />
                          <h4 className="mb-0">Add Strategy Test</h4>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    filteredRows.map((row) => {
                      prepareRow(row);
                      return (
                        <>
                          <tr
                            {...row.getRowProps()}
                            className="tr-cell-row text-center mobile-summary-row"
                          >
                            <td>
                              <div className="table-row">
                                <Link
                                  to={"#"}
                                  className={` ${
                                    state.activeRow === row.id
                                      ? "mm-active btn btn-primary angle"
                                      : "btn btn-primary angle"
                                  }`}
                                  key={row.id}
                                  onClick={() => {
                                    handleMenuActive(row.id);
                                  }}
                                >
                                  <div className="content">{">"}</div>
                                </Link>
                              </div>
                            </td>
                            {row.cells.map((cell, cellIndex) => {
                              return (
                                // (cellIndex !== 1 &&
                                // cellIndex !== 2 &&
                                // cellIndex !== 3) ||
                                (cellIndex !== 1 ||
                                  window.innerWidth > 768) && (
                                  <td {...cell.getCellProps()} className="">
                                    <div>
                                      {/* {console.log(cell)} */}
                                      {cell.column["Header"] == "Status" ? (
                                        // <Badge variant="success light">
                                        cell.value == "Running" ? (
                                          <span class="badge bg-success">
                                            {cell.value}
                                          </span>
                                        ) : (
                                          <span class="badge bg-dark">
                                            {/* {cell.value} */}
                                            Idle
                                          </span>
                                        )
                                      ) : cell.column["Header"] ===
                                        "Forward Test ROI" ? (
                                        row.id === ftProgressRow &&
                                        ftStatus === "calculating" ? (
                                          <div>
                                            <div className="mb-2">
                                              Calculating
                                            </div>
                                            <ProgressBar
                                              now={
                                                row.id === ftProgressRow
                                                  ? ftProgress
                                                  : 0
                                              }
                                              variant="info"
                                              striped
                                            />
                                          </div>
                                        ) : (
                                          <span>{cell.value}</span>
                                        )
                                      ) : cell.column["Header"] ===
                                        "Back Test ROI" ? (
                                        row.id === btProgressRow &&
                                        btStatus === "calculating" ? (
                                          <div>
                                            <div className="mb-2">
                                              Calculating
                                            </div>
                                            <ProgressBar
                                              now={
                                                row.id === btProgressRow
                                                  ? btProgress
                                                  : 0
                                              }
                                              variant="info"
                                              striped
                                            />
                                          </div>
                                        ) : (
                                          <span>{cell.value}</span>
                                        )
                                      ) : (
                                        cell.render("Cell")
                                      )}
                                    </div>
                                  </td>
                                )
                              );
                            })}
                            <td>
                              <Dropdown className="dropdown ms-auto">
                                <Dropdown.Toggle
                                  variant="primary"
                                  className="btn btn-primary light sharp i-false smaller-screen"
                                  data-toggle="dropdown"
                                  aria-expanded="true"
                                  dangerouslySetInnerHTML={{ __html: moreIcon }}
                                ></Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                                  <Dropdown.Item
                                    className="dropdown-item"
                                    onClick={() => {
                                      if (row.original.state === "Created") {
                                        setEditStrategyModal(true);
                                        setEditTestId(row.original.test_id);
                                      } else {
                                        setInfoModal(true);
                                      }
                                    }}
                                  >
                                    <i className="fa fa-pen-to-square text-primary me-2" />
                                    Edit Back Test
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="dropdown-item"
                                    onClick={() => {
                                      if (row.original.state === "Created") {
                                        findBestParameters(row.id);
                                      } else {
                                        setInfoModal(true);
                                      }
                                    }}
                                  >
                                    <i className="fa fa-microchip text-primary me-2" />
                                    Optimize Parameters
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="dropdown-item"
                                    onClick={() => startForwardTest(row.id)}
                                  >
                                    <i className="fa fa-circle-play text-primary me-2" />
                                    Start Forward Test
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="dropdown-item"
                                    onClick={() => stopForwardTest(row.id)}
                                  >
                                    <i className="fa fa-hand text-primary me-2" />
                                    Pause Forward Test
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="dropdown-item"
                                    onClick={() => getForwardTestResult(row.id)}
                                  >
                                    <i className="fa fa-chart-line text-primary me-2" />
                                    Get Current Forward Test Result
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="dropdown-item"
                                    onClick={() => getTelegramQRCode(row.id)}
                                  >
                                    <i className="fa-brands fa-telegram text-primary me-2" />
                                    Telegram Notifications
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    className="dropdown-item"
                                    onClick={() => removeTest(row.id)}
                                  >
                                    <i className="fa fa-trash-can text-primary me-2" />
                                    Delete Test
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                          <Collapse
                            in={state.activeRow === row.id ? true : false}
                          >
                            <tr className="tr-content-row">
                              <td colspan="8">
                                <div className="mm-show">
                                  <div>
                                    <StrategyOverview
                                      equityCurveData={equityCurveData}
                                      overviewData={row.original}
                                    />
                                  </div>
                                  <div className="card">
                                    <div className="card-body pb-0">
                                      {/* {console.log(row.original)} */}
                                      <StockPrice
                                        testId={row.original.test_id}
                                        setNewsIndex={selectNews}
                                        stockPriceData={stockPriceData}
                                        newsAnalysisData={newsAnalysisData}
                                        transactionsData={transactionsData}
                                      />
                                      <StockVolume
                                        testId={row.original.test_id}
                                        // setNewsIndex={selectNews}
                                        stockPriceData={stockPriceData}
                                      />
                                      <NewsTimeline
                                        newsAnalysisData={newsAnalysisData}
                                        testId={row.original.test_id}
                                        newsHighlighted={newsHighlighted}
                                        newsIndex={newsIndex}
                                      />
                                    </div>
                                  </div>
                                  <div className="card">
                                    <div className="card-header">
                                      <h4 className="card-title">
                                        Transactions
                                      </h4>
                                    </div>
                                    {activeTransactions === "FirstBackTest" && (
                                      <FirstBackTestTransactionTable
                                        handleTransactionChange={
                                          handleTransactionTabClick
                                        }
                                        backTestData={
                                          // row.original.resultOfBT
                                          strategyTestData.data[row.id]
                                            .resultOfBT
                                            ? [
                                                strategyTestData.data[row.id]
                                                  .resultOfBT,
                                              ]
                                            : transactionsData
                                            ? transactionsData
                                            : []
                                        }
                                      />
                                    )}
                                    {activeTransactions ===
                                      "SecondBackTest" && (
                                      <SecondBackTestTransactionTable
                                        handleTransactionChange={
                                          handleTransactionTabClick
                                        }
                                        backTestData={
                                          transactionsData
                                          // strategyTestData.data[row.id]
                                          //   .resultOfBT
                                          //   ? [
                                          //       strategyTestData.data[row.id]
                                          //         .resultOfBT,
                                          //     ]
                                          //   : transactionsData
                                          //   ? transactionsData
                                          //   : []
                                        }
                                      />
                                    )}
                                    {activeTransactions === "ForwardTest" && (
                                      <ForwardTestTransactionTable
                                        handleTransactionChange={
                                          handleTransactionTabClick
                                        }
                                        transactionsData={transactionsData}
                                      />
                                    )}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </Collapse>
                        </>
                      );
                    })
                  )}
                </tbody>
                {/* <tfoot>
                  {footerGroups.map((footerGroup) => (
                    <tr {...footerGroup.getFooterGroupProps()}>
                      <td>test</td>
                      {footerGroup.headers.map((column) => (
                        <td {...column.getFooterProps()}>
                          {column.render("Footer")}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tfoot> */}
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default StrategyTestTable;
