import {
  GET_STRATEGY_TEST_SUCCESS_ACTION,
  GET_STRATEGY_TEST_FAILED_ACTION,
  CREATE_STRATEGY_TEST_SUCCESS_ACTION,
  CREATE_STRATEGY_TEST_FAILED_ACTION,
  EDIT_STRATEGY_BACK_TEST_SUCCESS_ACTION,
  EDIT_STRATEGY_BACK_TEST_FAILED_ACTION,
  START_STRATEGY_BACK_TEST_SUCCESS_ACTION,
  START_STRATEGY_BACK_TEST_FAILED_ACTION,
  START_STRATEGY_FORWARD_TEST_SUCCESS_ACTION,
  START_STRATEGY_FORWARD_TEST_FAILED_ACTION,
  STOP_STRATEGY_FORWARD_TEST_SUCCESS_ACTION,
  STOP_STRATEGY_FORWARD_TEST_FAILED_ACTION,
  REMOVE_STRATEGY_TEST_SUCCESS_ACTION,
  REMOVE_STRATEGY_TEST_FAILED_ACTION,
  GET_FORWARD_TEST_SUCCESS_ACTION,
  GET_FORWARD_TEST_FAILED_ACTION,
  GET_FORWARD_TEST_PROGRESS_SUCCESS_ACTION,
  GET_FORWARD_TEST_PROGRESS_FAILED_ACTION,
  OPTIMIZE_PARAMETERS_SUCCESS_ACTION,
  OPTIMIZE_PARAMETERS_FAILED_ACTION,
  OPTIMIZE_PARAMETERS_PROGRESS_SUCCESS_ACTION,
  OPTIMIZE_PARAMETERS_PROGRESS_FAILED_ACTION,
} from "../actions/StrategyTestAction";

const initialState = {
  data: [],
  errorMessage: "",
  successMessage: "",
  showLoading: false,
};

// export function StrategyTestReducer(state = initialState, action) {
//   if (action.type === GET_STRATEGY_TEST_SUCCESS_ACTION) {
//     return {
//       ...state,
//       data: action.payload,
//       errorMessage: "",
//       successMessage: "api call successful",
//       showLoading: false,
//     };
//   }
//   if (action.type === GET_STRATEGY_TEST_FAILED_ACTION) {
//     return {
//       ...state,
//       data: action.payload,
//       errorMessage: "Get Strategy Test api call failed",
//       successMessage: "",
//       showLoading: false,
//     };
//   }

//   return state;
// }

export function StrategyTestReducer(state = initialState, action) {
  if (action.type === CREATE_STRATEGY_TEST_SUCCESS_ACTION) {
    const data = [...state.data];
    data.unshift({
      ...action.payload.resultWithBT,
      resultOfBT: action.payload.resultOfBT,
    });
    // data.unshift(action.payload.resultWithBT);

    return {
      ...state,
      data,
      errorMessage: "",
      successMessage: "Create test api call successful",
      showLoading: false,
    };
  }
  if (action.type === CREATE_STRATEGY_TEST_FAILED_ACTION) {
    // const data = [...state.data];
    // data.push(action.payload);

    return {
      ...state,
      // data,
      errorMessage: "Create test api api call failed",
      successMessage: "",
      showLoading: false,
    };
  }

  if (action.type === GET_STRATEGY_TEST_SUCCESS_ACTION) {
    return {
      ...state,
      data: action.payload,
      errorMessage: "",
      successMessage: "Get Strategy Test api call successful",
      showLoading: false,
    };
  }
  if (action.type === GET_STRATEGY_TEST_FAILED_ACTION) {
    return {
      ...state,
      // data: action.payload,
      errorMessage: "Get Strategy Test api call failed",
      successMessage: "",
      showLoading: false,
    };
  }
  if (action.type === START_STRATEGY_BACK_TEST_SUCCESS_ACTION) {
    return {
      ...state,
      data: action.payload,
      errorMessage: "",
      successMessage: "Start Back Test api call successful",
      showLoading: false,
    };
  }
  if (action.type === START_STRATEGY_BACK_TEST_FAILED_ACTION) {
    return {
      ...state,
      // data: action.payload,
      errorMessage: "Start Back Test api call failed",
      successMessage: "",
      showLoading: false,
    };
  }
  if (action.type === START_STRATEGY_FORWARD_TEST_SUCCESS_ACTION) {
    const testIndex = state.data.findIndex(
      (test) => test.test_id === action.payload.test_id
    );
    const data = [...state.data];
    // Update the specific item in the data array
    // data[testIndex] = { ...data[testIndex], status: "Running" };

    // data[testIndex] = { ...action.payload.result, status: "Running" };
    data[testIndex] = [
      ...action.payload.result,
      ...action.payload.tg_invite_link,
    ];

    return {
      ...state,
      data,
      errorMessage: "",
      successMessage: "Start Strategy Forward Test api call successful",
      showLoading: false,
    };
  }
  if (action.type === EDIT_STRATEGY_BACK_TEST_SUCCESS_ACTION) {
    const testIndex = state.data.findIndex(
      (test) => test.test_id === action.payload.test_id
    );
    const data = [...state.data];
    // Update the specific item in the data array
    data[testIndex] = action.payload;

    return {
      ...state,
      data,
      errorMessage: "",
      successMessage: "Edit Strategy Back Test api call successful",
      showLoading: false,
    };
  }
  if (action.type === STOP_STRATEGY_FORWARD_TEST_SUCCESS_ACTION) {
    const testIndex = state.data.findIndex(
      (test) => test.test_id === action.payload.test_id
    );
    const data = [...state.data];
    // data[testIndex] = { ...data[testIndex], status: "Stopped" };
    data[testIndex].state = "Stopped";
    console.log(data[testIndex]);

    return {
      ...state,
      data,
      errorMessage: "",
      successMessage: "Stop Strategy Forward Test api call successful",
      showLoading: false,
    };
  }
  if (action.type === OPTIMIZE_PARAMETERS_PROGRESS_SUCCESS_ACTION) {
    const testIndex = state.data.findIndex(
      (test) => test.test_id === action.payload.test_id
    );
    const data = [...state.data];
    data[testIndex] = action.payload.result;

    return {
      ...state,
      data,
      errorMessage: "",
      successMessage: "Optimize Parameters api call successful",
      showLoading: false,
    };
  }
  if (action.type === GET_FORWARD_TEST_PROGRESS_SUCCESS_ACTION) {
    const testIndex = state.data.findIndex(
      (test) => test.test_id === action.payload.test_id
    );
    const data = [...state.data];
    data[testIndex] = action.payload.result;
    // if (action.payload.status === "calculating") {
    //   data[testIndex] = {
    //     ...data[testIndex],
    //     status: "calculating",
    //     percentage: action.payload.percentage,
    //   };
    // } else if (action.payload.status === "calculated") {
    //   data[testIndex] = {
    //     ...data[testIndex],
    //     status: "calculated",
    //     percentage: action.payload.percentage,
    //   };
    // }

    return {
      ...state,
      data,
      errorMessage: "",
      successMessage: "Get Forward Test result api call successful",
      showLoading: false,
    };
  }
  if (action.type === REMOVE_STRATEGY_TEST_SUCCESS_ACTION) {
    const data = [...state.data];
    const testIndex = data.findIndex(
      (test) => test.test_id === action.payload.test_id
    );

    data.splice(testIndex, 1);

    return {
      ...state,
      data,
      errorMessage: "",
      successMessage: "Remove Test api call successful",
      showLoading: false,
    };
  }

  // if (action.type === CONFIRMED_EDIT_POST_ACTION) {
  //   const posts = [...state.posts];
  //   const postIndex = posts.findIndex((post) => post.id === action.payload.id);

  //   posts[postIndex] = action.payload;
  //   return {
  //     ...state,
  //     posts,
  //   };
  // }

  // if (action.type === CREATE_POST_ACTION) {
  //   const post = {
  //     id: Math.random(),
  //     title: "Post Title 2asdasd",
  //     description: "Sample Description 2asdasdas",
  //   };

  //   const posts = [...state.posts];
  //   posts.push(post);
  //   return {
  //     ...state,
  //     posts,
  //   };
  // }

  // if (action.type === CONFIRMED_GET_POSTS) {
  //   return {
  //     ...state,
  //     posts: action.payload,
  //   };
  // }
  return state;
}

export default StrategyTestReducer;
