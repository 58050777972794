import {
  STOCK_PRICE_SUCCESS_ACTION,
  STOCK_PRICE_FAILED_ACTION,
} from "../actions/StockPriceAction";

const initialState = {
  data: [],
  errorMessage: "",
  successMessage: "",
  showLoading: false,
};

export function StockPriceReducer(state = initialState, action) {
  if (action.type === STOCK_PRICE_SUCCESS_ACTION) {
    return {
      ...state,
      data: action.payload,
      errorMessage: "",
      successMessage: "api call successful",
      showLoading: false,
    };
  }
  if (action.type === STOCK_PRICE_FAILED_ACTION) {
    return {
      ...state,
      data: action.payload,
      errorMessage: "Get stock price api call failed",
      successMessage: "",
      showLoading: false,
    };
  }

  return state;
}

export default StockPriceReducer;
