import { Nav, Tab } from "react-bootstrap";

function TransactionTab({ handleTransactionTypeClick }) {
  const tabData = [
    {
      name: "1st Back Test",
      icon: "chevron-circle-left",
      transactionType: "FirstBackTest",
      content:
        "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove.",
    },
    {
      name: "2nd Back Test",
      icon: "chevron-circle-left",
      transactionType: "SecondBackTest",
      content:
        "Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor.      ",
    },
    {
      name: "Forward Test",
      icon: "chevron-circle-right",
      transactionType: "ForwardTest",
      content:
        "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove.",
    },
  ];
  return (
    <>
      <div className="card-action coin-tabs my-3 mx-3">
        <div className="custom-tab-1">
          <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
            <Nav as="ul" className="nav-tabs">
              {tabData.map((data, i) => (
                <Nav.Item as="li" key={i}>
                  <Nav.Link
                    eventKey={data.name.toLowerCase()}
                    onClick={() =>
                      handleTransactionTypeClick(data.transactionType)
                    }
                  >
                    <i className={`la la-${data.icon} me-2`} />
                    {data.name}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
            {/* <Tab.Content className="pt-4">
              {tabData.map((data, i) => (
                <Tab.Pane eventKey={data.name.toLowerCase()} key={i}>
                  <h4>This is {data.name} title</h4>
                  <p>{data.content}</p>
                  <p>{data.content}</p>
                </Tab.Pane>
              ))}
            </Tab.Content> */}
          </Tab.Container>
        </div>
      </div>
      {/* <div className="card-action coin-tabs my-3 mx-3">
        <Nav as="ul" className="nav nav-tabs" role="tablist">
          <Nav.Item as="li">
            <Nav.Link
              to={"#"}
              // eventKey="TrendFollowing"
              onClick={() => handleTransactionTypeClick("FirstBackTest")}
              className="text-black"
            >
              1st Back Test
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Nav.Link
              to={"#"}
              // eventKey="Scalping"
              onClick={() => handleTransactionTypeClick("SecondBackTest")}
              className="text-black"
            >
              2nd Back Test
            </Nav.Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Nav.Link
              to={"#"}
              // eventKey="MeanReversion"
              onClick={() => handleTransactionTypeClick("ForwardTest")}
              className="text-black"
            >
              Forward Test
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div> */}
    </>
  );
}

export default TransactionTab;
