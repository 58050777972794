import { format } from "date-fns";
import { ColumnFilter } from "./ColumnFilter";
export const COLUMNSLOWER = [
  {
    Header: "Date",
    Footer: "Date",
    accessor: "date_time",
    Cell: ({ value }) => {
      if (value) {
        return format(new Date(value), "dd/MM/yyyy");
      }
      return "";
    },
    Filter: ColumnFilter,
  },
  {
    Header: "Price",
    Footer: "Price",
    accessor: "price",
    Filter: ColumnFilter,
  },
  {
    Header: "Volume",
    Footer: "Volume",
    accessor: "volume",
    Filter: ColumnFilter,
  },
  {
    Header: "Entry",
    Footer: "Entry",
    accessor: "entry",
    Filter: ColumnFilter,
  },
  {
    Header: "Type",
    Footer: "Type",
    accessor: "trade_type",
    Filter: ColumnFilter,
  },
  {
    Header: "Trend",
    Footer: "Trend",
    accessor: "reason_type",
    Filter: ColumnFilter,
    //disableFilters: true,
  },
  {
    Header: "Reason",
    Footer: "Reason",
    accessor: "reason",
    Filter: ColumnFilter,
  },
];

export const GROUPED_COLUMNS = [
  {
    Header: "Id",
    Footer: "Id",
    accessor: "id",
  },
  {
    Header: "Name",
    Footer: "Name",
    columns: [
      {
        Header: "First Name",
        Footer: "First Name",
        accessor: "first_name",
      },
      {
        Header: "Last Name",
        Footer: "Last Name",
        accessor: "last_name",
      },
    ],
  },
  {
    Header: "Info",
    Footer: "Info",
    columns: [
      {
        Header: "Date of  Birth",
        Footer: "Date of  Birth",
        accessor: "date_of_birth",
      },
      {
        Header: "Country",
        Footer: "Country",
        accessor: "country",
      },
      {
        Header: "Phone",
        Footer: "Phone",
        accessor: "phone",
      },
    ],
  },
];
