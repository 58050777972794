import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import PostsReducer from "./reducers/PostsReducer";
import thunk from "redux-thunk";
import { AuthReducer } from "./reducers/AuthReducer";
import todoReducers from "./reducers/Reducers";
import MarketScanReducer from "./reducers/MarketScanReducer";
import StockPriceReducer from "./reducers/StockPriceReducer";
import StrategyTestReducer from "./reducers/StrategyTestReducer";
import NewsAnalysisReducer from "./reducers/NewsAnalysisReducer";
import EquityCurveReducer from "./reducers/EquityCurveReducer";
import TransactionsReducer from "./reducers/TransactionsReducer";
//import { reducer as reduxFormReducer } from 'redux-form';
const middleware = applyMiddleware(thunk);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const reducers = combineReducers({
  posts: PostsReducer,
  auth: AuthReducer,
  todoReducers,
  marketScanData: MarketScanReducer,
  stockPriceData: StockPriceReducer,
  strategyTestData: StrategyTestReducer,
  newsAnalysisData: NewsAnalysisReducer,
  equityCurveData: EquityCurveReducer,
  transactionsData: TransactionsReducer,
  //form: reduxFormReducer,
});

//const store = createStore(rootReducers);

export const store = createStore(reducers, composeEnhancers(middleware));
