export const COLUMNS = [
  //   {
  //     Header: "Expand",
  //     Footer: "Expand",
  //     accessor: "expand",
  //   },
  // {
  //   Header: "Strategy Type",
  //   Footer: "Strategy Type",
  //   accessor: "strategy_type",
  // },
  {
    Header: "Stock/Crypto",
    Footer: "Stock/Crypto",
    accessor: "bt_symbol",
  },
  {
    Header: "Strategy Name",
    Footer: "Strategy Name",
    accessor: "test_strategy_name",
  },
  {
    Header: "Back Test ROI",
    Footer: "Back Test ROI",
    accessor: "bt_overall_roi",
  },
  {
    Header: "Forward Test ROI",
    Footer: "Forward Test ROI",
    accessor: "ft_roi",
  },
  {
    Header: "Status",
    Footer: "Status",
    accessor: "state",
  },
  // {
  //   Header: "Age",
  //   Footer: "Phone",
  //   accessor: "age",
  // },
];

export const GROUPED_COLUMNS = [
  {
    Header: "Id",
    Footer: "Id",
    accessor: "id",
  },
  {
    Header: "Name",
    Footer: "Name",
    columns: [
      {
        Header: "Strategy Name",
        Footer: "Strategy Name",
        accessor: "strategy_name",
      },
      {
        Header: "ROI",
        Footer: "ROI",
        accessor: "roi",
      },
    ],
  },
  {
    Header: "Info",
    Footer: "Info",
    columns: [
      {
        Header: "Strategy Type",
        Footer: "Strategy Type",
        accessor: "strategy_type",
      },
      {
        Header: "Country",
        Footer: "Country",
        accessor: "country",
      },
      {
        Header: "Phone",
        Footer: "Phone",
        accessor: "phone",
      },
    ],
  },
];
