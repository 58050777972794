import {
  GET_TRANSACTIONS_SUCCESS_ACTION,
  GET_TRANSACTIONS_FAILED_ACTION,
} from "../actions/TransactionsAction";

const initialState = {
  data: [],
  errorMessage: "",
  successMessage: "",
  showLoading: false,
};

export function TransactionsReducer(state = initialState, action) {
  if (action.type === GET_TRANSACTIONS_SUCCESS_ACTION) {
    return {
      ...state,
      data: action.payload,
      errorMessage: "",
      successMessage: "Get Transaction api call successful",
      showLoading: false,
    };
  }
  if (action.type === GET_TRANSACTIONS_FAILED_ACTION) {
    return {
      ...state,
      // data: action.payload,
      errorMessage: "Get Transaction api call failed",
      successMessage: "",
      showLoading: false,
    };
  }

  return state;
}

export default TransactionsReducer;
