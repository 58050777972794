import { getHighestReturn } from "../../services/MarketScanService";

export const HIGHEST_RETURN_SUCCESS_ACTION =
  "[highestreturn action] confirmed highestreturn";
export const HIGHEST_RETURN_FAILED_ACTION =
  "[highestreturn action] failed highestreturn";
export const MOST_RECENT_CHANGE_SUCCESS_ACTION =
  "[highestreturn action] confirmed mostrecentchange";
export const MOST_RECENT_CHANGE_FAILED_ACTION =
  "[highestreturn action] failed mostrecentchange";

const highestReturnBaseURL =
  "https://etn4nbdcci.execute-api.ap-southeast-1.amazonaws.com/default";

const mostRecentChangeBaseURL =
  "https://5xb3pvggaf.execute-api.ap-southeast-1.amazonaws.com/dev";

export function getHighestReturnActionYear() {
  return async (dispatch) => {
    try {
      //   const res = await getHighestReturn();
      const res = await fetch(
        `${highestReturnBaseURL}/getHighestReturn/oneyear`
      );
      const result = await res.json();
      dispatch(highestReturnSuccessAction(result));
    } catch (error) {
      dispatch(highestReturnFailedAction(error));
    }
  };
}

export function getHighestReturnActionQuarter() {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${highestReturnBaseURL}/getHighestReturn/threemonth`
      );
      const result = await res.json();
      dispatch(highestReturnSuccessAction(result));
    } catch (error) {
      dispatch(highestReturnFailedAction(error));
    }
  };
}

export function getHighestReturnActionMonth() {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${highestReturnBaseURL}/getHighestReturn/onemonth`
      );
      const result = await res.json();
      dispatch(highestReturnSuccessAction(result));
    } catch (error) {
      dispatch(highestReturnFailedAction(error));
    }
  };
}

export function getHighestReturnActionWeek() {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${highestReturnBaseURL}/getHighestReturn/oneweek`
      );
      const result = await res.json();
      dispatch(highestReturnSuccessAction(result));
    } catch (error) {
      dispatch(highestReturnFailedAction(error));
    }
  };
}

export function getHighestReturnActionDay() {
  return async (dispatch) => {
    try {
      const res = await fetch(
        `${highestReturnBaseURL}/getHighestReturn/oneday`
      );
      const result = await res.json();
      dispatch(highestReturnSuccessAction(result));
    } catch (error) {
      dispatch(mostRecentChangeFailedAction(error));
    }
  };
}

export function getMostRecentChangeAction() {
  return async (dispatch) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          topX: 100,
        }),
      };
      const res = await fetch(
        `${mostRecentChangeBaseURL}/mostRecentChange`,
        requestOptions
      );
      const result = await res.json();
      dispatch(mostRecentChangeSuccessAction(result));
    } catch (error) {
      dispatch(highestReturnFailedAction(error));
    }
  };
}

export function highestReturnSuccessAction(payload) {
  return {
    type: HIGHEST_RETURN_SUCCESS_ACTION,
    payload,
  };
}

export function highestReturnFailedAction(message) {
  return {
    type: HIGHEST_RETURN_FAILED_ACTION,
    payload: message,
  };
}
export function mostRecentChangeSuccessAction(payload) {
  return {
    type: MOST_RECENT_CHANGE_SUCCESS_ACTION,
    payload,
  };
}

export function mostRecentChangeFailedAction(message) {
  return {
    type: MOST_RECENT_CHANGE_FAILED_ACTION,
    payload: message,
  };
}
