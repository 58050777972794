import {
  EQUITY_CURVE_SUCCESS_ACTION,
  EQUITY_CURVE_FAILED_ACTION,
} from "../actions/EquityCurveAction";

const initialState = {
  data: [],
  errorMessage: "",
  successMessage: "",
  showLoading: false,
};

export function EquityCurveReducer(state = initialState, action) {
  if (action.type === EQUITY_CURVE_SUCCESS_ACTION) {
    return {
      ...state,
      data: action.payload,
      errorMessage: "",
      successMessage: "api call successful",
      showLoading: false,
    };
  }
  if (action.type === EQUITY_CURVE_FAILED_ACTION) {
    return {
      ...state,
      data: action.payload,
      errorMessage: "Get equity curve api call failed",
      successMessage: "",
      showLoading: false,
    };
  }

  return state;
}

export default EquityCurveReducer;
