import React from "react";
import ReactApexChart from "react-apexcharts";

function generateDayWiseTimeSeries(start, numDays, yRange) {
  let result = [];
  let currentDate = new Date(start);

  for (let i = 0; i < numDays; i++) {
    const yValue =
      Math.floor(Math.random() * (yRange.max - yRange.min + 1)) + yRange.min;
    result.push({
      x: new Date(currentDate),
      y: yValue,
    });

    currentDate.setDate(currentDate.getDate() + 1); // Increment to the next day
  }

  return result;
}

class ApexChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      series: [
        {
          data: generateDayWiseTimeSeries(
            new Date("11 Feb 2017").getTime(),
            20,
            {
              min: 10,
              max: 60,
            }
          ),
        },
      ],
      options: {
        chart: {
          id: "fb",
          group: "social",
          type: "line",
          height: 160,
        },
        colors: ["#008FFB"],
      },

      seriesLine2: [
        {
          data: [
            [1327516800000, 30.95],
            [1327446000000, 31.34],
            [1327532400000, 31.18],
            [1327618800000, 31.05],
            [1327878000000, 31.0],
            [1327964400000, 30.95],
            [1328050800000, 31.24],
            [1328137200000, 31.29],
            [1328223600000, 31.85],
            [1328482800000, 31.86],
            [1328569200000, 32.28],
            [1328655600000, 32.1],
            [1328742000000, 32.65],
            [1328828400000, 32.21],
          ],
        },
      ],
      optionsLine2: {
        chart: {
          id: "tw",
          group: "social",
          type: "bar",
          height: 160,
        },
        plotOptions: {
          bar: {
            columnWidth: "100%",
            colors: {
              ranges: [
                {
                  from: -1000,
                  to: 0,
                  color: "#F15B46",
                },
                {
                  from: 1,
                  to: 10000,
                  color: "#FEB019",
                },
              ],
            },
          },
        },
        stroke: {
          width: 0,
        },
        colors: ["#546E7A"],
        xaxis: {
          type: "datetime",
        },
      },

      seriesArea: [
        {
          data: generateDayWiseTimeSeries(
            new Date("11 Feb 2017").getTime(),
            20,
            {
              min: 10,
              max: 60,
            }
          ),
        },
      ],
      optionsArea: {
        chart: {
          id: "yt",
          group: "social",
          type: "area",
          height: 160,
        },
        colors: ["#00E396"],
      },

      seriesSmall: [
        {
          data: generateDayWiseTimeSeries(
            new Date("11 Feb 2017").getTime(),
            20,
            {
              min: 10,
              max: 60,
            }
          ),
        },
      ],
      optionsSmall: {
        chart: {
          id: "ig",
          group: "social",
          type: "area",
          height: 160,
          width: 300,
        },
        colors: ["#008FFB"],
      },

      seriesSmall2: [
        {
          data: generateDayWiseTimeSeries(
            new Date("11 Feb 2017").getTime(),
            20,
            {
              min: 10,
              max: 60,
            }
          ),
        },
      ],
      optionsSmall2: {
        chart: {
          id: "li",
          group: "social",
          type: "area",
          height: 160,
          width: 300,
        },
        colors: ["#546E7A"],
      },
    };
  }

  render() {
    return (
      <div>
        <div id="wrapper">
          <div id="chart-line">
            <ReactApexChart
              options={this.state.options}
              series={this.state.series}
              type="line"
              height={300}
            />
          </div>
          <div id="chart-line2">
            <ReactApexChart
              options={this.state.optionsLine2}
              series={this.state.seriesLine2}
              type="bar"
              height={300}
            />
          </div>
          <div id="chart-area">
            <ReactApexChart
              options={this.state.optionsArea}
              series={this.state.seriesArea}
              type="area"
              height={300}
            />
          </div>
          <div class="columns">
            <div id="chart-small">
              <ReactApexChart
                options={this.state.optionsSmall}
                series={this.state.seriesSmall}
                type="area"
                height={160}
                width={300}
              />
            </div>
            <div id="chart-small2">
              <ReactApexChart
                options={this.state.optionsSmall2}
                series={this.state.seriesSmall2}
                type="area"
                height={160}
                width={300}
              />
            </div>
          </div>
        </div>
        <div id="html-dist"></div>
      </div>
    );
  }
}

export default ApexChart;
