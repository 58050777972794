import {
  NEWS_ANALYSIS_SUCCESS_ACTION,
  NEWS_ANALYSIS_FAILED_ACTION,
} from "../actions/NewsAnalysisAction";

const initialState = {
  data: [],
  errorMessage: "",
  successMessage: "",
  showLoading: false,
};

export function NewsAnalysisReducer(state = initialState, action) {
  if (action.type === NEWS_ANALYSIS_SUCCESS_ACTION) {
    return {
      ...state,
      data: action.payload,
      errorMessage: "",
      successMessage: "api call successful",
      showLoading: false,
    };
  }
  if (action.type === NEWS_ANALYSIS_FAILED_ACTION) {
    return {
      ...state,
      data: action.payload,
      errorMessage: "Get news analysis api call failed",
      successMessage: "",
      showLoading: false,
    };
  }

  return state;
}

export default NewsAnalysisReducer;
