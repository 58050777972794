import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Nav, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  getHighestReturnActionYear,
  getHighestReturnActionQuarter,
  getHighestReturnActionMonth,
  getHighestReturnActionWeek,
  getHighestReturnActionDay,
  getMostRecentChangeAction,
} from "../../store/actions/MarketScanAction";
import PerfectScrollbar from "react-perfect-scrollbar";

export const MarketScanTable = () => {
  const [periodKey, setPeriodKey] = useState("Year");
  const marketScanData = useSelector((state) => state.marketScanData);
  useEffect(() => {
    try {
      getHighestReturnYear("Year");
    } catch (error) {
      // Handle the error here
      console.log(
        "Error occurred while getting highest return for year:",
        error
      );
    }
    try {
      getMostRecentChange();
    } catch (error) {
      // Handle the error here
      console.log("Error occurred while getting most recent change:", error);
    }
  }, []);

  // useEffect(() => {
  //   if (dataHighestReturn.data.length > 0) {
  //     console.log(dataHighestReturn.data);
  //   }
  // }, [dataHighestReturn]);

  const dispatch = useDispatch();

  function getHighestReturnYear(periodKey) {
    dispatch(getHighestReturnActionYear());
    setPeriodKey(periodKey);
    // window.location.reload();
  }

  function getHighestReturnQuarter(periodKey) {
    dispatch(getHighestReturnActionQuarter());
    setPeriodKey(periodKey);
  }

  function getHighestReturnMonth(periodKey) {
    dispatch(getHighestReturnActionMonth());
    setPeriodKey(periodKey);
  }

  function getHighestReturnWeek(periodKey) {
    dispatch(getHighestReturnActionWeek());
    setPeriodKey(periodKey);
  }

  function getHighestReturnDay(periodKey) {
    dispatch(getHighestReturnActionDay());
    setPeriodKey(periodKey);
  }
  function getMostRecentChange() {
    dispatch(getMostRecentChangeAction());
  }

  return (
    <div className="row">
      <div className="col-xl-6 col-xxl-12">
        <div className="card">
          <div className="card-header border-0 pb-0">
            <h4 className="mb-0 mx-2 text-black fs-20">
              Strategy Trend Change
            </h4>
            {/* <div className="card-action card-tabs style-4 my-3 mx-5 period-tab">
              <Nav as="ul" className="nav nav-tabs style-4" role="tablist">
                <Nav.Item as="li" className="nav-item">
                  <Nav.Link
                    as="a"
                    className="nav-link c-pointer"
                    data-toggle="tab"
                    eventKey="Day"
                    role="tab"
                  >
                    Day
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" className="nav-item">
                  <Nav.Link
                    as="a"
                    className="nav-link c-pointer"
                    data-toggle="tab"
                    eventKey="Week"
                    role="tab"
                  >
                    Week
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" className="nav-item">
                  <Nav.Link
                    as="a"
                    className="nav-link c-pointer"
                    data-toggle="tab"
                    eventKey="Month"
                    role="tab"
                  >
                    Month
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" className="nav-item">
                  <Nav.Link
                    as="a"
                    className="nav-link c-pointer"
                    data-toggle="tab"
                    eventKey="Quarter"
                    role="tab"
                  >
                    Quarter
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" className="nav-item">
                  <Nav.Link
                    as="a"
                    className="nav-link c-pointer"
                    data-toggle="tab"
                    eventKey="Year"
                    role="tab"
                  >
                    Year
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div> */}
          </div>
          <div className="card-body p-3 mb-3">
            <div className="table-responsive table-non-scroll-container">
              <table className="table text-center">
                <colgroup>
                  <col className="col-2" />
                  <col className="col-2" />
                  <col className="col-2" />
                  <col className="col-2" />
                  <col className="col-2" />
                </colgroup>
                <thead>
                  <tr>
                    <th>Symbol</th>
                    <th>Stock / Crypto</th>
                    <th>Trend</th>
                    <th>Three Day Change</th>
                    <th>Trend Change Date</th>
                  </tr>
                </thead>
              </table>
            </div>
            <div className="table-responsive table-scroll-container">
              <table className="table text-center bg-success-hover tr-rounded">
                <colgroup>
                  <col className="col-2" />
                  <col className="col-2" />
                  <col className="col-2" />
                  <col className="col-2" />
                  <col className="col-2" />
                </colgroup>
                {/* <thead>
                  <tr>
                    <th>Symbol</th>
                    <th>Stock / Crypto</th>
                    <th>Trend Change Date</th>
                    <th>Trend</th>
                    <th>Three Day Change</th>
                  </tr>
                </thead> */}
                <tbody>
                  {marketScanData.mostRecentChangeData.length > 0 ? (
                    marketScanData.mostRecentChangeData.map((row, index) => {
                      return (
                        <tr key={index}>
                          <td>{row.symbol}</td>
                          <td>{row.name}</td>
                          <td>{row.trend ? "Up" : "Down"}</td>
                          <td>{row.three_day_percent}</td>
                          <td>{row.change_day}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr></tr>
                  )}
                </tbody>
                {/* <tbody>
                  <tr>
                    <td className="text-left">82.3</td>
                    <td>0.15</td>
                    <td className="text-right">$134,12</td>
                  </tr>
                  <tr>
                    <td className="text-left">83.9</td>
                    <td>0.18</td>
                    <td className="text-right">$237,31</td>
                  </tr>
                  <tr>
                    <td className="text-left">84.2</td>
                    <td>0.25</td>
                    <td className="text-right">$252,58</td>
                  </tr>
                  <tr>
                    <td className="text-left">86.2</td>
                    <td>0.35</td>
                    <td className="text-right">$126,26</td>
                  </tr>
                  <tr>
                    <td className="text-left">91.6</td>
                    <td>0.75</td>
                    <td className="text-right">$46,92</td>
                  </tr>
                  <tr>
                    <td className="text-left">92.6</td>
                    <td>0.21</td>
                    <td className="text-right">$123,27</td>
                  </tr>
                  <tr>
                    <td className="text-left">93.9</td>
                    <td>0.55</td>
                    <td className="text-right">$212,56</td>
                  </tr>
                  <tr>
                    <td className="text-left">94.2</td>
                    <td>0.18</td>
                    <td className="text-right">$129,26</td>
                  </tr>
                </tbody> */}
              </table>
            </div>
          </div>
          {/* <div className="card-footer border-0 pt-0 text-center">
            <Link to="coin-details" className="btn-link">
              Show more <i className="fa fa-caret-right ml-2 scale-2" />
            </Link>
          </div> */}
        </div>
      </div>
      <div className="col-xl-6 col-xxl-12">
        {/* <div className="col-sm-6"> */}
        <div className="card">
          <div className="card-header border-0 pb-0">
            <div className="header-wrapper">
              <h4 className="mb-0 mx-2 text-black fs-20">
                Strategy Highest Return
              </h4>
            </div>
            <div className="card-action card-tabs style-4 place-end">
              <Nav as="ul" className="nav nav-tabs style-4" role="tablist">
                <Nav.Item as="li" className="nav-item">
                  <Nav.Link
                    as="a"
                    className="nav-link c-pointer"
                    data-toggle="tab"
                    eventKey="Day"
                    role="tab"
                    onClick={() => {
                      getHighestReturnDay("Day");
                    }}
                  >
                    Day
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" className="nav-item">
                  <Nav.Link
                    as="a"
                    className="nav-link c-pointer"
                    data-toggle="tab"
                    eventKey="Week"
                    role="tab"
                    onClick={() => {
                      getHighestReturnWeek("Week");
                    }}
                  >
                    Week
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" className="nav-item">
                  <Nav.Link
                    as="a"
                    className="nav-link c-pointer"
                    data-toggle="tab"
                    eventKey="Month"
                    role="tab"
                    onClick={() => {
                      getHighestReturnMonth("Month");
                    }}
                  >
                    Month
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" className="nav-item">
                  <Nav.Link
                    as="a"
                    className="nav-link c-pointer"
                    data-toggle="tab"
                    eventKey="Quarter"
                    role="tab"
                    onClick={() => {
                      getHighestReturnQuarter("Quarter");
                    }}
                  >
                    Quarter
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item as="li" className="nav-item">
                  <Nav.Link
                    as="a"
                    className={`nav-link c-pointer ${
                      periodKey === "Year" ? "active" : ""
                    }`}
                    data-toggle="tab"
                    eventKey="Year"
                    role="tab"
                    onClick={() => {
                      getHighestReturnYear("Year");
                    }}
                  >
                    Year
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </div>
          <div className="card-body p-3 mb-3">
            <div className="table-responsive table-non-scroll-container">
              <table className="table text-center">
                <colgroup>
                  <col className="col-4" />
                  <col className="col-4" />
                  <col className="col-4" />
                </colgroup>
                <thead>
                  <tr>
                    <th className="text-left">Symbol</th>
                    <th className="text-center">Stock / Crypto</th>
                    <th className="text-right">ROI</th>
                  </tr>
                </thead>
              </table>
            </div>
            <div className="table-responsive table-scroll-container">
              {/* <table className="table text-center bg-info-hover tr-rounded"> */}
              <table className="table text-center bg-warning-hover tr-rounded">
                <colgroup>
                  <col className="col-4" />
                  <col className="col-4" />
                  <col className="col-4" />
                </colgroup>
                {/* <thead>
                  <tr>
                    <th className="text-left">Symbol</th>
                    <th className="text-center">Stock/Crypto</th>
                    <th className="text-right">ROI</th>
                  </tr>
                </thead> */}
                <tbody>
                  {marketScanData.highestReturnData.length > 0 ? (
                    marketScanData.highestReturnData.map((row, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-left">{row.symbol}</td>
                          <td>{row.name}</td>
                          <td className="text-right">{row.roi}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr></tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {/* <div className="card-footer border-0 pt-0 text-center">
            <Link to="coin-details" className="btn-link">
              Show more <i className="fa fa-caret-right ml-2 scale-2" />
            </Link>
          </div> */}
        </div>
      </div>
      {/* <div className="col-sm-6"> */}
    </div>
    // </div>
  );
};
export default MarketScanTable;
