import Donut from "../components/zenix/MyWallets/Donut";
import React, { useContext, useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThemeContext } from "../../context/ThemeContext";
import WidgetChart1 from "../components/zenix/Home/WidgetChart1";
import WidgetChart2 from "../components/zenix/Home/WidgetChart2";
import WidgetChart3 from "../components/zenix/Home/WidgetChart3";
import WidgetChart4 from "../components/zenix/Home/WidgetChart4";

import ReturnComparisonLine from "../components/table/SortingTable/ReturnComparisonLine";
import ReturnComparisonBar from "../components/table/SortingTable/ReturnComparisonBar";
import EquityCurve from "../components/table/SortingTable/EquityCurve";
// import { getEquityCurveAction } from "../../../src/store/actions/EquityCurveAction";
import { Button, Collapse } from "react-bootstrap";

import TestSynchTable from "../components/table/SortingTable/TestSynchTable";

function StrategyOverview({ equityCurveData, overviewData }) {
  const { background } = useContext(ThemeContext);
  // console.log(overviewData);
  // const dispatch = useDispatch();
  // const [selectedTestId, setSelectedTestId] = useState(testId);

  // const equityCurveData = useSelector((state) => state.equityCurveData);
  // function getEquityCurve(testId) {
  //   dispatch(getEquityCurveAction(testId));
  // }
  // console.log(equityCurveData);
  // useEffect(() => {
  //   if (testId !== "") {
  //     try {
  //       getEquityCurve(testId);
  //     } catch (error) {
  //       // Handle the error here
  //       console.log("Error occurred while getting stock price:", error);
  //     }
  //   }
  // }, [testId]);

  // useEffect(() => {
  //   // console.log(equityCurveData.data);
  //   if (
  //     equityCurveData &&
  //     equityCurveData.data &&
  //     // equityCurveData.data.length > 0
  //     equityCurveData.data.equity_per_day
  //   ) {
  //     // console.log(equityCurveData.data.equity_per_day);
  //     const data = changeDateTimeOfEquityCurve(equityCurveData);
  //     // console.log(data);
  //   }
  // }, [equityCurveData]);

  // let previousEquityCurveData;
  // const processedData = useMemo(() => {
  //   if (
  //     equityCurveData &&
  //     equityCurveData.data &&
  //     equityCurveData.data.equity_per_day &&
  //     previousEquityCurveData &&
  //     previousEquityCurveData.data &&
  //     previousEquityCurveData.data.equity_per_day &&
  //     previousEquityCurveData.data.equity_per_day["bt_overall_equity_per_day"]
  //       .data[0].x !==
  //       equityCurveData.data.equity_per_day["bt_overall_equity_per_day"].data[0]
  //         .x
  //   ) {
  //     changeDateTimeOfEquityCurve(equityCurveData);
  //     previousEquityCurveData = equityCurveData;
  //     console.log(equityCurveData);
  //     return equityCurveData;
  //   }
  //   return null; // Return a default value if conditions are not met
  // }, [equityCurveData]);

  // useEffect(() => {
  //   if (processedData) {
  //     // Processed data is available here
  //     // Perform any necessary operations
  //     console.log(processedData);
  //   }
  // }, [processedData]);
  // function changeDateTimeOfEquityCurve(equityCurveData) {
  //   // console.log(
  //   //   equityCurveData.data.equity_per_day["bt_1st_equity_per_day"].data
  //   // );
  //   let data =
  //     equityCurveData.data.equity_per_day["bt_overall_equity_per_day"].data;

  //   console.log(data);
  //   data.forEach((obj) => {
  //     obj.x = new Date(obj.x).getTime();
  //   });

  //   return data;
  // }

  const [isOpen, setIsOpen] = useState(false);
  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="card">
          <div className="card-body">
            <div className="row align-items-end me-0">
              <div className="col-xl-6 col-lg-12 col-xxl-12 mx-4">
                <div className="row">
                  <div className="col-sm-4">
                    <div className="mb-4">
                      <p className="mb-2">Stock / Crypto</p>
                      <h4 className="text-black">{overviewData.bt_symbol}</h4>
                    </div>
                    {/* <div>
                      <p className="mb-2">Max Drawdown</p>
                      <h4 className="text-black">12%</h4>
                    </div> */}
                  </div>
                  <div className="col-sm-4">
                    <div className="mb-4">
                      <p className="mb-2">Strategy Name</p>
                      <h4 className="text-black">
                        {overviewData.test_strategy_name}
                      </h4>
                    </div>
                    {/* <div>
                      <p className="mb-2">Win/Loss Ratio</p>
                      <h4 className="text-black">70%</h4>
                    </div> */}
                  </div>
                  <div className="col-sm-4">
                    <div className="mb-4">
                      <p className="mb-2">Strategy Type</p>
                      <h4 className="text-black">
                        {overviewData.strategy_type}
                      </h4>
                    </div>
                    {/* <div className="mb-4">
                      <p className="mb-2">Market ROI</p>
                      <h4 className="text-black">34%</h4>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-12 col-xxl-12 mt-4 mx-4">
                <div className="row">
                  <div className="col-sm-4">
                    <div className="mb-4">
                      <p className="mb-2">1st BackTest ROI</p>
                      <h4 className="text-black">{overviewData.bt_1st_roi}%</h4>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div>
                      <p className="mb-2">2nd BackTest ROI</p>
                      <h4 className="text-black">{overviewData.bt_2nd_roi}%</h4>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div>
                      <p className="mb-2">ForwardTest ROI</p>
                      <h4 className="text-black">{overviewData.ft_roi}%</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                {/* <div className="col-sm-12 mb-sm-0"> */}
                <EquityCurve
                  equityCurveData={equityCurveData}
                  overviewData={overviewData}
                />
                {/* </div> */}
                {/* <TestSynchTable /> */}
              </div>
              <div className="card-body py-0 px-0 display-flex justify-content-center">
                <Button
                  onClick={toggleCollapse}
                  className="me-2"
                  variant="outline-primary btn-xs"
                >
                  {isOpen ? "Show Less" : "Show More"}
                </Button>
              </div>
              <Collapse in={isOpen}>
                <div className="row mt-4">
                  <div className="col-sm-6 mb-sm-0 mb-4 text-center">
                    <ReturnComparisonLine equityCurveData={equityCurveData} />
                  </div>
                  <div className="col-sm-6 mb-sm-0 mb-4 text-center">
                    <ReturnComparisonBar equityCurveData={equityCurveData} />
                  </div>
                  <div className="col-xl-6 col-lg-12 col-xxl-12 mt-4 mx-4">
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="mb-4">
                          <p className="mb-2">Market ROI</p>
                          <h4 className="text-black">34%</h4>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div>
                          <p className="mb-2">Max Drawdown</p>
                          <h4 className="text-black">12%</h4>
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div>
                          <p className="mb-2">Win/Loss Ratio</p>
                          <h4 className="text-black">70%</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StrategyOverview;
