import React, { useState, useEffect } from "react";

const ResizableLogo = () => {
  const [fontSize, setFontSize] = useState(20); // Default font size

  const adjustTextSize = () => {
    const screenWidth = window.innerWidth;
    const calculatedFontSize = screenWidth * 0.04; // Adjust the scaling factor as needed
    const calculatedFontSizeMax = Math.min(calculatedFontSize, 35);
    setFontSize(calculatedFontSizeMax);
  };

  useEffect(() => {
    adjustTextSize();
    window.addEventListener("resize", adjustTextSize);
    return () => {
      window.removeEventListener("resize", adjustTextSize);
    };
  }, []);

  return (
    <div className="investTrend-logo">
      <div style={{ fontSize: `${fontSize}px` }}>InvestTrend</div>
    </div>
  );
};

export default ResizableLogo;
