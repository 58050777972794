import React, { Fragment, useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Nav, Dropdown, Modal } from "react-bootstrap";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { ThemeContext } from "../../../context/ThemeContext";

import Donut from "../zenix/MyWallets/Donut";
import WalletTab from "../zenix/MyWallets/WalletTab";
import ContactSlider from "../zenix/Home/ContactSlider";
import SwiperSlider from "../../pages/SwiperSliderStrategy";
import StrategyTab from "../../pages/StrategyTab";
import StrategyTestTable from "../table/SortingTable/StrategyTestTable";
import AddStrategySlickSlides from "../../pages/AddStrategySlickSlides";
import EditStrategyBox from "../../pages/EditStrategyBox";
import InfoBox from "../../pages/InfoBox";
import QRCodeBox from "../../pages/QRCodeBox";
import MarketScanTable from "../../pages/MarketScanTable";

const CoinChart = loadable(() =>
  pMinDelay(import("../zenix/MyWallets/CoinChart"), 1000)
);

const Strategy = () => {
  const { changeBackground, background } = useContext(ThemeContext);
  const [addStrategyModal, setAddStrategyModal] = useState(false);
  const [editStrategyModal, setEditStrategyModal] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [QRModal, setQRModal] = useState(false);
  const [QRCodeLink, setQRCodeLink] = useState();
  const [user, setUser] = useState("test_user");
  const [editTestId, setEditTestId] = useState("");
  const [crrency1, setCrrency1] = useState("Monthly (2023)");
  const [strategyType, setStrategyType] = useState("TrendFollowing");
  const [strategy, setStrategy] = useState("SuperTrend");
  const [loadTop, setLoadTop] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    changeBackground({ value: "light", label: "Light" });
  }, []);
  const handleStrategyTypeClick = (strategyType) => {
    setStrategyType(strategyType);
    setStrategy("");
  };
  return (
    <Fragment>
      <div className="form-head mb-sm-5 mb-3 d-flex align-items-center flex-wrap">
        <h2 className="font-w600 mb-0 me-auto mb-2 text-black">
          Trading Strategies
        </h2>
        {/* <Link to={"#"} className="btn btn-outline-secondary me-3 mb-2">
          <i className="las la-calendar scale5 me-2"></i>Filter Periode
        </Link> */}
        {/* <Link
          to={"#"}
          className="btn btn-secondary mb-2"
          onClick={() => setAddStrategyModal(true)}
        >
          + Add Strategy Test
        </Link> */}
      </div>
      <StrategyTab handleStrategyTypeClick={handleStrategyTypeClick} />
      <div className="row">
        <div className="col-xl-12">
          <div className="swiper-box">
            <SwiperSlider
              strategyType={strategyType}
              setStrategy={setStrategy}
            />
          </div>
        </div>
      </div>
      <div className="row my-3">
        <div className="col-xl-12">
          <StrategyTestTable
            strategy={strategy}
            setEditStrategyModal={setEditStrategyModal}
            setAddStrategyModal={setAddStrategyModal}
            setInfoModal={setInfoModal}
            setQRModal={setQRModal}
            setQRCodeLink={setQRCodeLink}
            user={user}
            setEditTestId={setEditTestId}
            loadTop={loadTop}
            setLoadTop={setLoadTop}
            loading={loading}
          />
        </div>
      </div>
      <MarketScanTable />
      <Modal
        className="fade"
        show={addStrategyModal}
        onHide={setAddStrategyModal}
        size="lg"
      >
        <AddStrategySlickSlides
          user={user}
          showModal={setAddStrategyModal}
          setLoadTop={setLoadTop}
          setLoading={setLoading}
        />
      </Modal>
      <Modal
        className="fade"
        show={editStrategyModal}
        onHide={setEditStrategyModal}
        size="lg"
      >
        <EditStrategyBox
          editTestId={editTestId}
          showModal={setEditStrategyModal}
        />
      </Modal>
      <Modal
        className="fade bd-example-modal-lg"
        show={infoModal}
        onHide={setInfoModal}
        size="lg"
      >
        <InfoBox showModal={setInfoModal} />
      </Modal>
      <Modal
        className="fade bd-example-modal-lg"
        show={QRModal}
        onHide={setQRModal}
        size="lg"
      >
        <QRCodeBox showModal={setQRModal} QRCodeLink={QRCodeLink} />
      </Modal>
    </Fragment>
  );
};
export default Strategy;
