export const STOCK_PRICE_SUCCESS_ACTION =
  "[stockprice action] confirmed stockprice";
export const STOCK_PRICE_FAILED_ACTION =
  "[stockprice action] failed stockprice";

const baseURL =
  "https://5xb3pvggaf.execute-api.ap-southeast-1.amazonaws.com/dev";

export function getStockPriceAction(test_id) {
  return async (dispatch) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          test_id: test_id,
        }),
      };

      //   const res = await getHighestReturn();
      const res = await fetch(`${baseURL}/stockPrice`, requestOptions);
      const result = await res.json();
      dispatch(stockPriceSuccessAction(result));
    } catch (error) {
      dispatch(stockPriceFailedAction(error));
    }
  };
}

export function stockPriceSuccessAction(payload) {
  return {
    type: STOCK_PRICE_SUCCESS_ACTION,
    payload,
  };
}

export function stockPriceFailedAction(message) {
  return {
    type: STOCK_PRICE_FAILED_ACTION,
    payload: message,
  };
}
