import React, { useEffect, useRef } from "react";
import QRCode from "qrcode";

const QRCodeBox = ({ QRCodeLink, showModal }) => {
  const qrCodeRef = useRef(null);

  useEffect(() => {
    if (qrCodeRef.current && QRCodeLink) {
      QRCode.toCanvas(
        qrCodeRef.current,
        QRCodeLink,
        { width: 256, height: 256 },
        (error) => {
          if (error) {
            console.error(error);
          }
        }
      );
    }
  }, [QRCodeLink]);

  return (
    <div className="display-flex justify-content-center mx-4 my-4">
      {QRCodeLink ? (
        <canvas ref={qrCodeRef} />
      ) : (
        <div>Telegram Notifications not enabled yet</div>
      )}
    </div>
  );
};

export default QRCodeBox;
