export const GET_STRATEGY_TEST_SUCCESS_ACTION =
  "[strategytest action] Confirmed Get Strategytest";
export const GET_STRATEGY_TEST_FAILED_ACTION =
  "[strategytest action] Failed Get Strategytest";
export const CREATE_STRATEGY_TEST_SUCCESS_ACTION =
  "[strategytest action] Confirmed Create Strategytest";
export const CREATE_STRATEGY_TEST_FAILED_ACTION =
  "[strategytest action] Failed Create Strategytest";
export const EDIT_STRATEGY_BACK_TEST_SUCCESS_ACTION =
  "[strategytest action] Confirmed Edit Strategytest";
export const EDIT_STRATEGY_BACK_TEST_FAILED_ACTION =
  "[strategytest action] Failed Edit Strategytest";

export const START_STRATEGY_BACK_TEST_SUCCESS_ACTION =
  "[strategytest action] Confirmed Start Strategy Back Test";
export const START_STRATEGY_BACK_TEST_FAILED_ACTION =
  "[strategytest action] Failed Start Strategy Back Test";
export const START_STRATEGY_FORWARD_TEST_SUCCESS_ACTION =
  "[strategytest action] Confirmed Start Strategy Forward Test";
export const START_STRATEGY_FORWARD_TEST_FAILED_ACTION =
  "[strategytest action] Failed Start Strategy Forward Test";
export const STOP_STRATEGY_FORWARD_TEST_SUCCESS_ACTION =
  "[strategytest action] Confirmed Stop Strategy Forward Test";
export const STOP_STRATEGY_FORWARD_TEST_FAILED_ACTION =
  "[strategytest action] Failed Stop Strategy Forward Test";
export const REMOVE_STRATEGY_TEST_SUCCESS_ACTION =
  "[strategytest action] Confirmed Remove Strategy Test";
export const REMOVE_STRATEGY_TEST_FAILED_ACTION =
  "[strategytest action] Failed Remove Strategy Test";

export const GET_FORWARD_TEST_SUCCESS_ACTION =
  "[strategytest action] Confirmed Get Forward Test Result";
export const GET_FORWARD_TEST_FAILED_ACTION =
  "[strategytest action] Failed Get Forward Test Result";
export const GET_FORWARD_TEST_PROGRESS_SUCCESS_ACTION =
  "[strategytest action] Confirmed Get Forward Test Progress";
export const GET_FORWARD_TEST_PROGRESS_FAILED_ACTION =
  "[strategytest action] Failed Get Forward Test Progress";
export const OPTIMIZE_PARAMETERS_SUCCESS_ACTION =
  "[strategytest action] Confirmed Optimize Parameters";
export const OPTIMIZE_PARAMETERS_FAILED_ACTION =
  "[strategytest action] Failed Optimize Parameters";
export const OPTIMIZE_PARAMETERS_PROGRESS_SUCCESS_ACTION =
  "[strategytest action] Confirmed Optimize Parameters Progress";
export const OPTIMIZE_PARAMETERS_PROGRESS_FAILED_ACTION =
  "[strategytest action] Failed Optimize Parameters Porgress";

const baseURL = "https://www.investtrend.tech/api";

export function getStrategyTestAction(user, test_strategy_name) {
  return async (dispatch) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          user: user,
          test_strategy_name: test_strategy_name,
        }),
      };

      const res = await fetch(`${baseURL}/get_tests_by_user`, requestOptions);
      const result = await res.json();
      dispatch(getStrategyTestSuccessAction(result));
    } catch (error) {
      dispatch(getStrategyTestFailedAction(error));
    }
  };
}

export function createStrategyTestAction(strategyTest, setLoading) {
  let bt_start_date = "2023-09-01";
  let bt_end_date = "2024-03-31";
  let bt_2nd_start_date = "2024-01-01";
  let bt_2nd_end_date = "2024-03-31";

  if (strategyTest.backTestPeriod) {
    const backTestPeriod = strategyTest.backTestPeriod;

    const [startDate, endDate] = backTestPeriod.split("-").map((dateString) => {
      const [year, month, day] = dateString.split("/");
      return `${year}-${month}-${day}`;
    });

    bt_start_date = startDate.trim();
    bt_end_date = endDate.trim();

    // const startDateObj = new Date(bt_start_date);
    const endDateObj = new Date(bt_end_date);

    // Calculate the difference in days
    // const days_between =
    //   Math.abs(endDateObj - startDateObj) / (1000 * 60 * 60 * 24);

    let test_range = strategyTest.validationPeriod;

    // if (days_between > 356) {
    //   test_range = 90;
    // } else if (days_between > 90) {
    //   test_range = 30;
    // } else if (days_between > 30) {
    //   test_range = 7;
    // } else if (days_between > 7) {
    //   test_range = 3;
    // } else {
    //   test_range = 1;
    // }
    // console.log(test_range);
    // const final_endDate = new Date(bt_end_date);

    // Subtract the calculated test_range days from the end date
    const second_startDate_temp = new Date(endDateObj);
    second_startDate_temp.setDate(endDateObj.getDate() - test_range);

    // Format the start date as "YYYY-MM-DD"
    bt_2nd_start_date = second_startDate_temp.toISOString().split("T")[0];
    bt_2nd_end_date = bt_end_date;
  }
  const data = {
    test_strategy_name: "SuperTrend",
    strategy_type: "Trend Following",
    test_name: "New Test",
    user: strategyTest.user,
    bt_symbol: strategyTest.stockCrypto
      ? strategyTest.stockCrypto.value
      : "BTC-USD",
    bt_atr_period: strategyTest.atr ? strategyTest.atr.toString() : "6",
    bt_multiplier: strategyTest.multiplier
      ? strategyTest.multiplier.toString()
      : "10",
    bt_start_date: bt_start_date,
    bt_end_date: bt_2nd_start_date,
    bt_2nd_start_date: bt_2nd_start_date,
    bt_2nd_end_date: bt_2nd_end_date,
    validation_period: strategyTest.validationPeriod,
    bt_time_frame_backward: strategyTest.timeFrame
      ? strategyTest.timeFrame.value
      : "1D",
    bt_initial_investment: strategyTest.initialInvestment
      ? strategyTest.initialInvestment.toString()
      : "100000",
    // bt_lot_size: strategyTest.lotSize ? strategyTest.lotSize : "2000",
    bt_lot_size: null,
    bt_sl_size: strategyTest.stopLoss ? strategyTest.stopLoss.toString() : "0",
    bt_tp_size: strategyTest.targetProfit
      ? strategyTest.targetProfit.toString()
      : "0",
    bt_commission: strategyTest.commission
      ? strategyTest.commission.toString()
      : "5",
    ft_symbol: "BTCUSD",
    ft_start_date: null,
    ft_end_date: null,
    ft_time_frame_forward: "D1",
    ft_initial_investment: "100000",
    // ft_lot_size: "0.1",
    ft_lot_size: null,
    ft_sl_size: "5000",
    ft_tp_size: "5000",
    tg_username: strategyTest.telegramName ? strategyTest.telegramName : "",
    tg_enable: strategyTest.enableTelegramNotification
      ? strategyTest.enableTelegramNotification
      : false,
  };
  console.log(data);
  const dataString = JSON.stringify(data);

  return async (dispatch) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: dataString,
        // body: JSON.stringify({
        //   test_strategy_name: "SuperTrend",
        //   strategy_type: "Trend Following",
        //   test_name: "New Test",
        //   user: strategyTest.user,
        //   bt_symbol: strategyTest.stockCrypto
        //     ? strategyTest.stockCrypto.value
        //     : "BTC-USD",
        //   bt_atr_period: strategyTest.atr ? strategyTest.atr.toString() : "6",
        //   bt_multiplier: strategyTest.multiplier
        //     ? strategyTest.multiplier.toString()
        //     : "10",
        //   bt_start_date: bt_start_date,
        //   bt_end_date: bt_end_date,
        //   bt_2nd_start_date: bt_2nd_start_date,
        //   bt_2nd_end_date: bt_2nd_end_date,
        //   bt_time_frame_backward: strategyTest.timeFrame
        //     ? strategyTest.timeFrame.value
        //     : "1D",
        //   bt_initial_investment: strategyTest.initialInvestment
        //     ? strategyTest.initialInvestment.toString()
        //     : "100000",
        //   // bt_lot_size: strategyTest.lotSize ? strategyTest.lotSize : "2000",
        //   bt_lot_size: null,
        //   bt_sl_size: strategyTest.stopLoss ? strategyTest.stopLoss : "0",
        //   bt_tp_size: strategyTest.targetProfit
        //     ? strategyTest.targetProfit.toString()
        //     : "0",
        //   bt_commission: strategyTest.commission
        //     ? strategyTest.commission.toString()
        //     : "5",
        //   ft_symbol: "BTCUSD",
        //   ft_start_date: null,
        //   ft_end_date: null,
        //   ft_time_frame_forward: "D1",
        //   ft_initial_investment: "100000",
        //   // ft_lot_size: "0.1",
        //   ft_lot_size: null,
        //   ft_sl_size: "5000",
        //   ft_tp_size: "5000",
        // }),
      };

      const resultCreateTest = await fetch(
        `${baseURL}/create_test`,
        requestOptions
      );
      const createdTest = await resultCreateTest.json();
      // console.log(createdTest);
      const test_id = createdTest.test_id;
      // console.log("Created test_id: " + test_id);
      const requestOptionsBT = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          test_id: test_id,
        }),
      };
      // await fetch(`${baseURL}/backtesting`, requestOptionsBT);
      const res1 = await fetch(`${baseURL}/backtesting`, requestOptionsBT);
      const resultOfBT = await res1.json();

      const requestOptionsGetOneTest = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          test_id: test_id,
        }),
      };
      const res2 = await fetch(
        `${baseURL}/get_test_instances`,
        requestOptionsGetOneTest
      );
      const resultWithBT = await res2.json();
      // console.log("resultWithBT: " + resultWithBT);

      const response = {
        resultOfBT: resultOfBT,
        resultWithBT: resultWithBT,
      };
      dispatch(createStrategyTestSuccessAction(response));
      setLoading(false);
    } catch (error) {
      console.log(error);
      dispatch(createStrategyTestFailedAction(error));
    }
  };
}

export function startBackTestAction(test_id) {
  return async (dispatch) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          test_id: test_id,
        }),
      };

      const res = await fetch(`${baseURL}/backtesting`, requestOptions);
      const result = await res.json();
      dispatch(startStrategyBackTestSuccessAction(result));
    } catch (error) {
      dispatch(startStrategyBackTestFailedAction(error));
    }
  };
}

export function editBackTestAction(strategyTest) {
  const test_id = strategyTest.editTestId;
  let bt_start_date = "";
  let bt_end_date = "";
  let bt_2nd_start_date = "";
  let bt_2nd_end_date = "";
  if (strategyTest.backTestPeriod) {
    const backTestPeriod = strategyTest.backTestPeriod;

    const [startDate, endDate] = backTestPeriod.split("-").map((dateString) => {
      const [year, month, day] = dateString.split("/");
      return `${year}-${month}-${day}`;
    });

    bt_start_date = startDate.trim();
    bt_end_date = endDate.trim();

    const endDateObj = new Date(bt_end_date);

    let test_range = strategyTest.validationPeriod;

    // Subtract the calculated test_range days from the end date
    const second_startDate_temp = new Date(endDateObj);
    second_startDate_temp.setDate(endDateObj.getDate() - test_range);

    // Format the start date as "YYYY-MM-DD"
    bt_2nd_start_date = second_startDate_temp.toISOString().split("T")[0];
    bt_2nd_end_date = bt_end_date;
  }
  return async (dispatch) => {
    try {
      console.log(test_id);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          test_id: strategyTest.editTestId ? strategyTest.editTestId : "",
          bt_atr_period: strategyTest.atr ? strategyTest.atr.toString() : "6",
          bt_multiplier: strategyTest.multiplier
            ? strategyTest.multiplier.toString()
            : "10",
          bt_start_date: bt_start_date,
          bt_end_date: bt_2nd_start_date,
          bt_2nd_start_date: bt_2nd_start_date,
          bt_2nd_end_date: bt_2nd_end_date,
          validation_period: strategyTest.validationPeriod,
          bt_time_frame_backward: strategyTest.timeFrame
            ? strategyTest.timeFrame.value
            : "1D",
          bt_initial_investment: strategyTest.initialInvestment
            ? strategyTest.initialInvestment.toString()
            : "10000",
          // bt_lot_size: strategyTest.lotSize ? strategyTest.lotSize : "2000",
          bt_lot_size: null,
          bt_sl_size: strategyTest.stopLoss.toString()
            ? strategyTest.stopLoss
            : "0",
          bt_tp_size: strategyTest.targetProfit
            ? strategyTest.targetProfit.toString()
            : "0",
          bt_commission: strategyTest.commission
            ? strategyTest.commission.toString()
            : "5",
        }),
      };

      const res = await fetch(`${baseURL}/edit_test`, requestOptions);
      const result = await res.json();
      console.log(result);
      if (result.message) {
        const requestOptionsBT = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            test_id: test_id,
          }),
        };
        await fetch(`${baseURL}/backtesting`, requestOptionsBT);

        const requestOptionsGetOneTest = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            test_id: test_id,
          }),
        };
        const res2 = await fetch(
          `${baseURL}/get_test_instances`,
          requestOptionsGetOneTest
        );
        const resultEdited = await res2.json();
        console.log(resultEdited);
        dispatch(editBackTestSuccessAction(resultEdited));
      } else {
        dispatch(editBackTestFailedAction(result.error));
      }
    } catch (error) {
      dispatch(editBackTestFailedAction(error));
    }
  };
}

export function startForwardTestAction(test_id) {
  return async (dispatch) => {
    try {
      console.log(test_id);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          test_id: test_id,
        }),
      };

      const res = await fetch(`${baseURL}/start_forward_test`, requestOptions);
      const result = await res.json();
      console.log(result);

      if (result.message) {
        const requestOptionsGetOneTest = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            test_id: test_id,
          }),
        };
        const res2 = await fetch(
          `${baseURL}/get_test_instances`,
          requestOptionsGetOneTest
        );
        const resultEdited = await res2.json();

        const response = {
          test_id: test_id,
          status: "startedFT",
          result: resultEdited,
          tg_invite_link: result.tg_invite_link,
        };
        dispatch(startStrategyForwardTestSuccessAction(response));
      } else {
        dispatch(startStrategyForwardTestFailedAction(result.error));
      }
    } catch (error) {
      dispatch(startStrategyForwardTestFailedAction(error));
    }
  };
}

export function stopForwardTestAction(test_id) {
  return async (dispatch) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          test_id: test_id,
        }),
      };

      const res = await fetch(`${baseURL}/stop_forward_test`, requestOptions);
      const result = await res.json();
      console.log(result);

      // const requestOptionsFT = {
      //   method: "POST",
      //   headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify({
      //     test_id: test_id,
      //   }),
      // };

      // const resFT = await fetch(
      //   `${baseURL}/get_forward_test_result`,
      //   requestOptionsFT
      // );
      // const resultFT = await resFT.json();
      // console.log(resultFT);

      // let ft_roi;
      // let payload = JSON.parse({ test_id: test_id, ft_roi: ft_roi });
      if (result.message) {
        const response = {
          test_id: test_id,
          status: "stoppedFT",
          result: result,
        };
        dispatch(stopStrategyForwardTestSuccessAction(response));
      } else {
        dispatch(stopStrategyForwardTestFailedAction(result.error));
      }
    } catch (error) {
      dispatch(stopStrategyForwardTestFailedAction(error));
    }
  };
}

export function findBestParametersAction(test_id) {
  return async (dispatch) => {
    try {
      console.log(test_id);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          atr: null,
          multiplier: null,
          test_id: test_id,
        }),
      };

      const res = await fetch(
        `${baseURL}/find_best_parameters`,
        requestOptions
      );
      const result = await res.json();
      console.log(result);

      if (result.success) {
        const response = {
          test_id: test_id,
          success: true,
        };
        return response;
      } else {
        const response = {
          test_id: test_id,
          success: false,
          error: result.error,
        };
        return response;
      }
    } catch (error) {
      dispatch(optimizeParametersFailedAction(error));
      const response = {
        test_id: test_id,
        success: false,
        error: error,
      };
      return response;
    }
  };
}

export function findBestParametersProgressAction(test_id) {
  return async (dispatch) => {
    try {
      const requestOptionsFT = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          test_id: test_id,
        }),
      };
      const res = await fetch(
        `${baseURL}/get_find_best_parameters_progress_percentage`,
        requestOptionsFT
      );
      const result = await res.json();
      console.log(result);
      try {
        if (result.estimated_remaining_time) {
          const response = {
            test_id: test_id,
            success: true,
            status: "calculating",
            percentage: result.percentage,
            result: result,
          };
          // dispatch(getForwardTestResultSuccessAction(response));
          return response;
        } else {
          const requestOptionsBT = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              test_id: test_id,
            }),
          };
          await fetch(`${baseURL}/backtesting`, requestOptionsBT);

          const requestOptionsGetOneTest = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              test_id: test_id,
            }),
          };
          const res = await fetch(
            `${baseURL}/get_test_instances`,
            requestOptionsGetOneTest
          );
          const resultUpdated = await res.json();

          const response = {
            test_id: test_id,
            status: "calculated",
            result: resultUpdated,
          };
          console.log(response);
          dispatch(optimizeParametersProgressSuccessAction(response));
          return response;
        }
      } catch (error) {
        dispatch(optimizeParametersProgressFailedAction(error));
        const response = {
          test_id: test_id,
          status: "error",
          error: error,
        };
        return response;
      }
    } catch (error) {
      dispatch(optimizeParametersProgressFailedAction(error));
      const response = {
        test_id: test_id,
        status: "error",
        error: error,
      };
      return response;
    }
  };
}

export function getForwardTestResultAction(test_id) {
  return async (dispatch) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          test_id: test_id,
        }),
      };

      const res = await fetch(
        `${baseURL}/get_forward_test_result`,
        requestOptions
      );
      const result = await res.json();
      console.log(result);
      if (result.success) {
        const response = {
          test_id: test_id,
          success: true,
        };
        return response;
      } else {
        const response = {
          test_id: test_id,
          success: false,
          error: result.error,
        };
        return response;
      }
    } catch (error) {
      dispatch(getForwardTestResultFailedAction(error));
      const response = {
        test_id: test_id,
        success: false,
        error: error,
      };
      return response;
    }
  };
}

export function getForwardTestProgressAction(test_id) {
  return async (dispatch) => {
    try {
      const requestOptionsFT = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          test_id: test_id,
        }),
      };
      const res = await fetch(
        `${baseURL}/get_forward_test_progress_percentage`,
        requestOptionsFT
      );
      const result = await res.json();
      console.log(result);
      try {
        if (result.estimated_remaining_time) {
          const response = {
            test_id: test_id,
            success: true,
            status: "calculating",
            percentage: result.percentage,
            result: result,
          };
          // dispatch(getForwardTestResultSuccessAction(response));
          return response;
        } else {
          const requestOptionsGetOneTest = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              test_id: test_id,
            }),
          };
          const res = await fetch(
            `${baseURL}/get_test_instances`,
            requestOptionsGetOneTest
          );
          const resultUpdated = await res.json();
          console.log(resultUpdated);
          const response = {
            test_id: test_id,
            success: true,
            status: "calculated",
            result: resultUpdated,
          };
          dispatch(getForwardTestProgressSuccessAction(response));
          return response;
        }
      } catch (error) {
        dispatch(getForwardTestProgressFailedAction(error));
        const response = {
          test_id: test_id,
          status: "error",
          error: error,
        };
        return response;
      }
    } catch (error) {
      dispatch(getForwardTestProgressFailedAction(error));
      const response = {
        test_id: test_id,
        status: "error",
        error: error,
      };
      return response;
    }
  };
}

export function removeTestAction(test_id) {
  return async (dispatch) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          test_id: test_id,
        }),
      };

      const res = await fetch(`${baseURL}/remove_forward_test`, requestOptions);
      const result = await res.json();
      if (result.message) {
        const response = {
          test_id: test_id,
          status: "removedTest",
          result: result,
        };
        dispatch(removeStrategyTestSuccessAction(response));
      } else {
        dispatch(removeStrategyTestFailedAction(result.error));
      }
    } catch (error) {
      dispatch(removeStrategyTestFailedAction(error));
    }
  };
}

export function getStrategyTestSuccessAction(payload) {
  return {
    type: GET_STRATEGY_TEST_SUCCESS_ACTION,
    payload,
  };
}

export function getStrategyTestFailedAction(message) {
  return {
    type: GET_STRATEGY_TEST_FAILED_ACTION,
    payload: message,
  };
}
export function createStrategyTestSuccessAction(payload) {
  return {
    type: CREATE_STRATEGY_TEST_SUCCESS_ACTION,
    payload,
  };
}

export function createStrategyTestFailedAction(message) {
  return {
    type: CREATE_STRATEGY_TEST_FAILED_ACTION,
    payload: message,
  };
}
export function editBackTestSuccessAction(payload) {
  return {
    type: EDIT_STRATEGY_BACK_TEST_SUCCESS_ACTION,
    payload,
  };
}

export function editBackTestFailedAction(message) {
  return {
    type: EDIT_STRATEGY_BACK_TEST_FAILED_ACTION,
    payload: message,
  };
}

export function startStrategyBackTestSuccessAction(payload) {
  return {
    type: START_STRATEGY_BACK_TEST_SUCCESS_ACTION,
    payload,
  };
}

export function startStrategyBackTestFailedAction(message) {
  return {
    type: START_STRATEGY_BACK_TEST_FAILED_ACTION,
    payload: message,
  };
}

export function startStrategyForwardTestSuccessAction(payload) {
  return {
    type: START_STRATEGY_FORWARD_TEST_SUCCESS_ACTION,
    payload,
  };
}

export function startStrategyForwardTestFailedAction(message) {
  return {
    type: START_STRATEGY_FORWARD_TEST_FAILED_ACTION,
    payload: message,
  };
}

export function stopStrategyForwardTestSuccessAction(payload) {
  return {
    type: STOP_STRATEGY_FORWARD_TEST_SUCCESS_ACTION,
    payload,
  };
}

export function stopStrategyForwardTestFailedAction(message) {
  return {
    type: STOP_STRATEGY_FORWARD_TEST_FAILED_ACTION,
    payload: message,
  };
}

export function removeStrategyTestSuccessAction(payload) {
  return {
    type: REMOVE_STRATEGY_TEST_SUCCESS_ACTION,
    payload,
  };
}

export function removeStrategyTestFailedAction(message) {
  return {
    type: REMOVE_STRATEGY_TEST_FAILED_ACTION,
    payload: message,
  };
}

export function getForwardTestResultSuccessAction(payload) {
  return {
    type: GET_FORWARD_TEST_SUCCESS_ACTION,
    payload,
  };
}

export function getForwardTestResultFailedAction(message) {
  return {
    type: GET_FORWARD_TEST_FAILED_ACTION,
    payload: message,
  };
}
export function getForwardTestProgressSuccessAction(payload) {
  return {
    type: GET_FORWARD_TEST_PROGRESS_SUCCESS_ACTION,
    payload,
  };
}

export function getForwardTestProgressFailedAction(message) {
  return {
    type: GET_FORWARD_TEST_PROGRESS_FAILED_ACTION,
    payload: message,
  };
}

export function optimizeParametersSuccessAction(payload) {
  return {
    type: OPTIMIZE_PARAMETERS_SUCCESS_ACTION,
    payload,
  };
}

export function optimizeParametersFailedAction(message) {
  return {
    type: OPTIMIZE_PARAMETERS_FAILED_ACTION,
    payload: message,
  };
}
export function optimizeParametersProgressSuccessAction(payload) {
  return {
    type: OPTIMIZE_PARAMETERS_PROGRESS_SUCCESS_ACTION,
    payload,
  };
}

export function optimizeParametersProgressFailedAction(message) {
  return {
    type: OPTIMIZE_PARAMETERS_PROGRESS_FAILED_ACTION,
    payload: message,
  };
}
