export const NEWS_ANALYSIS_SUCCESS_ACTION =
  "[newsanalysis action] confirmed newsanalysis";
export const NEWS_ANALYSIS_FAILED_ACTION =
  "[newsanalysis action] failed newsanalysis";

const baseURL = "https://www.investtrend.tech/api";

export function getNewsAnalysisAction(
  test_id,
  limit,
  start_date,
  end_date,
  symbol,
  impact_above,
  impact_below
) {
  return async (dispatch) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          test_id: test_id,
          limit: limit,
          start_date: start_date,
          end_date: end_date,
          symbol: symbol,
          impact_above: impact_above,
          impact_below: impact_below,
        }),
      };

      //   const res = await getHighestReturn();
      const res = await fetch(
        `${baseURL}/get_multiple_ai_analyze_news`,
        requestOptions
      );
      const result = await res.json();
      dispatch(newsAnalysisSuccessAction(result));
    } catch (error) {
      dispatch(newsAnalysisFailedAction(error));
    }
  };
}

export function newsAnalysisSuccessAction(payload) {
  return {
    type: NEWS_ANALYSIS_SUCCESS_ACTION,
    payload,
  };
}

export function newsAnalysisFailedAction(message) {
  return {
    type: NEWS_ANALYSIS_FAILED_ACTION,
    payload: message,
  };
}
